import Address from './Address';
import axios from 'axios';
const config = require('../config');

class LocalAddress extends Address
{
    componentDidMount()
    {
        if (this.props.barangays.length <= 1) 
        {
            var self = this;
            this.props.onAddressChanged(
                {...this.props, loading : true }
            );
            axios.get(config.server_url + '/barangays').then(function(response){
                var _localbarangays = [ {'':''}];
                for (var __barangay in response.data)
                {
                    var _barangay = response.data[__barangay];
                    _localbarangays.push({ [_barangay.BRGY_CODE] : _barangay.BRGY_NM}) 
                }
                self.props.onAddressChanged({ ...self.props, barangays : _localbarangays, loading : false});
            });
        }
    }

}
/*
LocalAddress.defaultProps = {
    street : '',
    province : '031400000',
    citymunicipality : '031410000',
    barangay : '',
    zipcode : '3000',
    country : 'PH',
    streetlabel : 'Street',
    isfulladdress: true,
    countries : [ {'PH' : 'PHILIPPINES' }],
    provinces : [ {'031400000': 'BULACAN'}],
    citymunicipalities : [{'031410000':'CITY OF MALOLOS'}],
    barangays : [{'':''}],
    loading : false
}
*/
export default LocalAddress;