
import React, { Fragment } from "react";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import cx from "classnames";
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { logoutRequest } from '../../actions/session';
import sidebarStyle from "../../assets/css/sidebarStyle";
import history from '../../history';
import {
	Dashboard,
	Description,
	FolderShared,
	Payment,
	PowerSettingsNew,
	Settings,
	Store
} from "@material-ui/icons";

const debug = require('debug')('clientHeader.js');
debug.enabled = process.env.REACT_APP_DEBUG;
const _config = require('../../config');

class Sidebar extends React.Component {

    constructor(props)
    {
		super(props);
        this.state = {
            mobileOpen: false,
			avatar: (this.props.user !== undefined && this.props.user.avatar !== undefined && this.props.user.avatar !== '') ? this.props.user.avatar : 'default-avatar.png',
		};
		if (!this.props.isAuthenticated){
			history.push('/');
        }
        this.onLogout = this.onLogout.bind(this);
	}

	onLogout()
	{
		this.props.logoutRequest();
		history.push('/');
	}

	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
	}

	setColor(route, _class)
	{
		if (_class === undefined) return '';
		return cx(
			{
			[" " + _class] :
			this.props.location.pathname.indexOf(route) > -1 ? true : false
			}
		)
	}
	componentDidMount(){
		let token = localStorage.getItem('token');
		let config = {
			headers: {"Authorization" : 'bearer ' + token},
			params: {
				id: this.props.user._id,
			}
		}
		const _props = this.props;
		const self = this;

		axios.get(_config.server_url + '/getdir',config).then((result) => {
			self.setState ({avatar : result.data.imagePath}, () => { })
		}).catch(function(error){
			_props.logoutRequest();
			history.push('/');
		});
	}

	componentWillUpdate(prevProps, prevState) {
		if (prevProps.open !== this.props.open) {
			this.setState(state => ({ mobileOpen: this.props.open }));
		}
	}

    render() {
		const { classes, color, user} = this.props;
		const {lastname,firstname} = user;
        const {avatar : image} = this.state;

        const links = (
			<List className={classes.list}>
				<div className={classes.sectionDesktop}>
					{/* <Avatar className={classes.avatar}><AccountCircle /></Avatar> */}
					<Avatar src={image} className={classes.avatar}/>
					<Typography
						className = {classes.userName}
						color = "inherit"
					>
						{lastname}, {firstname}
					</Typography>
				</div>
				{ (user.access === 'admin') ? (
					<Fragment>
						<NavLink to="/admin-dashboard" exact={true} className={classes.item} activeClassName="is-active">
						<ListItem button className={classes.itemLink + this.setColor('/admin-dashboard', classes[color])}>
							<ListItemIcon className={classes.itemIcon}>
								<Dashboard />
							</ListItemIcon>
							<ListItemText
							primary= {"Dashboard"}
							className={classes.itemText + this.setColor('/admin-dashboard', classes.whiteFont)}
							disableTypography={true} />
						</ListItem>
						</NavLink>
						<NavLink to={`/user-settings/view`} className={classes.item} exact={true} activeClassName="is-active">
							<ListItem button className={classes.itemLink + this.setColor('/user-settings', classes[color])}> 
							<ListItemIcon className={classes.itemIcon}>
								<FolderShared />
							</ListItemIcon>
							<ListItemText
								primary= {"User Settings"}
								className={classes.itemText + this.setColor('/user-settings', classes.whiteFont)}
								disableTypography={true} />
							</ListItem>
						</NavLink>
					</Fragment>
				): (
					<Fragment >
						<NavLink to="/dashboard" exact={true} className={classes.item} activeClassName="is-active">
							<ListItem button className={classes.itemLink + this.setColor('/dashboard', classes[color])}>
							<ListItemIcon className={classes.itemIcon}>
								<Dashboard />
							</ListItemIcon>
							<ListItemText
							primary= {"Dashboard"}
							className={classes.itemText + this.setColor('/dashboard', classes.whiteFont)}
							disableTypography={true} />
							</ListItem>
						</NavLink>
						<NavLink to="/application-form" className={classes.item} exact={true} activeClassName="is-active">
							<ListItem button className={classes.itemLink + this.setColor('/application-form', classes[color])}> 
							<ListItemIcon className={classes.itemIcon}>
								<Description />
							</ListItemIcon>
							<ListItemText
								primary= {"Application Form"}
								className={classes.itemText + this.setColor('/application-form', classes.whiteFont)}
								disableTypography={true} />
							</ListItem>
						</NavLink>
						<NavLink to="/business-information" className={classes.item} exact={true} activeClassName="is-active">
							<ListItem button className={classes.itemLink + this.setColor('/business-information', classes[color])}> 
							<ListItemIcon className={classes.itemIcon}>
								<Store />
							</ListItemIcon>
							<ListItemText
								primary= {"Business Information"}
								className={classes.itemText + this.setColor('/business-information', classes.whiteFont)}
								disableTypography={true} />
							</ListItem>
						</NavLink>
						<NavLink to="/payment-history" className={classes.item} exact={true} activeClassName="is-active">
							<ListItem button className={classes.itemLink + this.setColor('/payment-history', classes[color])}> 
							<ListItemIcon className={classes.itemIcon}>
								<Payment />
							</ListItemIcon>
							<ListItemText
								primary= {"Payment History"}
								className={classes.itemText + this.setColor('/payment-history', classes.whiteFont)}
								disableTypography={true} />
							</ListItem>
						</NavLink>
					</Fragment >
				)}
				<NavLink to="/account-settings" className={classes.item} activeClassName="is-active">
					<ListItem button className={classes.itemLink + this.setColor('/account-settings', classes[color])}>
					<ListItemIcon className={classes.itemIcon}>
						<Settings />
					</ListItemIcon>
					<ListItemText
					primary= {"Account Settings"}
					className={classes.itemText + this.setColor('/account-settings', classes.whiteFont)}
					disableTypography={true} />
					</ListItem>
				</NavLink>
				<ListItem button className={classes.itemLink} onClick={this.onLogout}>
					<ListItemIcon className={classes.itemIcon}>
						<PowerSettingsNew />
					</ListItemIcon>
					<ListItemText
						primary= {"Logout"}
						className={classes.itemText}
						disableTypography={true} />
				</ListItem>
			</List>
		);

        return (
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor='left'
                            open={this.props.open}
                            onClose={this.props.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {links}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {links}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return {
		logoutRequest : () => {
			dispatch(logoutRequest());
		}
	}
}

const mapStateToProps = state => {
	return  {
		isAuthenticated : state.session.isAuthenticated,
		user : state.session.user,
		mobileOpen : state.session.mobileOpen
	}
}

export default withRouter(withStyles(sidebarStyle)(connect(mapStateToProps,mapDispatchToProps)(Sidebar)));
