import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  group: {
    display: "block !important",
    margin:"0px",
  },
  formLabel:{
    fontSize: "11.5px",
  },
});

class RadioButtonsGroup extends React.Component {

  render() {
    const { 
        classes,
        required = false,
        error = false,
        label,
        name,
        onChange,
        selectList,
        color = "primary",
        xsSize,
        mdSize,
        lgSize,
        value,
        disabled = false
    } = this.props;

    // if(this.props.value == "") {var value = this.props.selectList[0].value}
    // else{var value = this.props.value }
    
    return (
        <Grid item xs={xsSize} md={mdSize} lg={lgSize}>
            <FormControl component="fieldset" required={required} error={error}>
                <FormLabel component="legend" className={classes.formLabel}>{label}</FormLabel>
                <RadioGroup
                    aria-label={label}
                    name={name}
                    className={classes.group}
                    value={value}
                    onChange={onChange}
                >   
                    {selectList.map((v,i) => {
                        return <FormControlLabel key={i} value={v.value} control={<Radio color={color} disabled={disabled}/>} label={v.label} />
                    })}
                    {/* <FormControlLabel value="male" control={<Radio color="primary" />} label="Male" />
                    <FormControlLabel value="female" control={<Radio color="primary" />} label="Female" /> */}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonsGroup);
