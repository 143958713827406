import React, { Component, Fragment } from 'react';
import { withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import appStyle from '../../assets/css/appStyle';
import logo from "../../assets/img/reactlogo.png";
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

class PrivatePage extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            isSidebarOpen : false,
        }
    }

    openMobileSidebar = () => {
        this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    };

    render() {
        const { isSidebarOpen } = this.state
        const { title, classes ,...props } = this.props;
        return (
            <Fragment>
                <Header
                    logo={logo}
                    handleDrawerToggle={this.openMobileSidebar}
                    {...props}
                />
                <Sidebar
                    color="blue"
                    open={isSidebarOpen}
                    handleDrawerToggle={this.openMobileSidebar}
                >
                    <p className = {classes.titlePageDesign}>
                        {title}
                    </p>
                    <div className = {classes.content}>
                        {this.props.children}
                    </div>
                    <Divider/>
                    <Footer />
                </Sidebar>
            </Fragment>
        )
    }
}

export default withStyles(appStyle)(PrivatePage);
