import Address from './Address';
import axios from 'axios';
const config = require('../config');

class GlobalAddress extends Address
{
    onCityMunicipalityChanged = (v) =>
    {
        this.props.onAddressChanged(
            {...this.props, citymunicipality: v, hascitymunicipalityerror : v.trim() === '',  barangays : [{'':''}], barangay:'', hasbarangayerror:true, loading : v !== '' }
        );
        if (v === '')
            return;
        const citymunicipalityid = v;
        var self = this;
        axios.get(config.server_url + '/psgcbarangays/' + citymunicipalityid).then(function(response){
            var _barangays = [ {'':''}];
            for (var i in response.data)
                _barangays.push({ [Object.keys(response.data[i])[0]] : Object.values(response.data[i])[0]});
            self.props.onAddressChanged({ ...self.props, 'barangays' :  _barangays, loading : false });
        });
    }

    onProvinceChanged = (v) =>
    {
        this.props.onAddressChanged(
            {...this.props, province : v,  hasprovinceerror : v.trim() === '', citymunicipalities : [{'':''}],
                citymunicipality: '',  hascitymunicipalityerror : true, barangays : [{'':''}], barangay: '', hasbarangayerror : true, loading : v !== '' }
        );
        if (v === '')
            return;
        const provinceid = v.substr(0, 4);
        var self = this;
        axios.get(config.server_url + '/citymunicipalities/' + provinceid).then(function(response){
            var _citymunicipalities = [ {'':''}];
            for (var i in response.data)
                _citymunicipalities.push({ [Object.keys(response.data[i])[0]] : Object.values(response.data[i])[0]});
            self.props.onAddressChanged({ ...self.props, 'citymunicipalities' :  _citymunicipalities, loading : false });
        });
    }

    onCountryChanged = (v) =>
    {
        if (v !== 'PH')
            this.props.onAddressChanged({ ...this.props, country : v, isfulladdress: false,  streetlabel : 'Address', zipcode:'', haszipcodeerror:false,
                province: '', hasprovinceerror: false, citymunicipality:'', hascitymunicipalityerror: false, citymunicipalities: [{'':''}], barangay:'', hasbarangayerror : false, barangays : [{'':''}]});
        else
            this.props.onAddressChanged(  {...this.props, country : v, isfulladdress: true,  streetlabel : 'Street', housenumber : '',
                street: '', province: '', citymunicipality:'', barangay : '', zipcode : '', hasstreeterror:true, hasprovinceerror : true,
                hascitymunicipalityerror : true, hasbarangayerror: true, haszipcodeerror:true} );

    }

    componentDidMount()
    {
        if (this.props.countries.length === 1) //initial run
        {
            this.props.onAddressChanged(
                {...this.props, loading : true }
            );

            var self = this;
            axios.get(config.server_url + '/countries').then(function(response){
                var _countries = [];
                response.data.forEach(function(element)
                {
                    _countries.push(element);
                });

                axios.get(config.server_url + '/provinces').then(function(response){
                    var _provinces = [ {'':''}];
                    response.data.forEach(function(element)
                    {
                        _provinces.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                    });
                    if (self.props.province !== '')
                    {
                        const provinceid = self.props.province.substr(0, 4);
                        axios.get(config.server_url + '/citymunicipalities/' + provinceid).then(function(response){
                            var _citymunicipalities = [ {'':''}];
                            response.data.forEach(function(element)
                            {
                                _citymunicipalities.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                                if (self.props.citymunicipality !== '')
                                {
                                    const citymunicipalityid = self.props.citymunicipality;
                                    axios.get(config.server_url + '/psgcbarangays/' + citymunicipalityid).then(function(response){
                                        var _barangays = [ {'':''}];
                                        response.data.forEach(function(element)
                                        {
                                            _barangays.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                                        });
                                        self.props.onAddressChanged({ ...self.props, 'countries' :  _countries, 'provinces' :  _provinces, 'citymunicipalities': _citymunicipalities, 'barangays': _barangays,  loading : false});
                                    });
                                }
                                else
                                {
                                    self.props.onAddressChanged({ ...self.props, 'countries' :  _countries, 'provinces' :  _provinces, 'citymunicipalities': _citymunicipalities,  loading : false});
                                }
                            });
                        });
                    }
                    else
                    {
                        self.props.onAddressChanged({ ...self.props, 'countries' :  _countries, 'provinces' :  _provinces , loading : false});
                    }
                });
            });
        }
    }
}

GlobalAddress.defaultProps = {
    housenumber : '',
    street : '',
    province : '',
    citymunicipality : '',
    barangay : '',
    zipcode : '',
    country : 'PH',
    streetlabel : 'Street',
    isfulladdress: true,
    countries : [ {'PH' : 'PHILIPPINES' }],
    provinces : [ {'':''}],
    citymunicipalities : [{'':''}],
    barangays : [{'':''}],
    loading : false
}

export default GlobalAddress;