import React from 'react';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

const ApplicationType = (props) => (
    <Typography>
        <Radio checked={props.applicationType === 'NEW'} onClick={props.setApplicationType} onChange={props.setApplicationType} value="NEW" />NEW
        <Radio checked={props.applicationType === 'RENEWAL'} onClick={props.setApplicationType} onChange={props.setApplicationType} value="RENEWAL" />RENEWAL
    </Typography>  
);

export default ApplicationType;
