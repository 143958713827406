import { combineReducers } from 'redux';
import users from './users';
import session from "./session";
import alert from "./alert";
import application from "./application"

export default combineReducers(
    {
        session,
        users,
        application,
        alert
    }
);

