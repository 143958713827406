import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import footerStyle from '../../assets/css/footerStyle';

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p className={classes.left}>
          <span>
              {/* 2017 © Amellar Solutions. All rights reserved. */}
              {1900 + new Date().getYear()}{" "}&copy;{" "}
              <a href="/" className={classes.a}>
                Amellar Solutions.
              </a>, All rights reserved.
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
