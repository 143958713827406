import React, { Component , Fragment} from 'react';
//import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";
//import Grid from '@material-ui/core/Grid';

import regularCardStyle from "../assets/css/regularCardStyle";
import TextFields from './TextFields';
//import TextField from '@material-ui/core/TextField';



/**
    @class Person
    @description component for displaying textbox for data like lastname,firstname and middlename
    @params onPersonChanged func
*/

class Person extends Component
{
    constructor(props){
        super(props)
        this.state = {
            appearAutoComplete : true,
        }
    }

    
    onChange = (e) =>
    {
        const {name, value} = e.target;
        this.props.onPersonChanged( {...this.props, [name] : value.toUpperCase(),  ['has' + name + 'error'] : value.trim() === ''});
    }

    render() {
        const {classes} = this.props
        return (
            <Fragment>
                <TextFields
                    id="lastname"
                    name="lastname"
                    label="Last Name"
                    placeholder="Last Name"
                    value={this.props.lastname}
                    onChange={this.onChange}
                    error={this.props.haslastnameerror && !this.props.runonce}
                    className={classes.componentMargin}
                    disabled={this.props.disabled}
                    inputProps={{
                        'style' : {'textTransform': 'uppercase'}
                    }}
                    required
                    lgSize={5}
                    mdSize={6}
                    xsSize={12}
                    autoMode = {false} // to disable the searching of owners info in persons component
                    getOwnInfoList = {this.props.getOwnInfoList}
                    getSelectedInfo = {this.props.getSelectedInfo}

                />
                <TextFields
                    id="firstname"
                    name="firstname"
                    label="First Name"
                    placeholder="First Name"
                    value={this.props.firstname}
                    onChange={this.onChange}
                    className={classes.componentMargin}
                    disabled={this.props.disabled}
                    error={this.props.hasfirstnameerror && !this.props.runonce}
                    inputProps={{
                        'style' : {'textTransform': 'uppercase'}
                    }}
                    required
                    lgSize={5}
                    mdSize={6}
                    xsSize={12}
                />
                <TextFields
                    id="middlename"
                    name="middlename"
                    label="Middle Initial"
                    placeholder="Middle Initial"
                    value={this.props.middlename}
                    onChange={this.onChange}
                    className={classes.componentMargin}
                    disabled={this.props.disabled}
                    error={this.props.hasmiddlenameerror && !this.props.runonce}
                    inputProps={{
                        'style' : {'textTransform': 'uppercase'}
                    }}
                    required
                    lgSize={2}
                    mdSize={6}
                    xsSize={12}
                />
            </Fragment>
        );
    }
}

Person.propTypes = {
    lastname : PropTypes.string.isRequired,
    firstname : PropTypes.string.isRequired,
    middlename : PropTypes.string.isRequired,
    onPersonChanged : PropTypes.func.isRequired,
};

export default withStyles(regularCardStyle)(Person);
