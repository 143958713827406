import React, {Component} from 'react';
import Person from '../Person';
import LocalAddress from '../LocalAddress';
import BINField from '../BINField'
import MayorsPermitField from '../MayorsPermitField'
import axios from 'axios';
const config = require('../../config');

class BinDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            prefix : ''
        }
    }

    componentDidMount()
    {
        const self = this;
        axios.get(config.server_url + '/binprefix' )
        .then(res => {
            self.setState(ps => ( {
                prefix : res.data.BIN_PREFIX
            }));
        })
        .catch(error => {
            self.setState(ps => ( {
                prefix : ''
            }));
        });
    }

    render() {
        return (
            <div>
                <Person {...this.props.owner} runonce={this.props.runonce} onPersonChanged={this.props.onPersonChanged}/>
                <BINField prefix={this.state.prefix} name="bin" helperText={this.props.error} value={this.props.bin} onChange={this.props.onChange} required placeholder="BIN"  error={ (this.props.error.trim() !== '' || this.props.bin.trim() === '') && !this.props.runonce}/>
                <MayorsPermitField seriesLength={7} name="permitnumber" value={this.props.permitnumber} onChange={this.props.onChange} required placeholder="Mayor's Permit No." error={this.props.permitnumber.trim() === '' && !this.props.runonce}/>
                <LocalAddress {...this.props.owneraddress} runonce={this.props.runonce} onAddressChanged={this.props.onAddressChanged}/>
            </div>
        )
    }

}

export default BinDetails;