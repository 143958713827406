import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { connect } from 'react-redux';
import { getOwnInfo } from '../actions/application'

const debug = require('debug')('client_tfs');
debug.enabled = process.env.REACT_APP_DEBUG;

let selectedOwnersInfo = [];
// let suggestions = [];

function renderInputComponent(inputProps) {
	const { classes, inputRef = () => {}, ref, ...other } = inputProps;

	return (
		<TextField
			fullWidth
			InputProps={{
			inputRef: node => {
				ref(node);
				inputRef(node);
			},
			classes: {
				input: classes.input,
			},
			}}
			{...other}
		/>
	);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
	
	const matches = match(suggestion.LASTNAME , query);
	const parts = parse(suggestion.FULLNAME, matches);

	return (
	<MenuItem selected={isHighlighted} component="div">
		<div>
		{parts.map((part, index) =>
			part.highlight ? (
			<span key={String(index)} style={{ fontWeight: 500 }}>
				{part.text}
			</span>
			) : (
			<strong key={String(index)} style={{ fontWeight: 300 }}>
				{part.text}
			</strong>
			),
		)}
		</div>
	</MenuItem>
	);
}

function getSuggestions(value,suggestions) {
	const inputValue = deburr(value.trim()).toLowerCase();
	const inputLength = inputValue.length;
	let count = 0;
	return (inputLength === 0 || suggestions === undefined || suggestions.length === 0 || suggestions === null)
	? []
	: suggestions[0].filter(suggestion => {
		// debug('suggestion %O', suggestion)
		const keep = count < 5 && suggestion.FULLNAME.slice(0, inputLength).toLowerCase() === inputValue;
		if (keep) {
			count += 1;
		}
		return keep;
	});
}

function getSuggestionValue(suggestion) {
	// debug('Suggestion Value %O', suggestion);
	//selectedOwnersInfo = suggestion;
	return suggestion.LASTNAME;
}


const styles = theme => ({

	textField: {
		width: "100%",
	},
	root: {
		height: 250,
		flexGrow: 1,
	},
	container: {
		position: 'relative',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},
	divider: {
		height: theme.spacing.unit * 2,
	},
});

class TextFields extends React.Component {

	constructor(props){
		super(props)
		this.state = {
			single: '',
			popper: '',
			suggestions: [],
		}

	}

	handleSuggestionsFetchRequested = ({ value }) => {
		this.props.getOwnInfo(value, ()=> {
			this.setState({
				suggestions: getSuggestions(value,this.props.getOwnInfoList),
			});
	
		});
	};

	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};

	handleSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		selectedOwnersInfo = suggestion;		
		this.props.getSelectedInfo(selectedOwnersInfo)
		selectedOwnersInfo = [];	
	}

	/*
	handleSuggestionSelected = () => (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } ) => {
		debug('Suggestion Selected %O', suggestion);
	}	
	*/

	handleChange = (name, _name, getSuggestionValue) => (event, { newValue }) => {
		console.log("handleChange")
		console.log(selectedOwnersInfo)
		const _props = this.props;
		this.setState({
			[name]: newValue,
		}, ()=> {
			_props.onChange( { target : {name:_name, value: newValue}} )

			// bring it back, to reset the value of the data if the lastname started to change or removed by users
			_props.getSelectedInfo(selectedOwnersInfo)
			selectedOwnersInfo = []; 
		});
	};

	render() {
		const {
			classes,
			name,
			id,
			label,
			onChange,
			value,
			required = false,
			disabled = false,
			xsSize,
			mdSize,
			lgSize,
			inputProps,
			error,
			placeholder,
			autoMode = false,
			// getSelectedInfo = null,
		} = this.props;

		const autosuggestProps = {
			renderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			getSuggestionValue,
			onSuggestionSelected : this.handleSuggestionSelected,
			renderSuggestion,
		};

		let errorAlert = false; 

		// Put some condition here due to the error parameter of autosuggest return undefined value after rendering ! 
		if(error == undefined)
			errorAlert = false 
		else
			errorAlert = error 

		return (
			<Grid item xs={xsSize} md={mdSize} lg={lgSize}>
				{autoMode === true  ?
					<div>
						<Autosuggest
							{...autosuggestProps}
							inputProps={{
							classes,
							label: 'Last Name*',
							placeholder: 'Last Name',
							name: name,
							value: value,
							onChange: this.handleChange('popper', name, getSuggestionValue),
							error: errorAlert,
							inputRef: node => {
								this.popperNode = node;
							},
							InputLabelProps: {
								shrink: true,
							},
							}}
							theme={{
							suggestionsList: classes.suggestionsList,
							suggestion: classes.suggestion,
							}}
							renderSuggestionsContainer={options => (
							<Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
								<Paper
								square
								{...options.containerProps}
								style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
								>
								{options.children}
								</Paper>
							</Popper>
							)}
						/>
					</div>
						:
					<TextField
						value={value}
						className={classes.textField}
						onChange={onChange}
						name={name}
						placeholder={placeholder}
						id={id}
						label={label}
						required ={required}
						disabled ={disabled}
						inputProps= {inputProps}
						error={error}
					/>
				}
			</Grid>
		);
	}
}

TextFields.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(null, {getOwnInfo}) (withStyles(styles)(TextFields));
