import { sessionConstants } from '../constants/session';

const sessionDefaultState = {
    isAuthenticated : false,
    user : undefined,
    hello : '',
    mobileOpen : false,
    isLoading : false,
    binPrefix : '',
}

export default (state = sessionDefaultState, action ) => {
    switch (action.type) {
        case sessionConstants.LOGIN_SUCCESS:
        {
            return {...state, isAuthenticated: true, user: action.user};
        }
        case sessionConstants.LOGIN_FAILED:
        {
            return sessionDefaultState;
        }
        case sessionConstants.HELLO:
        {
            return {...state, hello : action.hello}
        }
        case sessionConstants.LOGOUT:
        {
            return sessionDefaultState;
        }
        case sessionConstants.LOADING_SHOW:
        {
            return {...state, isLoading : true}
        }
        case sessionConstants.LOADING_HIDE:
        {
            return {...state, isLoading : false}
        }
        case sessionConstants.BIN_PREFIX:
        {
            return {...state, binPrefix : action.binPrefix}
        }
        default:
            return state;
    }
}
