import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordValidator from 'password-validator'

const styles = theme => ({
  textField: {
    margin: theme.spacing.unit,
    flexBasis: 200,
  },
});


class PasswordField extends React.Component {
  state = {
    sValue: '',
    sShowPassword: false,
  };


  handleChange = prop => event => {
    if(prop === 'password' && this.props.onChange !== null){
      this.props.onChange(event);
      this.setState({ sValue: event.target.value });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ sShowPassword: !this.state.sShowPassword });
  };

  static getValidationRules()
  {
    let schema = new PasswordValidator();
    const minPasswordLength = 8;
    const maxPasswordLength = 100;

    schema.is().min(minPasswordLength)
      .is().max(maxPasswordLength)
      .has().uppercase()
      .has().lowercase()
      .has().digits();

    return schema;
  }

  render() {

    const minPasswordLength = 8;
    const maxPasswordLength = 100;
    /*
    var schema = new PasswordValidator();

    schema.is().min(minPasswordLength)
      .is().max(maxPasswordLength)
      .has().uppercase()
      .has().lowercase()
      .has().digits()
    */

    const {
      classes,
      width = '300px',
      label = 'Password',
      validationRules = PasswordField.getValidationRules(),
      validatePassword = false,
      shouldMatch = null,
      //onChange = null,
      name = null ,
      value = '',
    } = this.props;

    const {
      sShowPassword,
    } = this.state;

    const currentPassword = value;

    const hasError = (shouldMatch === null && currentPassword !== "" && !validationRules.validate(currentPassword)) || (shouldMatch !== null && currentPassword !== shouldMatch ) ;

    let validationErrors = '';
    let errorText = '';

    if(hasError && shouldMatch === null){
      const unsatisfiedConditions = validationRules.validate(currentPassword, {list: true})

      validationErrors = unsatisfiedConditions.map( (v, i) => {
        let str = ''
        switch(v){
          case 'min': str = `Password should be at least ${minPasswordLength} characters`; break;
          case 'max': str = `Password should be at most ${maxPasswordLength} characters`; break;
          case 'uppercase': str = `Password should have an uppercase letter`; break;
          case 'lowercase': str = `Password should have a lowercase letter`; break;
          case 'digits': str = `Password should have a number`; break;
          default : break;
        }
        return <li key={i}>{str}</li>
      });

    }else if(hasError && shouldMatch != null){
      errorText = "Passwords didn't match.";
    }

    return (
        <FormControl error={validatePassword && hasError} className={classes.textField}>
          <InputLabel htmlFor="adornment-password">{label}</InputLabel>
          <Input
            style={{width}}
            type={sShowPassword ? 'text' : 'password'}
            value={value}
            name={name}
            onChange={this.handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  tabIndex="-1"
                >
                  {sShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {
            validatePassword && hasError && shouldMatch === null &&
            <ul>{validationErrors}</ul>
          }
          { shouldMatch !== null && hasError && errorText !== '' && <FormHelperText>{errorText}</FormHelperText> }
       </FormControl>
    );
  }
}

PasswordField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordField);