import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from "@material-ui/icons";
import { closeAlert, showAlert } from '../actions/alert';
import { hideLoadingRequest, showLoadingRequest } from '../actions/session';
import { userSignupRequest } from '../actions/users';
import Alert from '../components/alerts/alert';
import PasswordField from '../components/PasswordField';
import { ApplicationType, BinDetails, EmailRegistration, PersonalInformation, Summary } from '../components/registration';
import history from '../history';

const config = require('../config');

class SignupContainer extends Component {

    getRenewSteps = () => {
        const {activeStep} = this.state;
        const {applicationType} = this.state.user;
        return [
            {title:'Application Type',id:0, 'chip': [activeStep !== 0 ?  [applicationType]  : ''] },
            {title:'Personal Information',id:1},
            {title:'Email Registration',id:2},
            {title:'BIN details',id:3},
            {title:'Summary',id:4}
        ];
    }

    getNewSteps = () => {
        const {activeStep} = this.state;
        const {applicationType} = this.state.user;
        return [
            {title:'Application Type', id:0, 'chip': [activeStep !== 0 ?  [applicationType]  : ''] },
            {title:'Personal Information',id:1},
            {title:'Email Registration',id:2},
            {title:'Summary',id:4}
        ];
    }

    constructor(props)
    {
        super(props);

        if (this.props.isAuthenticated) //put to redirect in route
            history.push('/dashboard');
        this.props.closeAlert();
        this.state = {
            activeStep : 0,
            user : {
                applicationType : '',  //NEW, RENEWAL
                person : {
                    lastname : '',
                    firstname : '',
                    middlename : '',
                    haslastnameerror : true,
                    hasfirstnameerror : true,
                    hasmiddlenameerror : true,
                    runonce : true
                },
                dialingcode : '63', //default country
                contactnumber : '',
                personaddress : {
                    housenumber : '',
                    street : '',
                    province : '',
                    citymunicipality : '',
                    barangay : '',
                    zipcode : '',
                    country : 'PH',
                    streetlabel : 'Street',
                    isfulladdress: true,
                    countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
                    provinces : [ {'':''}],
                    citymunicipalities : [{'':''}],
                    barangays : [{'':''}],
                    loading : false,
                    hasstreeterror : true,
                    hasprovinceerror : true,
                    hascitymunicipalityerror : true,
                    hasbarangayerror : true,
                    haszipcodeerror : true,
                    hascountryerror : false,
                    runonce : true
                },
                runonce1 : true,
                emailaddress: '',
                emailaddressError: 'Invalid Email.',
                password: '',
                confirmPassword: '', // not necessary data
                runonce2 : true,
                owner : {
                    lastname : '',
                    firstname : '',
                    middlename : '',
                    haslastnameerror : true,
                    hasfirstnameerror : true,
                    hasmiddlenameerror : true,
                    runonce : true
                },
                bin : '',
                error : '',
                permitnumber: '',
                owneraddress : {
                    housenumber : '',
                    street : '',
                    province : '031400000',
                    citymunicipality : '031410000',
                    barangay : '',
                    zipcode : '3000',
                    country : 'PH',
                    streetlabel : 'Street',
                    isfulladdress: true,
                    countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
                    provinces : [ {'031400000': 'BULACAN'}],
                    citymunicipalities : [{'031410000':'CITY OF MALOLOS'}],
                    barangays : [{'':''}],
                    loading : false,
                    hashousenumber : true,
                    iszipcodedisabled : true,
                    hasstreeterror : true,
                    hasprovinceerror : false,
                    hascitymunicipalityerror : false,
                    hasbarangayerror : true,
                    haszipcodeerror : false,
                    hascountryerror : false,
                    runonce : true
                },
                runonce3 : true,
                }
        }
    }

    onPersonChanged = (person) =>
    {
        this.setState( prevState => ( {
            user:  {...prevState.user, person}
        }));
    }

    onPersonAddressChanged = ( personaddress) =>
    {
        let isCountryChanged = false;

        this.setState( function(prevState){
            if (prevState.user.personaddress.country !== personaddress.country)
            {
                isCountryChanged = true;
                return {user : { ...prevState.user, personaddress} }
            }
            else
            {
                return {user : { ...prevState.user, personaddress} }
            }
        }, () => {
            if (isCountryChanged)
            {
                const self = this;
                //get dialing code
                axios.get(config.server_url + '/dialingcode', {params :  {countrycode : personaddress.country} } )
                    .then(function (response) {
                        const dialingcode = response.data.dialing_code;
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode, contactnumber: ''}
                        }));
                    })
                    .catch(function(error){
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode : '', contactnumber: ''}
                        }));
                    });
            }
        });

    }

    onOwnerChanged = (owner) =>
    {
        this.setState( prevState => ( {
            user:  {...prevState.user, owner}
        }));
    }

    onOwnerAddressChanged = ( owneraddress) =>
    {
        this.setState( prevState => ( {
            user : { ...prevState.user, owneraddress}
        }));
    }

    setApplicationType = (e) => {
        const value = e.target.value;
        this.setState( prevState => ( {
            activeStep : 1,
            user:  {...prevState.user, applicationType: value}
        }));
    }

    getStepContent(step) {
        switch (step) {
            case 0:
            return <ApplicationType applicationType={this.state.user.applicationType} setApplicationType={this.setApplicationType}/>;
            case 1:
            return <PersonalInformation person={this.state.user.person} onPersonChanged={this.onPersonChanged}
                dialingcode={this.state.user.dialingcode} contactnumber={this.state.user.contactnumber} onChange={this.onChange} 
                personaddress={this.state.user.personaddress} onAddressChanged={this.onPersonAddressChanged} runonce={this.state.user.runonce1} 
                />
            case 2:
            const {emailaddress, password, confirmPassword, emailaddressError, runonce2, ...user} = this.state.user
            return <EmailRegistration
                        onChange={
                            (emailaddress, password, confirmPassword, emailaddressError, runonce2) => {
                                this.setState({
                                    user: {
                                        ...user,
                                        emailaddress,
                                        password,
                                        confirmPassword,
                                        emailaddressError,
                                        runonce2
                                    }
                                })
                            }
                        }
                        value={{emailaddress, password, confirmPassword, emailaddressError, 'runonce':runonce2}}
                    />
            case 3:
            return <BinDetails owner={this.state.user.owner} onPersonChanged={this.onOwnerChanged}
                bin={this.state.user.bin}
                error={this.state.user.error}
                permitnumber={this.state.user.permitnumber}
                onChange={this.onChange}
                owneraddress={this.state.user.owneraddress} onAddressChanged={this.onOwnerAddressChanged} runonce={this.state.user.runonce3} 
                />;
            case 4:
            return <Summary user={this.state.user} />
            default:
            return 'Unknown step';
        }
    }

    onChange = (e) =>
    {
            const {name, value} = e.target;

            var user = this.state.user;
            if (name === 'contactnumber')
                user.contactnumber = value;
            else if (name === 'emailaddress')
                user.emailaddress = value;
            else if (name === 'password')
                user.password = value;
            else if (name === 'bin')
                user.bin = value;
            else if (name === 'permitnumber')
                user.permitnumber = value;
            this.setState({user});
    }

    onSubmit = (e) =>{
        e.preventDefault();

        const _props = this.props;
        //this.props.userSignupRequest(this.state);
            _props.showLoadingRequest();
            axios.post(config.server_url + '/register', { user : this.state.user}).then(function (response) {
                _props.hideLoadingRequest();
                _props.showAlert('Successfully created account.', 'success');
                history.push('/');
            }).catch(function(error){
                _props.hideLoadingRequest();
                _props.showAlert(error.response.data.errors[0], 'error');
        });
    }

    handleNext = () => {
        //check
        let success = true;
        if (this.state.activeStep === 1) //personal information
        {
            var {person, personaddress, contactnumber, dialingcode, runonce1} = this.state.user;

            if (runonce1)
            {
                const _user = {...this.state.user, runonce1: false};
                this.setState({user: _user});
            }

            if (person.haslastnameerror || person.hasfirstnameerror || person.hasmiddlenameerror ||
                contactnumber === '' || (dialingcode !== '-' && contactnumber === ('+' + dialingcode)) ||
                personaddress.hasstreeterror || personaddress.hascountryerror || personaddress.hasprovinceerror ||  personaddress.hascitymunicipalityerror || personaddress.hasbarangayerror || personaddress.haszipcodeerror)
            {
                    success = false;
            }

                

        }
        else if (this.state.activeStep === 2) // email registration
        {
            const { emailaddress, password, confirmPassword, emailaddressError, runonce2 } = this.state.user;

            if (runonce2)
            {
                const _user = {...this.state.user, runonce2: false};
                this.setState({user: _user});
            }

            if(emailaddress === '' || password === '' || password !== confirmPassword  || emailaddressError !== '' ||  !PasswordField.getValidationRules().validate(password) ){
                success = false;
            }
        }
        else if (this.state.activeStep === 3) //bin information
        {
            const {owner, bin : _bin, permitnumber:_permitnumber, owneraddress, runonce3} = this.state.user;
            //format bin and permit number
            let bin = _bin.replace(/_/g, '');
            let permitnumber = _permitnumber.replace(/_/g, '');
            if (permitnumber === '-')
                permitnumber = '';

            if (runonce3)
            {
                const _user = {...this.state.user, runonce3: false};
                this.setState({user: _user});
            }

            if (owner.haslastnameerror || owner.hasfirstnameerror || owner.hasmiddlenameerror  ||
                bin === '' ||
                owneraddress.hasstreeterror || owneraddress.hascountryerror || owneraddress.hasprovinceerror ||  owneraddress.hascitymunicipalityerror || owneraddress.hasbarangayerror || owneraddress.haszipcodeerror
                )
                success = false;

            if (success)
            {
                //axios
                const bindetails = { lastname:owner.lastname, firstname:owner.firstname, middlename:owner.middlename, bin, permitnumber,
                    housenumber: owneraddress.housenumber, street: owneraddress.street, barangay: owneraddress.barangay  };

                const self = this;
                axios.post(config.server_url + '/matchbindetails', { bindetails }).then(function (response) {
                    const _user = {...self.state.user, error: ''};
                    self.setState({
                        activeStep: self.state.activeStep + 1,
                        user : _user
                        });
                }).catch(function(error){
                    self.props.showAlert(error.response.data.errors[0], 'error');

                    const _user = {...self.state.user, error: error.response.data.errors[0]};
                    self.setState({ user: _user });
                });
            }
        }
        if (success && this.state.activeStep !== 3) //axios
        {
            this.setState({
            activeStep: this.state.activeStep + 1,
            });
        }
    };

    handleLoad = () => {
        this.props.showLoadingRequest();
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    render() {

    const { classes } = this.props;
    const { applicationType } = this.state.user;
    const steps = (applicationType === 'NEW') ? this.getNewSteps():this.getRenewSteps();
    const { activeStep } = this.state;

        return (
            <div className="signinProperties">
            <Grid container spacing={24} justify="center">
                <Grid item xs={12} sm={6} >
                    {/* Title Part */}
                <AppBar position="static" >
                    <Toolbar className="appBarColor">
                        <NavLink to="/" exact={true}>
                            <Typography variant="title" className="arrowBack">
                                <ArrowBack/>
                            </Typography>
                        </NavLink>
                        <Typography variant="title" color="inherit" >
                        <Grid item>
                            BPLS-ONLINE
                        </Grid>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper >
                    <Alert handleCloseButton = {this.props.closeAlert }/>
                    <div className={classes}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => {
                            return (
                            <Step key={label.title}>
                                <StepLabel>{label.title}{(label.chip !== '' && label.chip !== undefined && activeStep !== 0) && <span>&nbsp;&nbsp;<Chip label ={label.chip}/></span> }
                                </StepLabel>
                                <StepContent>
                                    {this.getStepContent(label.id)}
                                    {activeStep !== 0  &&
                                    <div className={classes}>
                                        <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            className={classes}
                                        >
                                            Back
                                        </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={activeStep === steps.length - 1 ? this.onSubmit : this.handleNext}
                                                className={classes}
                                            >
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </div>
                                    </div>
                                    }
                                </StepContent>
                            </Step>
                            );
                        })}
                        </Stepper>
                        {/* {activeStep === steps.length && (
                        <Paper square elevation={0} className={classes}>
                            <Typography>All steps completed - you&quot;re finished</Typography>
                            <Button onClick={this.handleReset} className={classes}>
                            Reset
                            </Button>
                            <Button variant="contained" type="submit" onClick={this.onSubmit} primary={true}>Submit</Button>
                        </Paper>
                        )} */}
                    </div>
                    <div className="text footer">
                            Copyright 2018. All Rights Reserved.
                    </div>
                </Paper>
            </Grid>
        </Grid>
    </div>
        )
    }
}

/*
SignupContainer.propTypes = {
    userSignupRequest : PropTypes.func.isRequired
}
*/


const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        userSignupRequest: (userData) => {
            dispatch(userSignupRequest(userData))
        },
        showLoadingRequest : ()  => { 
            dispatch(showLoadingRequest())
        },
        hideLoadingRequest : ()  => { 
            dispatch(hideLoadingRequest())
        },
    }
};

const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
    }
}

export default connect( mapStateToProps, mapDispatchToProps)(SignupContainer);