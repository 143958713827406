import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Autorenew, Delete, Edit, Print } from '@material-ui/icons';
import axios from 'axios';
import { hideLoadingRequest, showLoadingRequest } from '../actions/session';
//import { TextField } from '@material-ui/core';
import history from '../history';

const config = require('../config');
const debug = require('debug')('client_af');
debug.enabled = process.env.REACT_APP_DEBUG;

const CustomTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	row: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
	fab:{
		margin: 5,
	}
});



class ApplicationListItem extends Component{

	handleLoad = (event, _id) => {
		if (_id === '')
			return;
		history.push('business-form/'+ _id);
	};

	handlePrint = (event, bin) => {
		if (bin === '')
			return;
		const _props = this.props;
		_props.showLoadingRequest();

		let token = localStorage.getItem('token');
		// const self = this;// assigned but never used
		axios.get(config.server_url + '/applicationform/' + bin,
		{ headers: {"Authorization" : 'bearer ' + token}, responseType : 'arraybuffer'
		}).then(function(response){
			let data = new Buffer(response.data, 'binary').toString('base64');
			const url = 'data:application/pdf;base64,' + data;
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', bin +'.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
			_props.hideLoadingRequest();
		}).catch(function(error){
			_props.hideLoadingRequest();
		});
	};

	render(){
		const { classes, n} = this.props;
		return (
			<TableRow className={classes.row} key={n.id}>
				<CustomTableCell >{n.stat}</CustomTableCell>
				<CustomTableCell component="th" scope="row">
					{n.firstname} {n.middlename} {n.lastname}
				</CustomTableCell>
				<CustomTableCell >{n.bin}</CustomTableCell>
				<CustomTableCell >{n.businessname}</CustomTableCell>
				<CustomTableCell >{n.businesstype}</CustomTableCell>
				<CustomTableCell >{n.taxyear}</CustomTableCell>
				<CustomTableCell > N/A</CustomTableCell>

				<CustomTableCell >
					{ n.bin !== null &&
						<Fab onClick={event => this.handleLoad(event, n._id)} color="primary" aria-label="Renew" title="Renew" size = 'small' className={classes.fab}>
							<Autorenew />
						</Fab>
					}
					{ n.bin === null &&
						<Fab onClick={event => this.handleLoad(event, n._id)} color="default" aria-label="Edit" title="Edit" size = 'small' className={classes.fab}>
							<Edit />
						</Fab>
					}
					<Fab color="secondary" aria-label="Delete" title="Delete" size = 'small' className={classes.fab}>
						<Delete />
					</Fab>
					{ n.bin !== null &&
						<Fab onClick={event => this.handlePrint(event, n.bin)} color="primary" aria-label="Print" title="Print" size = 'small' className={classes.fab}>
							<Print />
						</Fab>
					}
				</CustomTableCell>
			</TableRow>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	showLoadingRequest : ()  => dispatch(showLoadingRequest()),
	hideLoadingRequest : ()  => dispatch(hideLoadingRequest())
});


export default connect( null, mapDispatchToProps)(withStyles(styles)(ApplicationListItem))