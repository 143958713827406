import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withStyles } from "@material-ui/core";
import { Routes } from '../../routers/Routes';
import appStyle from '../../assets/css/appStyle';
import withLoading from '../withLoading';
import PrivatePage from './PrivatePage';

const PrivateRoute = ({path, exact, component: Component,...props}) => (
    <Route
        path = {path}
        exact = {exact}
        component={(compProps) =>(
            <PrivatePage {...props}>
                <Component {...props} {...compProps}/>
            </PrivatePage>
        )}
    />
)

class MainPage extends Component {

    /*
    constructor(props)
    {
        super(props);
    }
    */

    componentDidMount() {
        document.body.classList.toggle('darkClass', this.props.isAuthenticated)
        document.body.classList.toggle('lightClass', !this.props.isAuthenticated)
    }
    componentWillReceiveProps(nextProps) {
        document.body.classList.toggle('darkClass', nextProps.isAuthenticated)
        document.body.classList.toggle('lightClass', !nextProps.isAuthenticated)
    }

    render() {
        //fragment appends 404 page if resized  for some reason, this is not recommended but works
        const { user } = this.props;
        const RoutesList = Routes.map((value,key)=>{
            const {exact, path, component, title, from, to, access, type} = value
            if(type === "PrivateRoute" && (user !== undefined) && access === user.access)
                return <PrivateRoute key={key} exact={exact} path={path} component={component} title={title} {...this.props} />
            else if(type === "Route")
                return <Route key={key} path={path} component={component} exact={exact}/>
            else if(type === "Redirect")
                return <Redirect key={key} exact={exact} from={from} to={to}/>
            else return false
        })

        return(
            <Switch>
                {RoutesList}
            </Switch>
        )
    }
}


const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
        user : state.session.user,
    }
}

export default withRouter(withLoading(withStyles(appStyle)(connect( mapStateToProps , null )(MainPage))));
