import {alertConstants} from '../constants/alert'

const alertDefaultState = {
    message : '',
    color : '',
    open : false
}

export default (state = alertDefaultState, action) =>  {
    switch (action.type) {
        case alertConstants.SHOW_ALERT: 
            return {message: action.message, color: action.color,  open: action.open};
        case alertConstants.CLOSE_ALERT:
            return alertDefaultState;
        default: return state;
    }
};