import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CardContent, withStyles } from "@material-ui/core";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import { hideLoadingRequest, showLoadingRequest } from '../actions/session';
import regularCardStyle from "../assets/css/regularCardStyle";
import withLoading from '../components/withLoading';

const config = require('../config');

class BusinessInformationContainer extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            businesses : [],
            bin : '',
            data : '',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount()
    {
        const _props = this.props;
        _props.showLoadingRequest();
        let token = localStorage.getItem('token');
        const self = this;
        axios.get(config.server_url + '/business',
            { headers: {"Authorization" : 'bearer ' + token}
        }).then(function(response){
            self.setState({'businesses': response.data}, () => { _props.hideLoadingRequest() });
        }).catch(function(error){
            //console.error('An error has occurred');
            self.setState( {'businesses': {}}, () => { _props.hideLoadingRequest() });
        });
    }


    onChange(e)
    {
        this.setState({[e.target.name] : e.target.value});
        if (e.target.name === 'bin')
        {
            const _props = this.props;
            const self = this;
            //this.props.userSignupRequest(this.state);
            let token = localStorage.getItem('token');
            //set wait screen here
            this.props.showLoadingRequest();
            if (e.target.value === '')
            {
                self.setState({data : ''}, ()=> _props.hideLoadingRequest());
            }
            else
            {
                //https://github.com/wojtekmaj/react-pdf/issues/203
                axios.post(config.server_url + '/businessinfo', { bin : e.target.value, isApplication : 0, printedBy : 'SYS_PROG' }, { headers: {"Authorization" : 'bearer ' + token}, responseType: 'arraybuffer'}).then(function (response) {
                    let data = new Buffer(response.data, 'binary').toString('base64');
                    self.setState({data : 'data:application/pdf;base64,'+ data}, () => _props.hideLoadingRequest());
                }).catch(function(error){
                    self.setState({data : ''}, ()=> _props.hideLoadingRequest());
                    //_props.showAlert(error.response.data.errors[0], 'error');
                });
            }
        }
    }

    handleDownload = () => {
        const {bin} = this.state;
		if (bin === '')
			return;
        const _props = this.props;
        _props.showLoadingRequest();

		let token = localStorage.getItem('token');
		// const self = this;// assigned but never used
		axios.post(config.server_url + '/businessinfo/', { bin, isApplication : 0, printedBy : 'SYS_PROG' },
		{ headers: {"Authorization" : 'bearer ' + token}, responseType : 'arraybuffer'
		}).then(function(response){
			let data = new Buffer(response.data, 'binary').toString('base64');
			const url = 'data:application/pdf;base64,' + data;
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', bin +'.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
			_props.hideLoadingRequest();
		}).catch(function(error){
			_props.hideLoadingRequest();
		});

    }

    onSubmit(e)
    {
        e.preventDefault();
        this.props.addUser(this.state);
    }

    render() {
    const componentMargin = {
        marginRight: 75,
        width : '100%',
    }

    const { classes } = this.props;
    const { data:_data } = this.state;
    // const bin_selected = this.state.bin; // assigned a value but never used
    let bins = this.state.businesses.map(function(_bin,key){
        if (_bin.bin === '' || _bin.bin === undefined || _bin.bin === null || _bin.ispending === true)
            return '';
        // const _selected = (bin_selected === _bin.bin ) ? 'defaultValue' : ''; //  assigned a value but never used
        return <MenuItem key={key} value={_bin.bin}>{_bin.bin}</MenuItem>;
    });
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} >
                    <Card className={classes.card }>
                        <CardHeader color="primary" >
                            <h4 className={classes.cardTitleWhite}>Business Information</h4>
                            <p className={classes.cardCategoryWhite}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                            </p>
                        </CardHeader>
                        <CardBody>
                            <CardContent>
                                <FormControl style = {componentMargin}>
                                    <InputLabel >BIN</InputLabel>
                                        <Select
                                            onChange={this.onChange}
                                            value={this.state.bin}
                                            inputProps={{
                                                id: 'bin',
                                                name: 'bin'
                                            }}
                                        >
                                        <MenuItem value=""></MenuItem>
                                        {bins}
                                        </Select>
                                </FormControl>
                                <div>
                                    <object data={_data} type="application/pdf" style={{ height: '500px',width:'100%' }}>
                                        {this.state.bin !== '' ? <Link onClick={this.handleDownload}>Download</Link> : null }
                                    </object>
                                </div>
                            </CardContent>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


BusinessInformationContainer.defaultProps = {
    headerColor: "purple"
};
BusinessInformationContainer.propTypes = {
    plainCard: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
    cardTitle: PropTypes.node,
    cardSubtitle: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};

const mapDispatchToProps = dispatch => ({
    showLoadingRequest : ()  => dispatch(showLoadingRequest()),
    hideLoadingRequest : ()  => dispatch(hideLoadingRequest())
});

export default withLoading(connect( null, mapDispatchToProps)( withStyles(regularCardStyle)(BusinessInformationContainer)));