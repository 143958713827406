import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormControl, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from "@material-ui/icons";
import { closeAlert, showAlert } from '../actions/alert';
import forgotStyle from '../assets/css/forgotStyle';
import Alert from '../components/alerts/alert';
import EmailField from '../components/EmailField';
import history from '../history';

const config = require('../config');

class ForgotPasswordContainer extends Component{
    constructor(props) {
        super(props);

        if (this.props.isAuthenticated) //put to redirect in route
            history.push('/dashboard');

        this.state = {
            email : '',
        };
    }

    onChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [name] : value });
    }

    onSubmit = (e) =>
    {
        e.preventDefault();

        const _props = this.props;
        //this.props.userSignupRequest(this.state);
        axios.post(config.server_url + '/forgot', { email : this.state.email}).then(function (response) {
            _props.showAlert('Check your email to reset your password', 'success');
            history.push('/');
        }).catch(function(error){
            if(error.response.data.hasOwnProperty('errors')){
                _props.showAlert(error.response.data.errors[0], 'error');
            }else{
                console.log("Connection problem!");
            }
        });
    }

    render(){
        //const { classes } = this.props;
        return(
            <div className="LoginProperties">
                <div className="container">
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            {/* Title Part */}
                            <AppBar position="static" >
                                        <Toolbar className="appBarColor">
                                            <NavLink to="/" exact={true}>
                                                <Typography variant="title" className="arrowBack">
                                                    <ArrowBack/>
                                                </Typography>
                                            </NavLink>
                                            {/* <a href="/" variant="text" color="inherit" ><ArrowBack/></a> */}
                                            <Typography variant="title" color="inherit" >
                                            <Grid item>
                                                BPLS-ONLINE
                                            </Grid>
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <Paper >
                                        <form onSubmit={this.onSubmit}>
                                            <div className="forgot" >
                                                <Typography variant="title" color="inherit" align="center" gutterBottom >
                                                    Forgot password?
                                                </Typography>
                                                <Typography color="inherit">
                                                    Please enter your Email so we can send you an email to reset your password.
                                                </Typography>
                                                <EmailField
                                                    width='360px'
                                                    value = {this.state.email}
                                                    name="email"
                                                    onChange = {this.onChange}
                                                    validateEmail
                                                />
                                                <FormControl fullWidth  >
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Submit
                                                    </Button>
                                                </FormControl>
                                            </div>
                                        </form>
                                        <br />
                                    <div className="text footer">
                                            Copyright 2018. All Rights Reserved.
                                    </div>
                                </Paper>
                        </Grid>
                    </Grid>
                    <Alert />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
    }
}

const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
    }
}

export default connect(mapStateToProps ,mapDispatchToProps)(withStyles(forgotStyle)(ForgotPasswordContainer));