// ##############################
// // // forgot styles
// #############################

//import { drawerWidth, transition, container } from "./styles";

const forgotStyle = theme => ({
    arrowBack: {
        color: 'white',
        marginRight: '6px',
        height: '26px;',
      },
});

export default forgotStyle;
