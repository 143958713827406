import ForgotPasswordContainer from '../containers/ForgotPasswordContainer';
import LoginContainer from '../containers/LoginContainer';
import ResetPasswordContainer from '../containers/ResetPasswordContainer';
import SignupContainer from '../containers/SignupContainer';
import VerifyAccountContainer from '../containers/VerifyAccountContainer';
import AccountSettingsContainer from '../containers/AccountSettingsContainer';
import ApplicationFormContainer from '../containers/ApplicationFormContainer';
import BusinessContainer from '../containers/BusinessContainer';
import BusinessInformationContainer from '../containers/BusinessInformationContainer';
import NewBusinessContainer from '../containers/NewBusinessContainer';
import PaymentHistoryContainer from '../containers/PaymentHistoryContainer';
import RenewBusinessContainer from '../containers/RenewBusinessContainer';
import UserContainer from '../containers/UserContainer';
import DashboardPage from '../components/pages/DashboardPage';
import AdminDashboard from '../components/pages/AdminDashboard';
import NotFoundPage from '../components/pages/NotFoundPage';

const Routes = [
    {
        exact:true,
        type: "Route",
        path : "/",
        component : LoginContainer,
    },
    {
        type: "Route",
        path : "/signup",
        component : SignupContainer,
    },
    {
        type: "Route",
        path : "/forgot-password",
        component : ForgotPasswordContainer,
    },
    {
        type: "Route",
        path : "/reset-password/:code",
        component : ResetPasswordContainer,
    },
    {
        type: "Route",
        path : "/verify-account/:code",
        component : VerifyAccountContainer,
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/dashboard",
        component : DashboardPage,
        title : "Dashboard",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/application-form",
        component : ApplicationFormContainer,
        title : "Application Form",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/new-business-form",
        component : NewBusinessContainer,
        title : "New Business Application Form",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/business-form/:id",
        component : BusinessContainer,
        title : "Renew Business Application",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/renew-business-form",
        component : RenewBusinessContainer,
        title : "Renew Business Application",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/business-information",
        component : BusinessInformationContainer,
        title : "Business Information",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/payment-history",
        component : PaymentHistoryContainer,
        title : "Payment History",
    },
    {
        access: 'user',
        type: "PrivateRoute",
        path : "/account-settings",
        component : AccountSettingsContainer,
        title : "Account Settings",
    },
    {
        access: 'admin',
        type: "PrivateRoute",
        path : "/admin-dashboard",
        component : AdminDashboard,
        title : "Dashboard",
    },
    {
        access: 'admin',
        type: "PrivateRoute",
        path : "/account-settings",
        component : AccountSettingsContainer,
        title : "Account Settings",
    },
    {
        access: 'admin',
        type: "PrivateRoute",
        path : "/user-settings/:event/:id?",
        component : UserContainer,
        title : "User Settings",
    },
    {
        type: "Route",
        path : "*",
        component : NotFoundPage,
    },
]
export {
    Routes,
}
