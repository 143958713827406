import axios from 'axios';
import {sessionConstants} from '../constants/session';
//import session from '../reducers/session';
const config = require('../config');

export const loginSuccess = (user) => ( //update user information
    {
        type: sessionConstants.LOGIN_SUCCESS, 
        user
    }
);

export const loginFailed = (err) => (
    {
        type: sessionConstants.LOGIN_FAILED, 
        err
    }
);

export const showLoadingRequest = () => (
    {
        type : sessionConstants.LOADING_SHOW
    }
);

export const hideLoadingRequest = () => (
    {
        type : sessionConstants.LOADING_HIDE
    }
);

export const logoutRequest = ()  => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return { type : sessionConstants.LOGOUT};
};

export const getDefaultBinPrefix = () => 
{
    return new Promise( (resolve, reject) => {
        axios.get(config.server_url + '/binprefix' )
        .then(res => {
                return resolve(res.data.BIN_PREFIX);
        })
        .catch(error => {
            return resolve('');
        });
    });
}

export const loginRequest = (user, pass, rememberme, success, error) =>  dispatch => {
    axios.post(config.server_url + '/login', { username : user, password : pass, rememberme})
        .then( res => 
            {       
                const {user, token, refreshToken} = res.data;
                user.rememberme = rememberme;

                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
                dispatch(loginSuccess(user)); 

                getDefaultBinPrefix().then( res1 => {
                    dispatch(setBinPrefixRequest(res1));
                }, err => {
                });

                dispatch(helloRequest()); //sample hello
                success();
            }
        )
        .catch (err => {  dispatch(loginFailed(err)); error(); }  )
}

export const renewTokenRequest = (email, refreshToken) => dispatch => {
    axios.post(config.server_url + '/token', { email, refreshToken }).then( res => {
        localStorage.setItem('token', res.data.token);
    }).catch (
        err=> { dispatch(logoutRequest()) }
    )
}

export const helloRequest = () => dispatch => {
    let token = localStorage.getItem('token');
    axios.get(config.server_url + '/protected', { headers: {"Authorization" : 'bearer ' + token}}).then(res => {
        var hello = res.data;
        return dispatch( { type : sessionConstants.HELLO, hello} ); //wrong  call?
    })
};

export const setBinPrefixRequest = (binPrefix) =>  (
    {
        type : sessionConstants.BIN_PREFIX, 
        binPrefix
    }
);
