import React from 'react';

const Summary = (props) => {

    var _ocountry = props.user.owneraddress.countries.find(function(e) {
        return props.user.owneraddress.country === Object.keys(e)[0];
    });
    if (_ocountry !== undefined && _ocountry !== null)
        _ocountry = Object.values(_ocountry)[0];

    var _oprovince = props.user.owneraddress.provinces.find(function(e){
        return props.user.owneraddress.province === Object.keys(e)[0];
    });
    if (_oprovince !== undefined && _oprovince !== null)
        _oprovince = Object.values(_oprovince)[0];

    var _ocitymunicipality = props.user.owneraddress.citymunicipalities.find(function(e){
        return props.user.owneraddress.citymunicipality === Object.keys(e)[0];
    });
    if (_ocitymunicipality !== undefined && _ocitymunicipality !== null)
        _ocitymunicipality = Object.values(_ocitymunicipality)[0];

    var _obarangay = props.user.owneraddress.barangays.find(function(e){
        return props.user.owneraddress.barangay === Object.keys(e)[0];
    });
    if (_obarangay !== undefined && _obarangay !== null)
        _obarangay = Object.values(_obarangay)[0];
    
    const renewal  = (props.user.applicationType === 'RENEWAL') ?
    <div> 
        <div>Owner : {props.user.owner.lastname}, {props.user.owner.firstname} {props.user.owner.middlename}</div> 
        <div>Address: {props.user.owneraddress.housenumber} {props.user.owneraddress.street} {_oprovince} {_ocitymunicipality} {_obarangay} {props.user.owneraddress.zipcode} {_ocountry}</div>
    </div>
    : '';


    var _country = props.user.personaddress.countries.find(function(e) {
        return props.user.personaddress.country === e.country_code;
    });
    if (_country !== undefined && _country !== null)
        _country = _country.country_name;

    var _province = props.user.personaddress.provinces.find(function(e){
        return props.user.personaddress.province === Object.keys(e)[0];
    });
    if (_province !== null)
        _province = Object.values(_province)[0];

    var _citymunicipality = props.user.personaddress.citymunicipalities.find(function(e){
        return props.user.personaddress.citymunicipality === Object.keys(e)[0];
    });
    if (_citymunicipality !== null)
        _citymunicipality = Object.values(_citymunicipality)[0];

    var _barangay = props.user.personaddress.barangays.find(function(e){
        return props.user.personaddress.barangay === Object.keys(e)[0];
    });
    if (_barangay !== null)
        _barangay = Object.values(_barangay)[0];
    
    return <div>
        Summary
        <div>Person : {props.user.person.lastname}, {props.user.person.firstname} {props.user.person.middlename}</div>
        <div>Contact Number : {props.user.contactnumber}</div>        
        <div>Address: {props.user.personaddress.housenumber} {props.user.personaddress.street} {_province} {_citymunicipality} {_barangay} {props.user.personaddress.zipcode} {_country}</div>
        {renewal}
    </div>;

};


export default Summary;