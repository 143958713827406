import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['alert'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const initialState = {}; // assigned a value but never used
const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);


export default () => {
    const store = createStore(
        persistedReducer,
        undefined,
        composeEnhancers(
            applyMiddleware(thunk),
        ),
    );
    const persistor = persistStore(store);
    // console.log(store.getState());
    return {store, persistor};
};
