import React from 'react';

//import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
  },
});


const TextMaskCustom = seriesLength => props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            placeholderChar={'_'}
            mask={ [...Array(4).fill(/\d/), '-', ...Array(seriesLength).fill(/\d/) ] }
            showMask
        />
    );

}


class MayorsPermitField extends React.Component {

    constructor(props){
        super(props)

        const {
            seriesLength = 5,
        } = this.props;

        this.prefix = seriesLength
        this.maskControl = TextMaskCustom(seriesLength)
    }

    onChange = event => {
        const {
            onChange = () => true
        } = this.props

        onChange(event)
    }

    render(){
        const {
            classes,
            label =  "Mayor's Permit No.",
            name,
            value = null,
            error,
            seriesLength = 5,
            disabled,
        } = this.props

        // not needed for now (prefix doesn't change dynamically)
        if(this.seriesLength !== seriesLength){
            this.maskControl = TextMaskCustom(seriesLength)
            this.seriesLength = seriesLength
        }

        return <FormControl className={classes.formControl}>
          <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
          <Input
            id="formatted-text-mask-input"
            name={name}
            value={value || '\u2000'.repeat( 5 + seriesLength)  }
            onChange={this.onChange}
            inputComponent={this.maskControl}
            error={error}
            disabled={disabled}
          />
        </FormControl>
    }

}

/*
MayorsPermitField.propTypes = {
}
*/
export default withStyles(styles)(MayorsPermitField);