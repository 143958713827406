import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import MaskedInput from 'react-text-mask';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width:'100%'
    },
});

const TextMaskCustom = (dialingCode, maxDigits) => props => {
        const { inputRef, ...other } = props;
        const mask = (dialingCode, maxDigits = 0) => rawValue => {
            let m = [];
            if(dialingCode === '-'){
                for(let i = 0; i < rawValue.length && (maxDigits === 0 || i < maxDigits); i++){
                    m.push(/\d/)
                }
                return m;
            }
            m = [ '+' ]
            rawValue = rawValue.trim();
            dialingCode.split('').map((v) => (
                m.push(v)
            ));
            for(let i = m.length; i < rawValue.length && (maxDigits === 0 || i < maxDigits); i++){
                m.push(/\d/)
            }
            return m
        }

        return (
            <MaskedInput
                {...other}
                // ref={inputRef}
                placeholderChar={'\u2000'}
                mask={mask(dialingCode, maxDigits)}
                showMask
            />
        );
    }


class MobileNumberField extends React.Component {

    constructor(props) {
        super(props);

        const {
            dialingCode = '-',
            maxDigits = 0,
        } = props;

        this.dialingCode = dialingCode;
        this.maxDigits = maxDigits;

        this.maskControl = TextMaskCustom(dialingCode, maxDigits)
    }

    render(){

        const {
            classes,
            label = "Mobile Number",
            name,
            value,
            dialingCode = '-',
            maxDigits = 0,
            onChange = () => true,
            error,
            disabled,
            lgSize = 2, // Grid lg default is 3
            mdSize = 6, // Grid md default is 6
            xsSize = 12, // Grid xs default is 12
        } = this.props;

        if(this.dialingCode !== dialingCode || this.maxDigits !== maxDigits){
            this.dialingCode = dialingCode;
            this.maxDigits = maxDigits;
            this.maskControl = TextMaskCustom(dialingCode, maxDigits)
        }

        const _header = (dialingCode === '-' ? '' : '+' + dialingCode);
        let sTextMask =  _header;
        if (_header === '' || value.startsWith(_header))
            sTextMask = value;
        return <Grid item xs={xsSize} md={mdSize} lg={lgSize}>
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
            <Input
                id="formatted-text-mask-input"
                name={name}
                value={sTextMask}
                onChange={onChange}
                inputComponent={this.maskControl}
                error={error}
                disabled={disabled}
            />
        </FormControl>
        </Grid>
    }

}

export default withStyles(styles)(MobileNumberField);