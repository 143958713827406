import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import LoadingSpinner from './LoadingSpinner'
import Modal from '@material-ui/core/Modal';

const withLoading = (Component) => (props) =>
{
    const {isLoading, ...rest} = props;
    return  <div><Component {...rest}/>{isLoading ? (<Modal open={true}><div><LoadingSpinner/></div></Modal> ): <div></div>}</div>;
}

const mapStateToProps = state => {
    return  {
        isLoading : state.session.isLoading,
    }
}

const composedWithLoading = compose (
    connect(mapStateToProps, null),
    withLoading
)

export default composedWithLoading;