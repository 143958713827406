import React, {Component} from 'react'
import { connect } from 'react-redux'

import {showAlert,closeAlert} from '../actions/alert'
import {loginRequest} from '../actions/session'

import Typography from '@material-ui/core/Typography';
import { NavLink} from 'react-router-dom';

//import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel } from '@material-ui/core/';
import {FormControl} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';

import Alert from '../components/alerts/alert'

//CSS
import '../assets/css/pages.css'
import history from '../history';
import EmailField from '../components/EmailField';
import PasswordField from '../components/PasswordField';

class LoginContainer extends Component{
    constructor(props) {
        super(props);

        if (this.props.isAuthenticated) //put to redirect in route
        {
            const { userProperty  : user } = this.props;
            (user.access === 'admin') ? (history.push('/admin-dashboard')) : (history.push('/dashboard'))
        }

        this.state = {
            username : '',
            password : '',
            rememberMe : false,
        };
    }

    onRememberMeChange = () => {
        this.setState( { rememberMe : !this.state.rememberMe} );
    }

    onChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [name] : value });
    }

    /*
    // function to close after 5 min
    hideAlert(){
        let count = 0
        let intertvalId = setInterval(()=>{
            count++
            if(count === 5){
                clearInterval(intertvalId);
                this.props.closeAlert() // dispatch

            }
        },1000)
    }
    */

    onLogin = (e) =>
    {
        e.preventDefault();
        if (this.state.username === '' || this.state.password === '')
        {
            this.props.showAlert('Invalid username/password.', 'success');
        }
        else
        {
            this.props.closeAlert();
            this.props.loginRequest(this.state.username, this.state.password, this.state.rememberMe, this.onSuccess, this.onFailure);
        }
    }

    onSuccess = () =>
    {
        const { userProperty  : user } = this.props;
        (user.access === 'admin') ? ( history.push('/admin-dashboard')) : (history.push('/dashboard'))
    }

    onFailure = () =>
    {
        this.props.showAlert('Invalid username/password.', 'error');
    }


    render(){
        return(
            <div className="LoginProperties">
                <div className="container">
                    <Grid container spacing={24} >
                        <Grid item xs={12}>
                            <AppBar position="static" >
                                <Toolbar className="appBarColor">
                                    <Typography variant="title" color="inherit" >
                                    <Grid item>
                                        BPLS-ONLINE
                                    </Grid>
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                                <Paper >
                                    <form onSubmit={this.onLogin}>
                                        <EmailField
                                            width='380px'
                                            value = {this.state.username}
                                            name="username"
                                            onChange = {this.onChange}
                                        />
                                        <PasswordField
                                            value={this.state.password}
                                            width='380px'
                                            name = 'password'
                                            onChange={ this.onChange }
                                        />
                                        <div className="margin">
                                            <Grid container spacing={24} justify="center">
                                                <Grid item  sm={7} >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="rememberMe"
                                                                // value={this.state.rememberMe}
                                                                onChange={this.onRememberMeChange}
                                                                color="primary"
                                                                checked={this.state.rememberMe}
                                                            />
                                                        }
                                                    label="Remember Me"
                                                    />
                                                </Grid>
                                                <Grid item sm={5} >
                                                    {/* Blank Grid */}
                                                </Grid>
                                            </Grid>
                                            <FormControl fullWidth  >
                                                <Button variant="contained" color="primary" type="submit">
                                                    Sign In
                                                </Button>
                                            </FormControl>
                                            <Grid container spacing={24} justify="center">
                                                <Grid item  sm={7} >
                                                    <NavLink to="/signup" activeClassName="is-active" className="link">
                                                        <Typography variant="body1" >
                                                                Not registered yet,Register Now
                                                        </Typography>
                                                    </NavLink>
                                                </Grid>
                                                <Grid item sm={5} >
                                                    <NavLink to="/forgot-password" activeClassName="is-active" className="link">
                                                        <Typography variant="body1" >
                                                                Forgot Password
                                                        </Typography>
                                                    </NavLink>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </form>
                                    <br />
                                <div className="text footer">
                                        Copyright 2018. All Rights Reserved.
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Alert />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        loginRequest: (user,pass, rememberme, success, err) => {
            dispatch(loginRequest(user, pass, rememberme, success, err));
        }
    }
}

const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
        userProperty : state.session.user,
    }
}

export default connect(mapStateToProps ,mapDispatchToProps)(LoginContainer);