import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

function CurrencyNumberFormat(props) {
	const { inputRef, onChange, name, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			allowNegative = {false} // added
			decimalScale = {0} // added
			onValueChange={values => {
			onChange({
				target: {
					value: values.value,
					name
				},
			});
			}}
			thousandSeparator
		/>
	);
}

CurrencyNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

const styles = theme => ({
	textField: {
		width: "100%",
	},
});

class NumberField extends React.Component {
	render() {
		const {
			classes,
			name,
			id,
			type = "text",
			label,
			onChange,
			required = false,
			disabled = false,
			value,
			unit = null,
			Currencyformat = false,
			xsSize,
			mdSize,
			lgSize,
			error = false,
		} = this.props;
		return (
			<Grid item xs={xsSize} md={mdSize} lg={lgSize}>
				<FormControl
					className={classes.textField}
					aria-describedby="weight-helper-text"
					>
					{ (unit !== null && !Currencyformat) ?
					<Fragment>
						<InputLabel htmlFor="adornment-amount">{label}</InputLabel>
						<Input
							type={type}
							id={id}
							name={name}
							value={value}
							onChange={onChange}
							required={required}
							endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
							disabled={disabled}
						/>
					</Fragment>
					:
					(Currencyformat) ?
					<TextField
						error={error}
						type={type}
						id={id}
						name={name}
						value={value}
						label={label}
						onChange={onChange}
						required={required}
						InputProps={ { inputComponent: CurrencyNumberFormat, 
										endAdornment: <InputAdornment position="end">{unit}</InputAdornment>}
						}
						disabled={disabled}
					/>:
					<TextField
					    error={error}
						type={type}
						id={id}
						name={name}
						value={value}
						label={label}
						onChange={onChange}
						required={required}
						disabled={disabled}
					/>
					}
				</FormControl>
			</Grid>
		);
	}
}

NumberField.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumberField);
