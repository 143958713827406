import Grid from '@material-ui/core/Grid';
import React, { Component, Fragment } from 'react';
import BusinessField from '../BusinessField';
import BINField from '../BINField';
import MayorsPermitField from '../MayorsPermitField';
import EmailField from '../EmailField';
import GlobalAddress from '../GlobalAddress';
import MobileNumberField from '../MobileNumberField';

class BusinessInformation extends Component {

    handleChange = (e) =>{
        const {name, value} = e.target;
        this.props.onBusinessInfoChanged( {...this.props.businessinfo, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    }

    render(){
        const props = this.props
        const isRenewal = props.status !== undefined && props.status === 'REN';
        return(
            <Grid container spacing={16}>
                { isRenewal ? (
                    <Fragment>
                    <Grid item lg={2} sm={6} md={6} xs={12}>
                        <BINField 
                            prefix={this.props.binPrefix} 
                            name="bin" 
                            helperText={props.error} 
                            value={props.businessinfo.bin} 
                            onChange={this.handleChange} 
                            required placeholder="BIN"  
                            error={  false && !props.runonce}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item lg={2} sm={6} md={6} xs={12}>
                    <MayorsPermitField seriesLength={7} 
                        name="permitnumber" 
                        value={props.businessinfo.bnsplate}
                        onChange={this.handleChange}
                        required placeholder="Mayor's Permit No."
                        error={false && !props.runonce}
                        disabled={true}
                    />
                    </Grid> 
                    </Fragment>
                ) : null }
                <BusinessField {...props.businessinfo} runonce={props.runonce} isrenewal={isRenewal} onBusinessFieldChanged={props.onBusinessInfoChanged} onSaveAddtlbusiness={props.onSaveAddtlbusiness} width="70%" />
                { isRenewal ? (
                    <Grid item lg={8} md={12} xs={12}>
                    </Grid> 
                ): null}
                <MobileNumberField
                    name='contactnumber'
                    label='Contact Number'
                    dialingCode={props.businessinfo.dialingcode}
                    value={props.businessinfo.contactnumber}
                    onChange = {this.handleChange}
                    error={false}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                    disabled={isRenewal}
                />
                <EmailField
                    value = {props.businessinfo.emailaddress}
                    name="emailaddress"
                    onChange = {this.handleChange}
                    validateEmail
                    // runonce={props.businessinfo.hasemailaddresserror}
                    errorText={props.businessinfo.emailaddresserror}
                    runonce={props.businessinfo.emailaddress === ''} // email address not neccessary has a validation in this part
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                    disabled={isRenewal}
                    onEmailChange={props.onEmailChange}
                />
                <GlobalAddress {...props.businessinfo} runonce={props.runonce} onAddressChanged={props.onBusinessInfoChanged}/>
            </Grid>
        )
    }
}
export default BusinessInformation;
