// ##############################
// // // App styles
// #############################

import { drawerWidth, transition } from "./styles";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "#eeeeee"

  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    width: "100%",
  },
  content: {
    padding: "10px 20px",
    minHeight: "500px"
  },
  container: {
    marginTop: "50px"
  },
  button: {
    margin: theme.spacing.unit
  },
  title:{
    margin: "20px 5px"
  },
  pageBar:{
    backgroundColor: "#fff",
    position: "relative",
    margin: "-36px -15px 0 -20px"
  },
  breadcrumb:{
    // display: "inline-block",
    padding: "0px 0px 0px 25px",
    margin: 0,
    listStyle: "none"
  },
  breadcrumbLink:{
    color: "#000",  
    padding: "10px 0px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    position: "relative"
  },
  greaterThan:{
    top: "7px",
    color: "#333",  
    position: "relative",
    opacity: "0.4"
  },

  titlePageDesign:{
    margin :"10px 0px",
    color: "#2196f3",
    paddingLeft: "30px",
    fontFamily: "Times New Roman",
    fontSize: "35px",
    fontWeight: "bold"
  },
});

export default appStyle;
