import React from "react";
import PasswordField from '../components/PasswordField';

class ChangePassword extends React.Component{
	state = {
		password: '',
		showPassword: false,
	};

	handleChange = prop => event => {
		if(this.props.onChange !== null){
			this.props.onChange(prop, event.target.value);
		}
		this.setState({ [prop]: event.target.value });
	};

	render(){
		const {
			// onChange = () => true, //assigned a value but never used
			value = {},
		} = this.props;
		return(
			<div>
				<div>
					<PasswordField
						value={value.currentpassword}
						width='300px'
						validatePassword
						onChange={ this.handleChange('currentpassword') }
						label="Old Password"
					/>
				</div>
				<div>
					<PasswordField
						value={value.password}
						width='300px'
						validatePassword
						onChange={ this.handleChange('password') }
					/>
				</div>
				<div>
					<PasswordField
						width='300px'
						value = {value.confirmPassword}
						validatePassword
						shouldMatch={this.state.password}
						label="Confirm Password"
						onChange={ this.handleChange('confirmPassword') }
					/>
				</div>
			</div>
		)
	}
}


export default ChangePassword
