import React, {Component} from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
//import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import validator from 'validator';

import {
	Cancel,
	CheckCircle
} from '@material-ui/icons';

const styles = theme => ({
  textFieldHasGrid: {
    width: '100%',
  },
  textField: {
    flexBasis: 200,
    margin: theme.spacing.unit,
  },
  iconGreen: {
  	color: "green",
  },
  iconRed: {
  	color: "red",
  },
  icon: {
    marginRight: "12px",
  },
});


class EmailField extends Component {

  isEmailValid = email => {
    /*
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    */
    return validator.isEmail(email);
  }

  checkEmail = fn => e => {
    
    const email = e.target.value;

    const {
      onEmailChange = null,
      onChange = null,
    } = this.props;

    /*
    const {
      sErrorText
    } = this.state;

    if(onChange){
      onChange(e, sErrorText);
    }

    if(this.isEmailValid(email)){
      if( fn(email) === true ){
        this.setState({errorText: ''});
      }else{
        // do nothing, show error message passed in props
      }
    }else{
      this.setState({sErrorText: "Email invalid"})
    }
    this.setState({sValue: email})
    */
    const isValid  = this.isEmailValid(email);
    if (onEmailChange) 
    {
      onEmailChange( { value: email, errorText: isValid ? "" : "Email invalid", runonce : false });
      if (isValid)
        fn(email); //callback
    }
    else if(onChange)
    {
      onChange(e,  isValid ? "" : "Email invalid");
    }
  }


  render() {
    const {
      classes,
      width,
      onCheckEmail = () => true,
      validateEmail = false,
      errorText = 'Invalid Email.',
      placeholder = "hello@example.com",
      value = "",
      // runonce = true,
      runonce,
      name = null,
      lgSize, 
      mdSize, 
      xsSize,
      disabled = false,
    } = this.props;

    const hasError =  errorText !== null && errorText !== '' && !runonce;
    const hasGrid = (lgSize || mdSize || xsSize)? true : false;
    return (
      <Grid item xs={xsSize} md={mdSize} lg={lgSize}>
          <FormControl error={validateEmail && hasError} className={hasGrid ? classes.textFieldHasGrid :  classes.textField} >
            <InputLabel htmlFor="adornment-password">Email Address</InputLabel>
            <Input
              value={value}
              name={name}
              type='email'
              placeholder={placeholder}
              style={{width}}
              onChange={ this.checkEmail(onCheckEmail) }
              disabled={disabled}
              endAdornment={
                <InputAdornment position="end">
                  {
                    validateEmail && !runonce &&
                    (
                      hasError ?
                      <Cancel className={classes.iconRed + " " + classes.icon} />
                      : <CheckCircle className={classes.iconGreen + " " + classes.icon} />
                    )
                  }
                </InputAdornment>
              }
            />
            { validateEmail && hasError &&
              <FormHelperText>{errorText}</FormHelperText>
            }
          </FormControl>
        </Grid>
    );
  }
}

EmailField.propTypes = {
  classes: PropTypes.object.isRequired,
  // onCheckEmail : PropTypes.func.isRequired
};

export default withStyles(styles)(EmailField);



