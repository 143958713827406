import { Card, CardContent, CardHeader, withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { userSignupRequest } from '../actions/users';
import {getOwnInfo} from '../actions/application'
import regularCardStyle from "../assets/css/regularCardStyle";
import { closeAlert, showAlert } from '../actions/alert';
import Alert from '../components/alerts/alert';
import BasicInformation from "../components/newApplication/BasicInformation";
import BusinessInformation from "../components/newApplication/BusinessInformation";
import OtherAddtlInformation from "../components/newApplication/OtherAddtlInformation";
import OwnersInformation from "../components/newApplication/OwnersInformation";
import history from '../history';
import withLoading from '../components/withLoading';
import { hideLoadingRequest, showLoadingRequest } from '../actions/session';

const moment = require('moment');

const debug = require('debug')('client_bc');
debug.enabled = process.env.REACT_APP_DEBUG;
const config = require('../config');

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const initState = {
    basicinfo : {
        stat : 'REN',
        taxyear : '',
        dateapplication : '',
        datestarted : '',
        dtiseccdaregnum : '',
        dtiseccdadateissued : '',
        tinnum : '',
        tindateissued : '',
        sssnum : '',
        sssdateissued : '',
        ctcnum : '',
        ctcdateissued : '',
        isstatdisabled : true ,
        istaxyeardisabled : true ,
        isdateapplicationdisabled : false ,
        isdatestarteddisabled : true ,
        isdtiseccdaregnumdisabled: false,
        isdtiseccdadateissueddisabled: false,
        istinnumdisabled: false,
        istindateissueddisabled: false,
        issssnumdisabled: false,
        issssdateissueddisabled: false,
        isctcnumdisabled: false,
        isctcdateissueddisabled: false,
    },
    runonce1 : true,
    businessinfo :{
        bin : '',
        bnsplate: '',
        businessname: '',
        orgnkind: '',
        businesstype: '',
        addtlbusiness: [{businesstype: "", stat:"NEW", capital: ""}],
        capital: '',
        gr1 : '',
        gr2: '',
        contactnumber : '',
        emailaddress: '',
        housenumber : '',
        street : '',
        province : '031400000',
        citymunicipality : '031410000',
        barangay : '',
        zipcode : '3000',
        country : 'PH',
        streetlabel : 'Street',
        dialingcode : '63',
        countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
        provinces : [ {'031400000': 'BULACAN'}],
        citymunicipalities : [{'031410000':'CITY OF MALOLOS'}],
        barangays : [{'':''}],
        isfulladdress: true,
        isaddressdisabled : true,
        ishousenumberdisabled : true,
        isstreetdisabled : true,
        isbarangaydisabled : true,
        hasbusinessnameerror : false,
        hasorgnkinderror : true,
        hasbusinesstypeerror : true,
        hascapitalerror : true,
        hascontactnumbererror : true,
        hashousenumber : true,
        hasstreeterror : true,
        hasprovinceerror : false,
        hascitymunicipalityerror : false,
        hasbarangayerror : true,
        haszipcodeerror : false,
        hascountryerror : false,
        loading : false,
        runonce : true
    },
    runonce2 : true,
    owner : {
        ownercode: '',
        lastname : '',
        firstname : '',
        middlename : '',
        gender : '',
        bdate: '',
        nationality: '',
        contactnumber : '',
        emailaddress: '',
        housenumber : '',
        street : '',
        province : '',
        citymunicipality : '',
        barangay : '',
        zipcode : '',
        country : 'PH',
        streetlabel : 'Street',
        dialingcode : '63',
        countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
        provinces : [ {'':''}],
        citymunicipalities : [{'':''}],
        barangays : [{'':''}],
        numemployees : 0,
        floorarea : 0,
        isfulladdress: true,
        isaddressdisabled : true,
        isbarangaydisabled : true,
        haslastnameerror : true,
        hasfirstnameerror : true,
        hasmiddlenameerror : true,
        hasemailaddresserror : false,
        hascontactnumbererror : true,
        hasbdateerror : true,
        hasstreeterror : true,
        hasprovinceerror : true,
        hascitymunicipalityerror : true,
        hasbarangayerror : true,
        haszipcodeerror : true,
        hascountryerror : false,
        loading : false,
        runonce : true,
        disabled : true
    },
    runonce3 : true,
    otherinfo :{
        // placeoccupancy : '',
        placeoccupancy : '',
        rentleasesince : '',
        rentleasemonthly : '',
        ownercode: '',
        lastname : '',
        firstname : '',
        middlename : '',
        housenumber : '',
        contactnumber : '',
        street : '',
        province : '',
        citymunicipality : '',
        barangay : '',
        zipcode : '',
        country : 'PH',
        streetlabel : 'Street',
        dialingcode : '63',
        countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
        provinces : [ {'':''}],
        citymunicipalities : [{'':''}],
        barangays : [{'':''}],
        isrentleasesincedisabled : false,
        isrentleasemonthlydisabled : false,
        isnamedisabled : false,
        ishousenumberdisabled : false,
        isstreetdisabled : false,
        isbarangaydisabled : false,
        iscontactnumberdisabled : false,
        isaddressdisabled : false,
        bnsplate : '',
        runonce : true
    },
    runonce4 : true,
};

class BusinessContainer extends Component {

    constructor(props)
    {
        super(props);
        this.props.closeAlert();
        this.state = {
            activeStep: 1,
            value: 1 ,
            business : initState
        }

    }

    getSteps = () => {
        return ['Basic Information', 'Business Information', 'Owners Information/ Profile' , 'Other additional Information'];
    }

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
            return  <BasicInformation
                        basicinfo={this.state.business.basicinfo}
                        onBasicinfoChanged={this.onChanged('basicinfo')}
                        runonce={this.state.business.runonce1}
                    />;
            case 1:
            return  <BusinessInformation
                        binPrefix={this.props.binPrefix}
                        businessinfo={this.state.business.businessinfo}
                        status={this.state.business.basicinfo.stat}
                        onBusinessInfoChanged={this.onChanged('businessinfo')}
                        onSaveAddtlbusiness={this.onSaveAddtlbusiness}
                        runonce={this.state.business.runonce2}
                    />;
            case 2:
            return  <OwnersInformation
                        owner={this.state.business.owner}
                        onOnwerChanged={this.onChanged('owner')}
                        runonce={this.state.business.runonce3}
                        getOwnInfo = {this.props.getOwnInfo}
                        getOwnInfoList = {this.props.ownerInfoList}
                        getSelectedInfo = {this.handleChange} 
                    />;
            case 3:
            return  <OtherAddtlInformation
                        owner={this.state.business.owner}
                        otherinfo={this.state.business.otherinfo}
                        otherinfoOrigin={initState.otherinfo}
                        onOtherInfoChanged={this.onChanged('otherinfo')}
                        runonce={this.state.business.runonce4}
                        getOwnInfo = {this.props.getOwnInfo}
                        getOwnInfoList = {this.props.ownerInfoList}
                        getSelectedInfo = {this.handleOtherInfoChange} 
                    />;
            default:
            return 'Uknown stepIndex';
        }
    }

    componentDidMount = () => {
        var self = this;
        var _props = this.props;

        axios.get(config.server_url + '/isodate', {}).then(function (response) {
            try
            {
                let m = moment();
                m = moment(response.data.ISO_DATE);
                let basicinfo = self.state.business.basicinfo;
                //basicinfo.taxyear = m.format("YYYY");
                basicinfo.dateapplication = m.format("YYYY-MM-DD");
                //basicinfo.datestarted = m.format("YYYY-MM-DD");

                self.setState( prevState => ( {
                    business : { ...prevState.business,  basicinfo }
                }));
            }
            catch (e) {
            }

        }).catch(function(error){
            debug('%O', error);
        });
        
        axios.get(config.server_url + '/bnskinds').then(function(response){
            var _bnskinds = [];
            response.data.forEach(function(element)
            {
                _bnskinds.push({ label: element , value: element });
            });

            self.setState(ps =>  ({
                business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , orgnKind : _bnskinds ,  loading : false}}
            }))
        });

        axios.get(config.server_url + '/allbnstypes').then(function(response){
            var _mainbnstypes = [];
            const data = response.data;
            Object.keys(data).forEach(function(key){
                _mainbnstypes.push({ label: data[key] , value: key.trim() });
            })
            self.setState(ps =>  ({
                business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , businessType : _mainbnstypes ,  loading : false}}
            }))
        })


        axios.get(config.server_url + '/bnsplace').then(function(response){
            var _bnsplace = [];
            response.data.forEach(function(element)
            {
                _bnsplace.push({ label: element , value: element });
            });

            self.setState(ps =>  ({
                business: { ...ps.business, otherinfo: { ...ps.business.otherinfo , placeOccupancyList : _bnsplace ,  loading : false}}
            }))
        });


        _props.showLoadingRequest();
        var {_id} = this.props.user;

        const param = this.props.match.params.id;
		let token = localStorage.getItem('token');
		axios.get(config.server_url + '/business/' + param, { headers: {"Authorization" : 'bearer ' + token}
		}).then(function(response){
            const application = response.data;
            if (application.bin === null) 
            {
                //just load the data from mongo (?)
                let newState = initState;
                newState.basicinfo.stat = application.stat;
                newState.businessinfo.businessname = application.businessname;
                //set individual values here

                self.setState( {value : 1 , business : newState} );
            }
            else
            {   
                if (_id !== application.userid)
                {
                    _props.hideLoadingRequest();                    
                    _props.showAlert('Bin does not belong to current user.', 'error');
                    history.push('/application-form');
                    return;
                }

                //call oracle BO_BPS_LOAD_REN_BIN here to validate
                const query = `{
                    validateRenewal(bin: "${application.bin}")
                }`
                axios.get(config.server_url + '/public?query=' + query).then(res => {
                    const result = JSON.parse(res.data.data.validateRenewal);
                    _props.hideLoadingRequest();                    
                    if (result.error)
                    {
                        _props.showAlert(result.error, 'error');
                        //mark as error on mongodb
                        history.push('/application-form');
                        return;
                    }
                    else
                    {
                        debug('Result is as follows %O', result);
/*
annualwages: 0
aveelectricbill: 0
avephonebill: 0
avewaterbill: 0
bin: "216-00-2009-0000643"
bldgpin: " "
bldgval: 0
bnsuser: "ESANTOS"
businessinfo_barangay: "BULIHAN"
businessinfo_citymunicipality: "CITY OF MALOLOS"
businessinfo_district: " "
businessinfo_housenumber: " "
businessinfo_province: " "
businessinfo_street: "MCARTHUR HIWAY"
businessinfo_zipcode: " "
businessinfo_zone: " "
businessname: "J.C. NOODLE HOUSE"
businesstype: "1010"
busnown: "3562"
cancby: ""
cancdate: ""
cancreason: " "
capital: 0
contactnumber: "09195342686"
ctcdateissued: ""
ctcissuedat: " "
ctcnum: " "
dtiseccdadateissued: "01-JUL-09"
dtiseccdaregnum: "794180"
dtoperated: "03-JUL-09"
emailaddress: ""
flrarea: 0
initialcapital: 148888
isbusinesstypedisabled: true
landpin: " "
machpin: " "
memoranda: "FAJARDO"
numdelivvehicle: 0
numemployees: 2
nummachineries: 0
numprofessional: 0
numstoreys: 0
orgnkind: "SINGLE PROPRIETORSHIP"
orno: " "
otherinfo_barangay: "BULIHAN"
otherinfo_bdate: ""
otherinfo_citymunicipality: "CITY OF MALOLOS"
otherinfo_contactnumber: ""
otherinfo_emailaddress: ""
otherinfo_firstname: " "
otherinfo_gender: ""
otherinfo_housenumber: "KM 42"
otherinfo_lastname: "PUREGOLD PRICE CLUB INC"
otherinfo_middlename: " "
otherinfo_nationality: ""
otherinfo_street: "MCARTHUR HI-WAY"
otherinfo_zipcode: ""
otherutil: 0
owncode: "4866"
owner_barangay: "BULIHAN"
owner_bdate: ""
owner_citymunicipality: "CITY OF MALOLOS"
owner_contactnumber: ""
owner_emailaddress: ""
owner_firstname: "MA LEONORA"
owner_gender: ""
owner_housenumber: " "
owner_lastname: "ZAMORA"
owner_middlename: "F"
owner_nationality: ""
owner_street: "MCARTHUR HIWAY"
owner_zipcode: ""
permitdt: "22-JUL-09"
permitno: ""
placeoccupancy: "RENTED"
poffbrgy: "BULIHAN"
poffdist: " "
poffhouseno: " "
poffmun: "CITY OF MALOLOS"
poffprov: " "
poffstreet: " "
poffzip: " "
poffzone: " "
prevbnsown: "3562"
prevgross: 0
rentleasemonthly: 8000
rentleasesince: "03-JUL-09"
savetm: "03-JUL-09"
sssdateissued: ""
sssnum: " "
taxyear: "2019"
tinissuedon: ""
tinnum: ""
totflrarea: 0
*/  
                        let m = moment();

                        const {taxyear, tinnum, ctcnum, sssnum, 
                            dtiseccdaregnum, 
                            dtoperated,
                            dtiseccdadateissued, 
                            ctcdateissued, sssdateissued, tinissuedon,
                            bin, permitno:bnsplate, businessname, orgnkind, businesstype, 
                            businessinfo_barangays,
                            businessinfo_barangay,
                            businessinfo_citymunicipality,
                            businessinfo_housenumber,
                            businessinfo_province,
                            businessinfo_street,
                            businessinfo_zipcode,
                            capital,
                            gr1,
                            gr2,
                            contactnumber, 
                            emailaddress,
                            owncode,
                            owner_citymunicipality,
                            owner_citymunicipalities,
                            owner_barangays,
                            owner_barangay,
                            owner_bdate,
                            owner_contactnumber,
                            owner_emailaddress,
                            owner_firstname,
                            owner_gender,
                            owner_housenumber,
                            owner_lastname,
                            owner_middlename,
                            owner_street,
                            owner_province,
                            owner_zipcode,
                            busnown,
                            otherinfo_barangays,
                            otherinfo_barangay,
                            otherinfo_bdate,
                            otherinfo_citymunicipality,
                            otherinfo_citymunicipalities,
                            otherinfo_contactnumber,
                            otherinfo_emailaddress,
                            otherinfo_firstname,
                            otherinfo_gender,
                            otherinfo_housenumber,
                            otherinfo_lastname,
                            otherinfo_middlename,
                            otherinfo_nationality,
                            otherinfo_street,
                            otherinfo_province,
                            otherinfo_zipcode,
                            rentleasemonthly,
                            rentleasesince,
                            provinces
                            
                        } = result;


                        self.setState( ps => {
                            
                            const basicinfo = {...ps.business.basicinfo, stat : 'REN', taxyear, tinnum, ctcnum, sssnum, dtiseccdaregnum,
                                dtiseccdadateissued: dtiseccdadateissued === '' ? '' :moment(dtiseccdadateissued).format('YYYY-MM-DD'),
                                datestarted: dtoperated === '' ? '' : moment(dtoperated).format('YYYY-MM-DD'),
                                ctcdateissued: ctcdateissued === '' ?  '' : moment(ctcdateissued).format('YYYY-MM-DD'),
                                sssdateissued: sssdateissued === '' ?  '' : moment(sssdateissued).format('YYYY-MM-DD'),
                                tindateissued: tinissuedon === '' ?  '' : moment(tinissuedon).format('YYYY-MM-DD'),
                            };
                            const businessinfo = {...ps.business.businessinfo, bin, bnsplate, businessname, orgnkind, 
                                 businesstype, 
                                 housenumber:businessinfo_housenumber, street:businessinfo_street, 
                                 contactnumber : '+63' + (contactnumber.startsWith('0')  ? contactnumber.substring(1) : contactnumber), 
                                 barangays : businessinfo_barangays,
                                 barangay: businessinfo_barangay,
                                 zipcode:businessinfo_zipcode,
                                 capital, gr2, gr1: (gr2 > 0 ? gr2 : 0),
                                 emailaddress};
                            const owner = {...ps.business.owner, ownercode:owncode, lastname:owner_lastname, firstname: owner_firstname,
                                middlename:owner_middlename, gender:owner_gender,  housenumber: owner_housenumber, street:owner_street,
                                provinces, province:owner_province, citymunicipalities: owner_citymunicipalities, citymunicipality : owner_citymunicipality,
                                barangays : owner_barangays, barangay: owner_barangay,
                               zipcode: owner_zipcode, bdate : owner_bdate === '' ?  '' : moment(owner_bdate).format('YYYY-MM-DD'),
                                contactnumber : '+63' + (owner_contactnumber.startsWith('0')  ? owner_contactnumber.substring(1) : owner_contactnumber), 
                            }
                            const otherinfo = {...ps.business.otherinfo,
                                placeoccupancy: (busnown === '' || owncode === busnown) ? 'OWNED' : 'RENTED',
                                ownercode:busnown, lastname:otherinfo_lastname, firstname: otherinfo_firstname,
                                middlename:otherinfo_middlename, gender:otherinfo_gender,  housenumber: otherinfo_housenumber, street:otherinfo_street,
                                provinces, province:otherinfo_province, citymunicipalities: otherinfo_citymunicipalities, citymunicipality : otherinfo_citymunicipality,
                                barangays : otherinfo_barangays, barangay: otherinfo_barangay,
                                zipcode: otherinfo_zipcode, bnsplate,
                                rentleasesince: rentleasesince === '' ?  '' : moment(rentleasesince).format('YYYY-MM-DD'), rentleasemonthly,
                                contactnumber : '+63' + (otherinfo_contactnumber.startsWith('0')  ? otherinfo_contactnumber.substring(1) : otherinfo_contactnumber), 
                            }
                            let newState = { ...ps.business, basicinfo, businessinfo, owner, otherinfo};
                            debug('New State %O', newState);
                                return ({
                            value : 1 , business : newState});
                        });
                    

                    }
                }).catch ( err => { 
                    //cannot validate bin
                    _props.hideLoadingRequest();
                    _props.showAlert('Cannot validate BIN.', 'error');
                    debug("Error %O", err);
                    history.push('/application-form');
                    return;
                })
            }

		}).catch(function(error){
            //cannot retrieve bin
            _props.hideLoadingRequest();
            _props.showAlert('Cannot retrieve BIN.', 'error');
            debug("Error %O", error);
            history.push('/application-form');
            return;
		});


    }

    handleChange = (value) => {
        debug('handleChange %O', value);

        let newOwnerCode = "";
        let newGender = ""; 
        let newContact = ""; 
        let newFirstName = "";
        let newMiddleName = "";
        let newNationality = "";
        if(value.FIRSTNAME !== undefined && value.FIRSTNAME !== null)
            newFirstName = value.FIRSTNAME;
        if(value.MIDDLE !== undefined && value.MIDDLE !== null)
            newMiddleName = value.MIDDLE;
        if(value.NATIONALITY !== undefined && value.NATIONALITY !== null)
            newNationality = value.NATIONALITY;
        
        // temporary code due to data (null data) from database 
        if(value.CONTACTNUMBER === undefined || value.CONTACTNUMBER === null || value.CONTACTNUMBER.trim() === ''){
            newContact = "63";
        }else{
            let contact = value.CONTACTNUMBER; 
            if (contact.length === 11 && contact.charAt(0) === 0){
                newContact = '63' + contact.substring(1, 11);
            }
        }

        if(value.GENDER !== undefined && value.GENDER !== null){
            let gender = value.GENDER; 
            newGender = gender.toLowerCase(); 
        }

        if (value.ID !== undefined && value.ID !== null)
            newOwnerCode = value.ID;

        if (this.state.business.owner.country !== 'PH')
        {
            this.setState(ps=> ( {
                business: { ...ps.business, owner : { ...ps.business.owner, country : 'PH', isfulladdress: true,  streetlabel : 'Street', dialingcode: '+63'}}
            }))
        }

        let provincecode = '';
        this.state.business.owner.provinces.forEach(function(element){
            if (value.PROVINCE === Object.values(element)[0]) 
            {
                provincecode = Object.keys(element)[0];
                return;
            }
        });
        let citymunicipalities = [ {'':''}];
        let citymunicipalitycode = '';


        if (provincecode !== '')
        {
            const self = this;
            /*load citymunicipalities*/
            axios.get(config.server_url + '/citymunicipalities/' + provincecode.substr(0, 4)).then(function(response){
                response.data.forEach(function(element)
                {
                    citymunicipalities.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                    if (value.CITYMUNICIPALITIES === Object.values(element)[0])
                    {
                        citymunicipalitycode = Object.keys(element)[0];
                        /*load barangays*/
                        axios.get(config.server_url + '/psgcbarangays/' + citymunicipalitycode).then(function(response1){

                            var _barangays = [ {'':''}];
                            let barangaycode = '';
                            for (var i in response1.data)
                            {
                                _barangays.push({ [Object.keys(response1.data[i])[0]] : Object.values(response1.data[i])[0]});
                                if (value.BARANGAY === Object.values(response1.data[i])[0])
                                    barangaycode = Object.keys(response1.data[i])[0];
                            }
                            self.setState(ps => ({
                                business: {
                                    ...ps.business, owner:{
                                        ...ps.business.owner, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangays : _barangays,
                                            barangay : barangaycode
                                    }
                                }
                            }))

                            return;
                        });
                
                        //empty barangay
                        self.setState(ps => ({
                                business: {
                                    ...ps.business, owner:{
                                        ...ps.business.owner, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangay : ''
                                    }
                                }
                            }))
                    
                        return;
                    }


                })
            });
        }


        this.setState(ps => ({
            business: {
                ...ps.business, owner:{
                    ...ps.business.owner, 
                        ownercode: newOwnerCode,
                        firstname: newFirstName,
                        middlename: newMiddleName,
                        gender : newGender, 
                        bdate: value.BDATE,
                        nationality: newNationality,
                        dialingcode : newContact,
                        emailaddress: value.EMAILADDRESS,
                        housenumber : value.HOUSERNUMBER,
                        street : value.STREET,
                        province : provincecode, 
                        citymunicipality : '',
                        citymunicipalities,
                        barangay : ''
                }
            }
        }))
    }

    handleOtherInfoChange = (value) => {
        debug('handleOtherInfoChange %O', value);

        let newOwnerCode = "";
        let newGender = ""; 
        let newContact = ""; 
        let newFirstName = "";
        let newMiddleName = "";
        let newNationality = "";
        if(value.FIRSTNAME !== undefined && value.FIRSTNAME !== null)
            newFirstName = value.FIRSTNAME;
        if(value.MIDDLE !== undefined && value.MIDDLE !== null)
            newMiddleName = value.MIDDLE;
        if(value.NATIONALITY !== undefined && value.NATIONALITY !== null)
            newNationality = value.NATIONALITY;
        
        // temporary code due to data (null data) from database 
        if(value.CONTACTNUMBER === undefined || value.CONTACTNUMBER === null || value.CONTACTNUMBER.trim() === ''){
            newContact = "63";
        }else{
            let contact = value.CONTACTNUMBER; 
            if (contact.length === 11 && contact.charAt(0) === 0){
                newContact = '63' + contact.substring(1, 11);
            }
        }

        if(value.GENDER !== undefined && value.GENDER !== null){
            let gender = value.GENDER; 
            newGender = gender.toLowerCase(); 
        }

        if (value.ID !== undefined && value.ID !== null)
            newOwnerCode = value.ID;

        if (this.state.business.otherinfo.country !== 'PH')
        {
            this.setState(ps=> ( {
                business: { ...ps.business, otherinfo : { ...ps.business.otherinfo, country : 'PH', isfulladdress: true,  streetlabel : 'Street', dialingcode: '+63'}}
            }))
        }

        let provincecode = '';
        this.state.business.otherinfo.provinces.forEach(function(element){
            if (value.PROVINCE === Object.values(element)[0]) 
            {
                provincecode = Object.keys(element)[0];
                return;
            }
        });
        let citymunicipalities = [ {'':''}];
        let citymunicipalitycode = '';


        if (provincecode !== '')
        {
            const self = this;
            /*load citymunicipalities*/
            axios.get(config.server_url + '/citymunicipalities/' + provincecode.substr(0, 4)).then(function(response){
                response.data.forEach(function(element)
                {
                    citymunicipalities.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                    if (value.CITYMUNICIPALITIES === Object.values(element)[0])
                    {
                        citymunicipalitycode = Object.keys(element)[0];
                        /*load barangays*/
                        axios.get(config.server_url + '/psgcbarangays/' + citymunicipalitycode).then(function(response1){

                            var _barangays = [ {'':''}];
                            let barangaycode = '';
                            for (var i in response1.data)
                            {
                                _barangays.push({ [Object.keys(response1.data[i])[0]] : Object.values(response1.data[i])[0]});
                                if (value.BARANGAY === Object.values(response1.data[i])[0])
                                    barangaycode = Object.keys(response1.data[i])[0];
                            }
                            self.setState(ps => ({
                                business: {
                                    ...ps.business, otherinfo:{
                                        ...ps.business.otherinfo, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangays : _barangays,
                                            barangay : barangaycode
                                    }
                                }
                            }))

                            return;
                        });
                
                        //empty barangay
                        self.setState(ps => ({
                                business: {
                                    ...ps.business, otherinfo:{
                                        ...ps.business.otherinfo, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangay : ''
                                    }
                                }
                            }))
                    
                        return;
                    }


                })
            });
        }


        this.setState(ps => ({
            business: {
                ...ps.business, otherinfo:{
                    ...ps.business.otherinfo, 
                        ownercode: newOwnerCode,
                        firstname: newFirstName,
                        middlename: newMiddleName,
                        gender : newGender, 
                        bdate: value.BDATE,
                        nationality: newNationality,
                        dialingcode : newContact,
                        emailaddress: value.EMAILADDRESS,
                        housenumber : value.HOUSERNUMBER,
                        street : value.STREET,
                        province : provincecode, 
                        citymunicipality : '',
                        citymunicipalities,
                        barangay : ''
                }
            }
        }))
    }

    onSaveAddtlbusiness = (data) =>
    {
        this.setState(prevState => ({
            business : {
                ...prevState.business,
                businessinfo : {
                    ...prevState.business.businessinfo,
                    addtlbusiness : data
                }
            }
        }))
    }

    onChanged = key => value =>
    {
        let isCountryChanged = false;

        const _props = this.props;
        //_props.getOwnInfo(value.lastname)

        this.setState( prevState => {
                var selectedCountry

                if(key === "owner") {selectedCountry = prevState.business.owner.country}
                else if(key === "otherinfo") {selectedCountry = prevState.business.otherinfo.country}
                else {selectedCountry = prevState.business.businessinfo.country}

                isCountryChanged = ((selectedCountry  !== value.country))?true:false;
                return {business:  {...prevState.business, [key] : value}}
            },() =>{
                if(isCountryChanged){
                    const self = this;
                    //get dialing code
                    axios.get(config.server_url + '/dialingcode', {params :  {countrycode : value.country} } )
                    .then(function (response) {
                        const dialingcode = response.data.dialing_code;
                        self.setState(ps =>  {
                            if(key === "owner")return {business: { ...ps.business, owner: { ...ps.business.owner , dialingcode , contactnumber: ''}}}
                            else if(key === "otherinfo") return {business: { ...ps.business, otherinfo: { ...ps.business.otherinfo , dialingcode , contactnumber: ''}}}
                            else return {business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , dialingcode , contactnumber: ''}}}
                        })
                    })
                    .catch(function(error){
                        self.setState(ps =>  {
                            if(key === "owner")return {business: { ...ps.business, owner: { ...ps.business.owner , dialingcode : '' , contactnumber: ''}}}
                            else if(key === "otherinfo") return {business: { ...ps.business, otherinfo: { ...ps.business.otherinfo , dialingcode : '' , contactnumber: ''}}}
                            else return {business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , dialingcode : '' , contactnumber: ''}}}
                        })
                    })
                }
            }
        )

    }

    onSubmit = (e) =>
    {
        e.preventDefault();
        const _props = this.props;
        //this.props.userSignupRequest(this.state);
        let token = localStorage.getItem('token');
        const self = this;

        axios.post(config.server_url + '/savebusiness', { business : this.state.business}, { headers: {"Authorization" : 'bearer ' + token}}).then(function (response) {
            _props.showAlert('Successfully created account.', 'success');
    //         //clear data
            self.setState( {value : 0 , business : initState} );
            //history.push('/new-business-form');
            history.push('/application-form');

        }).catch(function(error){
            debug('onSubmit %O', error)
            _props.showAlert(error.response.data.errors[0], 'error');
        });
    }

    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({
        activeStep: activeStep + 1,
        });
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
        activeStep: activeStep - 1,
        });
    };

    handleStep = (prevStep,step) => () => {
        if(prevStep !== step){
            this.setState({
                activeStep: step,
            });
        }
    };


    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} >
                    <Card className={classes.card }>
                        <CardHeader
                        classes={{
                                root:classes.cardHeader +" " +classes["blueCardHeader"] ,
                                title: classes.cardTitle,
                                subheader: classes.cardSubtitle
                        }}
                        title= "Simple Table"
                        subheader="Here is a subtitle for this table"
                        />
                        <Alert handleCloseButton = {this.props.closeAlert }/>
                        <CardContent>
                            {/* <form method="post" onSubmit={this.onSubmit}>
                            <div className={classes.root}>
                                <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    scrollable
                                    scrollButtons="auto"
                                >
                                    <Tab label="Basic Information" />
                                    <Tab label="Business Information" />
                                    <Tab label="Owners Information/ Profile" />
                                    <Tab label="Other additional Information" />
                                </Tabs>
                                </AppBar>
                                {this.state.value === 0 && <TabContainer>
                                        <BasicInformation
                                            basicinfo={this.state.business.basicinfo}
                                            onBasicinfoChanged={this.onChanged('basicinfo')}
                                            runonce={this.state.business.runonce1}
                                        />
                                    </TabContainer>
                                }
                                {this.state.value === 1 &&
                                    <TabContainer>
                                        <BusinessInformation
                                            businessinfo={this.state.business.businessinfo}
                                            onBusinessInfoChanged={this.onChanged('businessinfo')}
                                            runonce={this.state.business.runonce2}
                                        />
                                    </TabContainer>
                                }
                                {this.state.value === 2 &&
                                    <TabContainer>
                                        <OwnersInformation
                                            owner={this.state.business.owner}
                                            onOnwerChanged={this.onChanged('owner')}
                                            runonce={this.state.business.runonce3}
                                        />
                                    </TabContainer>
                                }
                                {this.state.value === 3 &&
                                    <TabContainer>
                                        <OtherAddtlInformation
                                            owner={this.state.business.owner}
                                            otherinfo={this.state.business.otherinfo}
                                            otherinfoOrigin={initState.otherinfo}
                                            onOtherInfoChanged={this.onChanged('otherinfo')}
                                            runonce={this.state.business.runonce4}
                                        />
                                    </TabContainer>
                                }
                            </div>
                                <button type="submit">Save</button>
                            </form> */}
                            <div className={classes.stepperHeader}>
                                <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel nonLinear>
                                    {steps.map((label, index) => {
                                        return (
                                        // <Step key={label}>
                                        //     <StepLabel>{label}</StepLabel>
                                        // </Step>
                                        <Step key={label}>
                                            <StepButton
                                                onClick={this.handleStep(activeStep,index)}
                                            >
                                                {label}
                                            </StepButton>
                                        </Step>
                                        );
                                    })}
                                </Stepper>
                                <div>
                                    {this.state.activeStep === steps.length-1 ? (
                                        <Fragment>
                                            <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                                            <div className={classes.stepperButtons}>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={this.handleBack}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                                <Button variant="contained" color="primary" onClick={this.onSubmit}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                                            <div className={classes.stepperButtons}>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={this.handleBack}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                                <Button variant="contained" color="primary" onClick={this.handleNext}>
                                                    {/* {activeStep === steps.length - 1 ? 'Submit' : 'Next'} */}
                                                    Next
                                                </Button>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


BusinessContainer.defaultProps = {
    headerColor: "purple"
};

BusinessContainer.propTypes = {
    plainCard: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
    cardTitle: PropTypes.node,
    cardSubtitle: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};
const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        userSignupRequest: (userData) => {
            dispatch(userSignupRequest(userData))
        },
        getOwnInfo: (lastname) => {
            dispatch(getOwnInfo(lastname))
        }, 
        showLoadingRequest : ()  => dispatch(showLoadingRequest()),
        hideLoadingRequest : ()  => dispatch(hideLoadingRequest())
    }
};


const mapStateToProps = (state) => {
    return ({
        ownerInfoList: state.application.ownersInfoList,
        user : state.session.user,
        binPrefix : state.session.binPrefix
    })
}

BusinessContainer.propTypes = {
    classes: PropTypes.object,
};


export default withLoading(connect( mapStateToProps, mapDispatchToProps)( withStyles(regularCardStyle)(BusinessContainer)));