import React from 'react';

//import TextField from '@material-ui/core/TextField';
import Person from '../Person';
import GlobalAddress from '../GlobalAddress';
import MobileNumberField from '../MobileNumberField';

//import { withStyles } from '@material-ui/core/styles';

class PersonalInformation extends React.Component{

    render(){
        return <div>
            <Person
                {...this.props.person}
                runonce={this.props.runonce}
                onPersonChanged={this.props.onPersonChanged}
            />
            <MobileNumberField
                name='contactnumber'
                label='Contact Number'
                dialingCode={this.props.dialingcode}
                value={this.props.contactnumber}
                onChange={this.props.onChange}
                error={ (this.props.contactnumber.trim() === '' ||
                    (this.props.dialingcode !== '-' &&  (this.props.contactnumber.trim() === ('+' + this.props.dialingcode)) )
                    ) && !this.props.runonce}
            />
            <GlobalAddress
                {...this.props.personaddress}
                runonce={this.props.runonce}
                onAddressChanged={this.props.onAddressChanged}
            />
        </div>
    }
}

export default PersonalInformation;