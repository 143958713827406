import { CardContent, ListItemText, withStyles } from "@material-ui/core";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideLoadingRequest, showLoadingRequest } from '../actions/session';
import regularCardStyle from "../assets/css/regularCardStyle";
import withLoading from '../components/withLoading';

import MUIDataTable from "mui-datatables";

const config = require('../config');

class PaymentHistoryContainer extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            businesses : [],
            bin: '',
            details : [],
            index : -1,
            data : '',
			'columns': [
				{label: 'Year',name: 'year'},
				{label: 'OR No',name: 'orno'},
				{label: 'Date',name: 'date'},
				{label: 'Term',name: 'term'},
				{label: 'No. of Qtr',name: 'noofqtr'},
				{label: 'Tax',name: 'tax'},
				{label: 'Fees',name: 'fees'},
				{label: 'Surch/Int',name: 'surchint'},
				{label: 'Total',name: 'total'},
            ],
            'columnDefs' :[
                { responsivePriority: 1, targets: 0 },
                { responsivePriority: 2, targets: 1 },
                { responsivePriority: 3, targets: -1 },
            ]
           
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount()
    {
        const _props = this.props;
        _props.showLoadingRequest();
        let token = localStorage.getItem('token');
        const self = this;
        axios.get(config.server_url + '/business',
        { headers: {"Authorization" : 'bearer ' + token}
        }).then(function(response){
            self.setState({'businesses': response.data}, () => { _props.hideLoadingRequest() });
        }).catch(function(error){
            //console.error('An error has occurred');
            self.setState( {'businesses': {}}, () => { _props.hideLoadingRequest() });
        });
    }

    onChange(e)
    {
        this.setState({[e.target.name] : e.target.value});
        if (e.target.name === 'bin')
        {
            const _props = this.props;
            const self = this;
            let token = localStorage.getItem('token');
            this.props.showLoadingRequest();
            if (e.target.value === '')
            {
                self.setState({details : [], index : -1, data : ''}, ()=> _props.hideLoadingRequest());
            }
            else
            {
                axios.post(
                    config.server_url + '/bindetails', { bin : e.target.value, startyear:1994 }, { headers: {"Authorization" : 'bearer ' + token}}).then(function (response) {
                    self.setState({details : response.data, index : -1,  data: '' }, () => _props.hideLoadingRequest());
                }).catch(function(error){
                    self.setState({details : [], index : -1,  data: ''}, ()=> _props.hideLoadingRequest());
                });
            }

        }
    }

    handleDetails = (event, index) => {
        this.setState({ index }, () => {
            //call payment info here
            const bin = this.state.bin;
            const details = this.state.details[index];
            const taxyear = details[0];
            const official_receipt = details[1];
            const qtr = details[4];
            const printedBy = 'SYS_PROG';

            const _props = this.props;
            const self = this;
            let token = localStorage.getItem('token');
            this.props.showLoadingRequest();
            axios.post(
                config.server_url + '/payhist', { bin, taxyear, official_receipt, qtr, printedBy }, { headers: {"Authorization" : 'bearer ' + token}, responseType: 'arraybuffer'}).then(function (response) {
                let data = new Buffer(response.data, 'binary').toString('base64');
                self.setState({data : 'data:application/pdf;base64,'+ data}, () => _props.hideLoadingRequest());
            }).catch(function(error){
                self.setState({data : ''}, ()=> _props.hideLoadingRequest());
            });
        });
    };

    handleDownload = () => {
        const {bin, details, index} = this.state;
		if (bin === '')
            return;
        if (index === -1 && index >= details.length) //wrong index
            return;
        const {taxyear, official_receipt, extra, qtr} = details[index];
        const printedBy = 'SYS_PROG';
                
        const _props = this.props;
        _props.showLoadingRequest();

		let token = localStorage.getItem('token');
        // const self = this;// assigned but never used

		axios.post(config.server_url + '/payhist/', { bin, taxyear, official_receipt, qtr, printedBy },
		{ headers: {"Authorization" : 'bearer ' + token}, responseType : 'arraybuffer'
		}).then(function(response){
			let data = new Buffer(response.data, 'binary').toString('base64');
			const url = 'data:application/pdf;base64,' + data;
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', bin +'.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
			_props.hideLoadingRequest();
		}).catch(function(error){
			_props.hideLoadingRequest();
		});
    }

    onSubmit(e)
    {
        e.preventDefault();
        this.props.addUser(this.state);
    }
    render() {
    const componentMargin = {
        marginRight: 75,
        width : '100%',
    }
    const { classes } = this.props;
    const { data:_data, index:_index, columns, columnDefs } = this.state;

    // const bin_selected = this.state.bin; //assigned a value but never used
    let bins = this.state.businesses.map(function(_bin,key){
        if (_bin.bin === '' || _bin.bin === undefined || _bin.bin === null || _bin.ispending === true)
            return '';
        // const _selected = (bin_selected === _bin.bin ) ? 'defaultValue' : ''; //assigned a value but never used
        return <MenuItem key={key} value={_bin.bin}>{_bin.bin}</MenuItem>;
    });
    
    let _details = this.state.details.map(function(_detail, key){
        return { 
            'year': _detail[0],
            'orno': _detail[1],
            'date': _detail[2],
            'term': _detail[4],
            'noofqtr': _detail[5],
            'tax': _detail[6],
            'fees': _detail[7],
            'surchint': _detail[8],
            'total': _detail[9]
        }
    });

    const self = this;
    const _props = this.props;

    const options = {
        selectableRows : 'single',
        viewColumns : false,
        search : false,
        filter: false,
        print : false,
        download : false,
        customToolbar: null,
        fixedHeader: false,
        responsive: 'stacked',
        rowsSelected : [ this.state.index ],
        customToolbarSelect : () => {},
        onRowsSelect: (rowsSelected, allRows) => {
            if (allRows.length > 0)
            {
                const index = allRows[0].index;
                self.setState({ index }, () => {
                    //call payment info here
                    const bin = this.state.bin;
                    const details = this.state.details[index];
                    const taxyear = details[0];
                    const official_receipt = details[1];
                    const qtr = details[4];
                    const printedBy = 'SYS_PROG';
        
                    const _props = this.props;
                    const self = this;
                    let token = localStorage.getItem('token');
                    this.props.showLoadingRequest();
                    axios.post(
                        config.server_url + '/payhist', { bin, taxyear, official_receipt, qtr, printedBy }, { headers: {"Authorization" : 'bearer ' + token}, responseType: 'arraybuffer'}).then(function (response) {
                        let data = new Buffer(response.data, 'binary').toString('base64');
                        self.setState({index, data : 'data:application/pdf;base64,'+ data}, () => _props.hideLoadingRequest());
                    }).catch(function(error){
                        self.setState({index, data : ''}, ()=> _props.hideLoadingRequest());
                    });
                });
        
            }
            else
            {
                self.setState({index:-1, data : ''}, ()=> _props.hideLoadingRequest());
            }
        }
    }


    let details = this.state.details.map(function(_detail, key){
        let _items = _detail.map(function(_item, key2){
            return <ListItemText key={key2} primary={_item}/>;
        });
        // console.log(key);
        return <ListItem button key={key} selected={_index === key} onClick={event=>this.handleDetails(event, key)}>{_items}</ListItem>;
    }.bind(this));

        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} >
                    <Card className={classes.card }>
                        <CardHeader color="primary" >
                            <h4 className={classes.cardTitleWhite}>Payment History</h4>
                            <p className={classes.cardCategoryWhite}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                            </p>
                        </CardHeader>
                        <CardBody>
                            <CardContent>
                                <Grid container xs={12} sm={12} md={12}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FormControl style = {componentMargin}>
                                            <InputLabel >BIN</InputLabel>
                                                <Select
                                                    onChange={this.onChange}
                                                    value={this.state.bin}
                                                    inputProps={{
                                                        id: 'bin',
                                                        name: 'bin'
                                                    }}
                                                >
                                                <MenuItem value=""></MenuItem>
                                                {bins}
                                                </Select>
                                        </FormControl>
                                    </Grid>
                                    {(_details.length > 0)&&
                                        <Grid item xs={12} sm={12} md={12}>
                                        <MUIDataTable
                                                                data={_details}
                                                                columns={columns}
                                                                options={options}
                                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12} md={12} style={{"margin-top": "10px"}} >
                                        <object data={_data} type="application/pdf" style={{ height: '500px',width:'100%' }}>
                                            { (this.state.bin !== '' && this.state.index !== -1) ? <Link onClick={this.handleDownload}>Download</Link> : null }
                                        </object>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


PaymentHistoryContainer.defaultProps = {
    headerColor: "purple"
};
PaymentHistoryContainer.propTypes = {
    plainCard: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
    cardTitle: PropTypes.node,
    cardSubtitle: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};

const mapDispatchToProps = dispatch => ({
    showLoadingRequest : ()  => dispatch(showLoadingRequest()),
    hideLoadingRequest : ()  => dispatch(hideLoadingRequest())
});

export default withLoading(connect( null, mapDispatchToProps)( withStyles(regularCardStyle)(PaymentHistoryContainer)));