export const userConstants = {
    USER_ADD : 'USER_ADD',
    USER_SIGNUP: 'USER_SIGNUP',
    USER_SIGNUP_REQUEST_SUCCESS : 'USER_SIGNUP_REQUEST_SUCCESS',
    USER_SIGNUP_REQUEST_FAILED : 'USER_SIGNUP_REQUEST_FAILED',
    USERS_GET : 'USERS_GET',
    USER_GET : 'USER_GET',
    USER_DELETE: 'USER_DELETE',
    USER_UPDATE: 'USER_UPDATE',
    OWNINFO_GET : 'OWNINFO_GET',
};