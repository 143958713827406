import React ,{ Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Person from '../Person';
import GlobalAddress from '../GlobalAddress';
import DatePicker from '../DatePicker';
import MobileNumberField from '../MobileNumberField';
import EmailField from '../EmailField';
import RadioButtonsGroup from '../RadioButtonsGroup';
import TextFields from '../TextFields';
import NumberField from '../NumberField';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceICon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import TextField from '@material-ui/core/TextField';

const selectList = [
    {value:"male" ,label : "Male"},
    {value:"female" ,label : "Female"},
]


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },

  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },

  card: {
    minWidth: 275,
  },

  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

});

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


class OwnersInformation extends Component {
    constructor(props){
        super(props)
        this.state = {
            autoComplete: false,
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        this.props.onOnwerChanged( {...this.props.owner, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    };


    render(){
            const props = this.props
            const { classes } = props;
        return(
            <Grid container spacing={12}>
                    <Grid container justify="center" spacing={12}>
                        <Button variant="contained" color="primary" className={classes.button} onClick = {() => this.props.handleClickOpen("owner")}>
                        Existing Business
                        <SearchIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>
                <Person 
                    {...props.owner} 
                    runonce={props.runonce} 
                    onPersonChanged={props.onOnwerChanged} 
                    autoComplete = {!this.props.owner.disabled} 
                    // getOwnInfoList = {this.props.getOwnInfoList} 
                    // getSelectedInfo = {this.props.getSelectedInfo}
                 />
                <DatePicker
                    {...props.owner}
                    runonce={props.runonce}
                    label="birthdate"
                    name="bdate"
                    value={props.owner.bdate}
                    onDateChanged={props.onOnwerChanged}
                    openTo = 'year'
                    lgSize={5}
                    mdSize={6}
                    xsSize={12}
                />
                <RadioButtonsGroup
                    label="Gender"
                    name="gender"
                    value={props.owner.gender}
                    onChange = {this.handleChange}
                    selectList = {selectList}
                    lgSize={3}
                    mdSize={6}
                    xsSize={12}
                    disabled={this.props.owner.disabled}
                />
                <TextFields
                    label="Nationality"
                    name="nationality"
                    value={props.owner.nationality}
                    onChange = {this.handleChange}
                    inputProps={{
                        'style' : {'textTransform': 'uppercase'}
                    }}
                    disabled={props.owner.disabled}
                    lgSize={4}
                    mdSize={6}
                    xsSize={12}
                />
                <MobileNumberField
                    name='contactnumber'
                    label='Contact Number'
                    dialingCode={props.owner.dialingcode}
                    value={props.owner.contactnumber}
                    onChange = {this.handleChange}
                    // error={ (props.owner.contactnumber.trim() === '' ||
                    //     (props.owner.dialing_code !== '-' &&  (props.owner.contactnumber.trim() === ('+' + props.owner.dialing_code)) )
                    //     ) && !props.runonce}
                    error={ false }
                    disabled={props.owner.disabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <EmailField
                    value = {props.owner.emailaddress}
                    name="emailaddress"
                    onChange = {this.handleChange}
                    validateEmail
                    // runonce={props.runonce}
                    // runonce={props.owner.emailaddress === ''} // email address not neccessary has a validation in this part
                    runonce= {props.runonce}
                    disabled={props.owner.disabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <GlobalAddress {...props.owner} runonce={props.runonce} onAddressChanged={props.onOnwerChanged} 
                    ishousenumberdisabled={props.owner.disabled}
                    isstreetdisabled={props.owner.disabled}
                    isaddressdisabled={props.owner.disabled}/>
                <NumberField
                    label="Number of Employee F/M"
                    name="numemployees"
                    // type="number"
                    value={props.owner.numemployees}
                    onChange = {this.handleChange}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                    Currencyformat = {true}
                />
                <NumberField
                    label="Business Area"
                    name="floorarea"
                    // type="number"
                    unit="SQM"
                    value={props.owner.floorarea}
                    onChange = {this.handleChange}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                    Currencyformat = {true}
                />
               
            </Grid>
        )
    }
}

export default withStyles(styles)(OwnersInformation);
