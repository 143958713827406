import {CardContent, withStyles } from "@material-ui/core";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { closeAlert, showAlert } from '../actions/alert';
import { userSignupRequest } from '../actions/users';
import {getOwnInfo} from '../actions/application'
import regularCardStyle from "../assets/css/regularCardStyle";
import Alert from '../components/alerts/alert';
import BasicInformation from "../components/newApplication/BasicInformation";
import BusinessInformation from "../components/newApplication/BusinessInformation";
import OtherAddtlInformation from "../components/newApplication/OtherAddtlInformation";
import OwnersInformation from "../components/newApplication/OwnersInformation";
import history from '../history';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';


const moment = require('moment');
const debug = require('debug')('client_nbc');
debug.enabled = process.env.REACT_APP_DEBUG;
const config = require('../config');

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};


const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
});

const initState = {
    modal: false,
    modalLocation : "",
    snackbarOpen: false, 
    basicinfo : {
        stat : 'NEW',
        taxyear : '',
        dateapplication : '',
        datestarted : '',
        dtiseccdaregnum : '',
        dtiseccdadateissued : '',
        tinnum : '',
        tindateissued : '',
        sssnum : '',
        sssdateissued : '',
        ctcnum : '',
        ctcdateissued : '',
        isstatdisabled : true ,
        istaxyeardisabled : true ,
        isdateapplicationdisabled : false ,
        isdatestarteddisabled : false ,
        isdtiseccdaregnumdisabled: false,
        isdtiseccdadateissueddisabled: false,
        istinnumdisabled: false,
        istindateissueddisabled: false,
        issssnumdisabled: false,
        issssdateissueddisabled: false,
        isctcnumdisabled: false,
        isctcdateissueddisabled: false,
    },
    runonce1 : true,
    businessinfo :{
        businessname: '',
        orgnkind: '',
        businesstype: '',
        addtlbusiness: [{businesstype: "", capital: ""}],
        capital: '',
        contactnumber : '',
        emailaddress: '',
        emailaddresserror: '',
        housenumber : '',
        street : '',
        province : '031400000',
        citymunicipality : '031410000',
        barangay : '',
        zipcode : '3000',
        country : 'PH',
        streetlabel : 'Street',
        dialingcode : '63',
        countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
        provinces : [ {'031400000': 'BULACAN'}],
        citymunicipalities : [{'031410000':'CITY OF MALOLOS'}],
        barangays : [{'':''}],
        isfulladdress: true,
        isaddressdisabled : true,
        hasbusinessnameerror : true, // 
        hasorgnkinderror : true,//
        hasbusinesstypeerror : true, //
        hascapitalerror : true,
        hascontactnumbererror : true,
        hashousenumber : true,
        hasstreeterror : true, 
        hasprovinceerror : false,
        hascitymunicipalityerror : false,
        hasbarangayerror : true,
        haszipcodeerror : false,
        hascountryerror : false,
        hasemailaddresserror : false,
        loading : false,
        runonce : true
    },
    runonce2 : true, // business
    owner : {
        ownercode: '',
        lastname : '',
        firstname : '',
        middlename : '',
        gender : '',
        bdate: '',
        nationality: '',
        contactnumber : '',
        emailaddress: '',
        housenumber : '',
        street : '',
        province : '',
        citymunicipality : '',
        barangay : '',
        zipcode : '',
        country : 'PH',
        streetlabel : 'Street',
        dialingcode : '63',
        countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
        provinces : [ {'':''}],
        citymunicipalities : [{'':''}],
        barangays : [{'':''}],
        numemployees : 0,
        floorarea : 0,
        isfulladdress: true,
        isaddressdisabled : false,
        haslastnameerror : true,
        hasfirstnameerror : false,
        hasmiddlenameerror : false,
        hasemailaddresserror : false,
        hascontactnumbererror : false,
        hasbdateerror : true,
        hasstreeterror : false,
        hasprovinceerror : false,
        hascitymunicipalityerror : false,
        hasbarangayerror : false,
        haszipcodeerror : false,
        hascountryerror : false,
        loading : false,
        runonce : true
    },
    runonce3 : true,
    otherinfo :{
        // placeoccupancy : '',
        placeoccupancy : 'OWNED',
        rentleasesince : '',
        rentleasemonthly : '',
        ownercode: '',
        lastname : '',
        firstname : '',
        middlename : '',
        housenumber : '',
        contactnumber : '',
        street : '',
        province : '',
        citymunicipality : '',
        barangay : '',
        zipcode : '',
        country : 'PH',
        streetlabel : 'Street',
        dialingcode : '63',
        countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
        provinces : [ {'':''}],
        citymunicipalities : [{'':''}],
        barangays : [{'':''}],
        isrentleasesincedisabled : true,
        isrentleasemonthlydisabled : true,
        isnamedisabled : true,
        ishousenumberdisabled : true,
        isstreetdisabled : true,
        isbarangaydisabled : true,
        iscontactnumberdisabled : true,
        isaddressdisabled : true,
        bnsplate : '',
        runonce : true,
        haslastnameerror: true, 
        hasrentleasesinceerror : true,
        hasrentleasemonthlyerror : true,
        hascontactnumbererror : true,
    },
    runonce4 : true,
    existingBusiness: {
        owncode : "",
        owncodeError: false,
        lastname : "",
        lastnameError: false,
        firstname : "",
        firstnameError: false,
        middlename : "", 
        runonce: false
    }
};



class NewBusinessContainer extends Component {

    constructor(props)
    {
        super(props);
        this.props.closeAlert();
        this.state = {
            activeStep: 0,
            value: 0 ,
            business : initState
        }

    }

    emailChange = email => {
        this.setState(ps =>  ({
            business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , 
            emailaddress : email.value,
            emailaddresserror : email.errorText,
            runonce: false}}
        }))

    }

    getSteps = () => {
        return ['Basic Information', 'Business Information', 'Owners Information/ Profile' , 'Other additional Information'];
    }

    changeRunonce = () => {
        this.setState({
            business : {
                ...this.state.business ,
                runonce4: true
            }
        });
    }

    handleSubmitChecker = async (location) => {
        // const ownCode = "10387";
        // const lastname = "MANAHAN"; 
        // const firstname = "IMELDA";

        const ownCode = this.state.business.existingBusiness.owncode[0];
        const lastname = this.state.business.existingBusiness.lastname[0]; 
        const firstname = this.state.business.existingBusiness.firstname[0];

        this.setState(ps => ({
             business:{
                 ...ps.business,
                 existingBusiness : {
                     ...ps.business.existingBusiness,
                     runonce: true, 
                     owncodeError : (ownCode === undefined || ownCode == "" ) ? true : false, 
                     lastnameError : (lastname === undefined || lastname == "" ) ? true : false, 
                     firstnameError : (firstname === undefined || firstname == "") ? true : false, 
                 }
             }
        }))

        if(ownCode === undefined || ownCode == "" || lastname === undefined || lastname == ""  || firstname === undefined || firstname == "")
            return ; 


        
        const query = `{
            checkExistingBusiness(ownCode:"${ownCode}",lastname:"${lastname}",firstname:"${firstname}") {
                ID
                LASTNAME
                FIRSTNAME
                MIDDLE
                GENDER
                BDATE
                EMAILADDRESS
                NATIONALITY
                CONTACTNUMBER
                HOUSERNUMBER
                STREET
                BARANGAY
                CITYMUNICIPALITIES
                PROVINCE
                ZIPCODE
            }
        }`

        const url_api = config.server_url + '/public?query=' + query;

        // const res =
        await axios.get(url_api).then(
            res => {
                if(res.data.data.checkExistingBusiness == null)
                {
                    // console.log('TEST ..... ')
                    this.setState({ snackbarOpen: true }, () => {
                        this.setState(ps =>  ({
                            business: { ...ps.business, 
                            modal: false,
                            existingBusiness: {
                                ...ps.business.existingBusiness,
                                owncode: "",
                                lastname : "",
                                firstname : "",
                                middlename : "", 
                            }
                        }
                        }))
                    });

                    return; 
                }
                const data = res.data.data.checkExistingBusiness[0]                

                // for contract 
                let contactinfo = "";
                const contactValue = data.CONTACTNUMBER; 
                if(contactValue == null || contactValue== "")
                {
                    return ;
                }else {
                    if((contactValue).substr(0,1) == 0)
                         contactinfo = "+63" + contactValue.substr(1, contactValue.length)
                }

                // for province
                let provincecode = '';
                this.state.business.owner.provinces.forEach(function(element){
                    if (data.PROVINCE === Object.values(element)[0]) 
                    {
                        provincecode = Object.keys(element)[0];
                        return;
                    }
                });

                // for barangay and city 
                let citymunicipalities = [ {'':''}];
                let citymunicipalitycode = '';


                if (provincecode !== '')
                {
                    const self = this;
                    /*load citymunicipalities*/
                    axios.get(config.server_url + '/citymunicipalities/' + provincecode.substr(0, 4)).then(function(response){
                        response.data.forEach(function(element)
                        {
                            citymunicipalities.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                            if (data.CITYMUNICIPALITIES === Object.values(element)[0])
                            {
                                citymunicipalitycode = Object.keys(element)[0];
                                /*load barangays*/
                                axios.get(config.server_url + '/psgcbarangays/' + citymunicipalitycode).then(function(response1){

                                    var _barangays = [ {'':''}];
                                    let barangaycode = '';
                                    for (var i in response1.data)
                                    {
                                        _barangays.push({ [Object.keys(response1.data[i])[0]] : Object.values(response1.data[i])[0]});
                                        if (data.BARANGAY === Object.values(response1.data[i])[0])
                                            barangaycode = Object.keys(response1.data[i])[0];
                                    }
                                    self.setState(ps => ({

                                        business:{
                                            ...ps.business,
                                            existingBusiness : {
                                                ...ps.business.existingBusiness,
                                                owncode : "",
                                                lastname : "",
                                                firstname : "",
                                                middlename : "", 
                                            }, 
                                            [location]: {
                                                ...ps.business[location],
                                                ownercode: data.ID,
                                                lastname : data.LASTNAME,
                                                firstname : data.FIRSTNAME,
                                                middlename : data.MIDDLE,
                                                gender : (data.GENDER).toLowerCase(),
                                                bdate: data.BDATE,
                                                nationality: data.NATIONALITY,
                                                contactnumber : contactinfo,
                                                emailaddress: data.EMAILADDRESS,
                                                housenumber : data.HOUSERNUMBER,
                                                street : data.STREET,
                                                province : provincecode,
                                                citymunicipality : citymunicipalitycode,
                                                citymunicipalities,
                                                barangays : _barangays,
                                                barangay : barangaycode,
                                                zipcode : data.ZIPCODE,
                                            }
                                        }
                                    }))

                                    return;
                                });
                        
                                //empty barangay
                                self.setState(ps => ({
                                        business:{
                                            ...ps.business,
                                            existingBusiness : {
                                                ...ps.business.existingBusiness,
                                                owncode : "",
                                                lastname : "",
                                                firstname : "",
                                                middlename : "", 
                                            }, 
                                            [location]: {
                                                ...ps.business[location],
                                                ownercode: data.ID,
                                                lastname : data.LASTNAME,
                                                firstname : data.FIRSTNAME,
                                                middlename : data.MIDDLE,
                                                gender : (data.GENDER).toLowerCase(),
                                                bdate: data.BDATE,
                                                nationality: data.NATIONALITY,
                                                contactnumber : contactinfo,
                                                emailaddress: data.EMAILADDRESS,
                                                housenumber : data.HOUSERNUMBER,
                                                street : data.STREET,
                                                province : provincecode,
                                                citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                                citymunicipalities,
                                                barangay : '',
                                                zipcode : data.ZIPCODE,
                                            }
                                        }
                                    }))
                            
                                return;
                            }


                        })
                    });
                }


                this.setState({ 
                    business : {
                        ...this.state.business ,
                        modal: false
                    } 
                }, () => {
                    this.setState(ps => ({
                        business:{
                            ...ps.business,
                            existingBusiness : {
                                ...ps.business.existingBusiness,
                                owncode : "",
                                lastname : "",
                                firstname : "",
                                middlename : "", 
                            }, 
                            [location]: {
                                ...ps.business[location],
                                ownercode: data.ID,
                                lastname : data.LASTNAME,
                                firstname : data.FIRSTNAME,
                                middlename : data.MIDDLE,
                                gender : (data.GENDER).toLowerCase(),
                                bdate: data.BDATE,
                                nationality: data.NATIONALITY,
                                contactnumber : contactinfo,
                                emailaddress: data.EMAILADDRESS,
                                housenumber : data.HOUSERNUMBER,
                                street : data.STREET,
                                province : provincecode,
                                citymunicipality : data.CITYMUNICIPALITIES,
                                barangay : data.BARANGAY,
                                zipcode : data.ZIPCODE,
                            }
                        }
                    }))
                });

             }
        )

    }

   handleChangeModelItem = (e) => {
        const {name, value} = e.target;

       this.setState(ps => ({
            business:{
                ...ps.business,
                existingBusiness : {
                    ...ps.business.existingBusiness,
                    [name] : [value]
                }
            }
       }), () => {
            const key = this.state.business.existingBusiness[name]; 
            // console.log((key[0] === "" ) ? "true" : "false")
            this.setState(ps => ({
                 business:{
                     ...ps.business,
                     existingBusiness : {
                         ...ps.business.existingBusiness,
                         [name + "Error"] : (key[0] === "" ) ? true : false
                     }
                 }
            }))
       })



    };

    handleClickOpen = (location = "owner") => {
      this.setState({ 
            business : {
                ...this.state.business ,
                modal: true,
                modalLocation: location
            } 
        });
    };

    handleClose = () => {
      this.setState({ 
            business : {
                ...this.state.business ,
                modal: false
            } 
        });
    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
            return  <BasicInformation
                        basicinfo={this.state.business.basicinfo}
                        onBasicinfoChanged={this.onChanged('basicinfo')}
                        runonce={this.state.business.runonce1}
                    />;
            case 1:
            return  <BusinessInformation
                        businessinfo={this.state.business.businessinfo}
                        onBusinessInfoChanged={this.onChanged('businessinfo')}
                        onSaveAddtlbusiness={this.onSaveAddtlbusiness}
                        runonce={this.state.business.runonce2}
                        onEmailChange={this.emailChange}
                    />;
            case 2:
            return  <OwnersInformation
                        owner={this.state.business.owner}
                        onOnwerChanged={this.onChanged('owner')}
                        runonce={this.state.business.runonce3}
                        getOwnInfo = {this.props.getOwnInfo}
                        getOwnInfoList = {this.props.ownerInfoList}
                        getSelectedInfo = {this.handleChange} 
                        handleClickOpen = {this.handleClickOpen}
                    />;
            case 3:
            return  <OtherAddtlInformation
                        owner={this.state.business.owner}
                        otherinfo={this.state.business.otherinfo}
                        otherinfoOrigin={initState.otherinfo}
                        onOtherInfoChanged={this.onChanged('otherinfo')}
                        runonce={this.state.business.runonce4}
                        getOwnInfo = {this.props.getOwnInfo}
                        getOwnInfoList = {this.props.ownerInfoList}
                        getSelectedInfo = {this.handleOtherInfoChange} 
                        changeRunonce = {this.changeRunonce}
                        handleClickOpen = {this.handleClickOpen}
                    />;
            default:
            return 'Uknown stepIndex';
        }
    }

    componentDidMount = () => {


        var self = this.state;
        axios.get(config.server_url + '/isodate', {}).then(function (response) {
            try
            {
                let m = moment();
                m = moment(response.data.ISO_DATE);
                let basicinfo = self.business.basicinfo;
                basicinfo.taxyear = m.format("YYYY");
                basicinfo.dateapplication = m.format("YYYY-MM-DD");
                basicinfo.datestarted = m.format("YYYY-MM-DD");

                this.setState( prevState => ( {
                    business : { ...prevState.business,  basicinfo }
                }));
            }
            catch (e) {
            }

        }).catch(function(error){
            console.error(error);
        });
        var that = this;
        axios.get(config.server_url + '/bnskinds').then(function(response){
            var _bnskinds = [];
            response.data.forEach(function(element)
            {
                _bnskinds.push({ label: element , value: element });
            });

            that.setState(ps =>  ({
                business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , orgnKind : _bnskinds ,  loading : false}}
            }))
        });

        axios.get(config.server_url + '/allbnstypes').then(function(response){
            var _mainbnstypes = [];
            const data = response.data;
            Object.keys(data).forEach(function(key){
                _mainbnstypes.push({ label: data[key] , value: key.trim() });
            })
            that.setState(ps =>  ({
                business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , businessType : _mainbnstypes ,  loading : false}}
            }))
        })

        axios.get(config.server_url + '/bnsplace').then(function(response){
            var _bnsplace = [];
            response.data.forEach(function(element)
            {
                _bnsplace.push({ label: element , value: element });
            });

            that.setState(ps =>  ({
                business: { ...ps.business, otherinfo: { ...ps.business.otherinfo , placeOccupancyList : _bnsplace ,  loading : false}}
            }))
        });

    }

    handleChange = (value) => {
        debug('handleChange %O', value);

        let newOwnerCode = "";
        let newGender = ""; 
        let newContact = ""; 
        let newFirstName = "";
        let newMiddleName = "";
        let newNationality = "";
        if(value.FIRSTNAME !== undefined && value.FIRSTNAME !== null)
            newFirstName = value.FIRSTNAME;
        if(value.MIDDLE !== undefined && value.MIDDLE !== null)
            newMiddleName = value.MIDDLE;
        if(value.NATIONALITY !== undefined && value.NATIONALITY !== null)
            newNationality = value.NATIONALITY;
        
        // temporary code due to data (null data) from database 
        if(value.CONTACTNUMBER === undefined || value.CONTACTNUMBER === null || value.CONTACTNUMBER.trim() === ''){
            newContact = "63";
        }else{
            let contact = value.CONTACTNUMBER; 
            if (contact.length === 11 && contact.charAt(0) === 0){
                newContact = '63' + contact.substring(1, 11);
            }
        }

        if(value.GENDER !== undefined && value.GENDER !== null){
            let gender = value.GENDER; 
            newGender = gender.toLowerCase(); 
        }

        if (value.ID !== undefined && value.ID !== null)
            newOwnerCode = value.ID;

        if (this.state.business.owner.country !== 'PH')
        {
            this.setState(ps=> ( {
                business: { ...ps.business, owner : { ...ps.business.owner, country : 'PH', isfulladdress: true,  streetlabel : 'Street', dialingcode: '+63'}}
            }))
        }



        let provincecode = '';
        this.state.business.owner.provinces.forEach(function(element){
            if (value.PROVINCE === Object.values(element)[0]) 
            {
                provincecode = Object.keys(element)[0];
                return;
            }
        });
        let citymunicipalities = [ {'':''}];
        let citymunicipalitycode = '';


        if (provincecode !== '')
        {
            const self = this;
            /*load citymunicipalities*/
            axios.get(config.server_url + '/citymunicipalities/' + provincecode.substr(0, 4)).then(function(response){
                response.data.forEach(function(element)
                {
                    citymunicipalities.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                    if (value.CITYMUNICIPALITIES === Object.values(element)[0])
                    {
                        citymunicipalitycode = Object.keys(element)[0];
                        /*load barangays*/
                        axios.get(config.server_url + '/psgcbarangays/' + citymunicipalitycode).then(function(response1){

                            var _barangays = [ {'':''}];
                            let barangaycode = '';
                            for (var i in response1.data)
                            {
                                _barangays.push({ [Object.keys(response1.data[i])[0]] : Object.values(response1.data[i])[0]});
                                if (value.BARANGAY === Object.values(response1.data[i])[0])
                                    barangaycode = Object.keys(response1.data[i])[0];
                            }
                            self.setState(ps => ({
                                business: {
                                    ...ps.business, owner:{
                                        ...ps.business.owner, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangays : _barangays,
                                            barangay : barangaycode
                                    }
                                }
                            }))

                            return;
                        });
                
                        //empty barangay
                        self.setState(ps => ({
                                business: {
                                    ...ps.business, owner:{
                                        ...ps.business.owner, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangay : ''
                                    }
                                }
                            }))
                    
                        return;
                    }


                })
            });
        }


        this.setState(ps => ({
            business: {
                ...ps.business, owner:{
                    ...ps.business.owner, 
                        ownercode: newOwnerCode,
                        firstname: newFirstName,
                        middlename: newMiddleName,
                        gender : newGender, 
                        bdate: value.BDATE,
                        nationality: newNationality,
                        dialingcode : newContact,
                        emailaddress: value.EMAILADDRESS,
                        housenumber : value.HOUSERNUMBER,
                        street : value.STREET,
                        province : provincecode, 
                        citymunicipality : '',
                        citymunicipalities,
                        barangay : ''
                }
            }
        }))
    }

    handleOtherInfoChange = (value) => {
        debug('handleOtherInfoChange %O', value);

        let newOwnerCode = "";
        let newGender = ""; 
        let newContact = ""; 
        let newFirstName = "";
        let newMiddleName = "";
        let newNationality = "";
        if(value.FIRSTNAME !== undefined && value.FIRSTNAME !== null)
            newFirstName = value.FIRSTNAME;
        if(value.MIDDLE !== undefined && value.MIDDLE !== null)
            newMiddleName = value.MIDDLE;
        if(value.NATIONALITY !== undefined && value.NATIONALITY !== null)
            newNationality = value.NATIONALITY;
        
        // temporary code due to data (null data) from database 
        if(value.CONTACTNUMBER === undefined || value.CONTACTNUMBER === null || value.CONTACTNUMBER.trim() === ''){
            newContact = "63";
        }else{
            let contact = value.CONTACTNUMBER; 
            if (contact.length === 11 && contact.charAt(0) === 0){
                newContact = '63' + contact.substring(1, 11);
            }
        }

        if(value.GENDER !== undefined && value.GENDER !== null){
            let gender = value.GENDER; 
            newGender = gender.toLowerCase(); 
        }

        if (value.ID !== undefined && value.ID !== null)
            newOwnerCode = value.ID;

        if (this.state.business.otherinfo.country !== 'PH')
        {
            this.setState(ps=> ( {
                business: { ...ps.business, otherinfo : { ...ps.business.otherinfo, country : 'PH', isfulladdress: true,  streetlabel : 'Street', dialingcode: '+63'}}
            }))
        }

        let provincecode = '';
        this.state.business.otherinfo.provinces.forEach(function(element){
            if (value.PROVINCE === Object.values(element)[0]) 
            {
                provincecode = Object.keys(element)[0];
                return;
            }
        });
        let citymunicipalities = [ {'':''}];
        let citymunicipalitycode = '';


        if (provincecode !== '')
        {
            const self = this;
            /*load citymunicipalities*/
            axios.get(config.server_url + '/citymunicipalities/' + provincecode.substr(0, 4)).then(function(response){
                response.data.forEach(function(element)
                {
                    citymunicipalities.push({ [Object.keys(element)[0]] : Object.values(element)[0]});
                    if (value.CITYMUNICIPALITIES === Object.values(element)[0])
                    {
                        citymunicipalitycode = Object.keys(element)[0];
                        /*load barangays*/
                        axios.get(config.server_url + '/psgcbarangays/' + citymunicipalitycode).then(function(response1){

                            var _barangays = [ {'':''}];
                            let barangaycode = '';
                            for (var i in response1.data)
                            {
                                _barangays.push({ [Object.keys(response1.data[i])[0]] : Object.values(response1.data[i])[0]});
                                if (value.BARANGAY === Object.values(response1.data[i])[0])
                                    barangaycode = Object.keys(response1.data[i])[0];
                            }
                            self.setState(ps => ({
                                business: {
                                    ...ps.business, otherinfo:{
                                        ...ps.business.otherinfo, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangays : _barangays,
                                            barangay : barangaycode
                                    }
                                }
                            }))

                            return;
                        });
                
                        //empty barangay
                        self.setState(ps => ({
                                business: {
                                    ...ps.business, otherinfo:{
                                        ...ps.business.otherinfo, 
                                            ownercode: newOwnerCode,
                                            firstname: newFirstName,
                                            middlename: newMiddleName,
                                            gender : newGender,
                                            bdate: value.BDATE,
                                            nationality: newNationality,
                                            contactnumber : newContact,
                                            emailaddress: value.EMAILADDRESS,
                                            housenumber : value.HOUSERNUMBER,
                                            street : value.STREET,
                                            province : provincecode, //value.PROVINCE,
                                            citymunicipality : citymunicipalitycode, //value.CITYMUNICIPALITIES
                                            citymunicipalities,
                                            barangay : ''
                                    }
                                }
                            }))
                    
                        return;
                    }


                })
            });
        }


        this.setState(ps => ({
            business: {
                ...ps.business, otherinfo:{
                    ...ps.business.otherinfo, 
                        ownercode: newOwnerCode,
                        firstname: newFirstName,
                        middlename: newMiddleName,
                        gender : newGender, 
                        bdate: value.BDATE,
                        nationality: newNationality,
                        dialingcode : newContact,
                        emailaddress: value.EMAILADDRESS,
                        housenumber : value.HOUSERNUMBER,
                        street : value.STREET,
                        province : provincecode, 
                        citymunicipality : '',
                        citymunicipalities,
                        barangay : ''
                }
            }
        }))
    }

    onSaveAddtlbusiness = (data) =>
    {
        this.setState(prevState => ({
            business : {
                ...prevState.business,
                businessinfo : {
                    ...prevState.business.businessinfo,
                    addtlbusiness : data
                }
            }
        }))
    }

    onChanged = key => value =>
    {
        let isCountryChanged = false;

        const _props = this.props;
        //_props.getOwnInfo(value.lastname)

        this.setState( prevState => {
                var selectedCountry

                if(key === "owner") {selectedCountry = prevState.business.owner.country}
                else if(key === "otherinfo") {selectedCountry = prevState.business.otherinfo.country}
                else {selectedCountry = prevState.business.businessinfo.country}

                isCountryChanged = ((selectedCountry  !== value.country))?true:false;
                return {
                    business:  {
                        ...prevState.business, [key] : value
                    }
                }
            },() =>{
                if(isCountryChanged){
                    const self = this;
                    //get dialing code
                    axios.get(config.server_url + '/dialingcode', {params :  {countrycode : value.country} } )
                    .then(function (response) {
                        const dialingcode = response.data.dialing_code;
                        self.setState(ps =>  {
                            if(key === "owner")return {business: { ...ps.business, owner: { ...ps.business.owner , dialingcode , contactnumber: ''}}}
                            else if(key === "otherinfo") return {business: { ...ps.business, otherinfo: { ...ps.business.otherinfo , dialingcode , contactnumber: ''}}}
                            else return {business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , dialingcode , contactnumber: ''}}}
                        })
                    })
                    .catch(function(error){
                        self.setState(ps =>  {
                            if(key === "owner")return {business: { ...ps.business, owner: { ...ps.business.owner , dialingcode : '' , contactnumber: ''}}}
                            else if(key === "otherinfo") return {business: { ...ps.business, otherinfo: { ...ps.business.otherinfo , dialingcode : '' , contactnumber: ''}}}
                            else return {business: { ...ps.business, businessinfo: { ...ps.business.businessinfo , dialingcode : '' , contactnumber: ''}}}
                        })
                    })
                }
            }
        )

    }

    onSubmit = (e) =>
    {
        const _props = this.props;


        if(this.state.business.otherinfo.placeoccupancy == "OWNED"){
            e.preventDefault();
            const _props = this.props;
            //this.props.userSignupRequest(this.state);
            let token = localStorage.getItem('token');
            const self = this;

            axios.post(config.server_url + '/savebusiness', { business : this.state.business}, { headers: {"Authorization" : 'bearer ' + token}}).then(function (response) {
                _props.showAlert('Successfully created account.', 'success');
        //         //clear data
                self.setState( {value : 0 , business : initState} );
                //history.push('/new-business-form');
                history.push('/application-form');

            }).catch(function(error){
                debug('onSubmit %O', error)
                _props.showAlert(error.response.data.errors[0], 'error');
            });   
        }else{

            
            this.setState({
                business: {
                    ...this.state.business,
                    // otherinfo: {
                    //     ...this.state.business.otherinfo,
                    //     haslastnameerror: true,
                    //     hasrentleasesinceerror : true,
                    //     hasrentleasemonthlyerror : true,
                    // },
                    runonce4: false,
                },
            }, () => {
                if(this.state.business.otherinfo.rentleasesince == ""){
                    _props.showAlert('Since when date is require', 'error');  
                }
                else if(this.state.business.otherinfo.rentleasemonthly == ""){
                    _props.showAlert('Monthly Rental is require', 'error');  
                }
                else if(this.state.business.otherinfo.lastname == ""){
                    _props.showAlert('Last Name is requiressss', 'error');  
                }
                else{
                    e.preventDefault();
                    const _props = this.props;
                    //this.props.userSignupRequest(this.state);
                    let token = localStorage.getItem('token');
                    const self = this;

                    axios.post(config.server_url + '/savebusiness', { business : this.state.business}, { headers: {"Authorization" : 'bearer ' + token}}).then(function (response) {
                        _props.showAlert('Successfully created account.', 'success');
                //         //clear data
                        self.setState( {value : 0 , business : initState} );
                        //history.push('/new-business-form');
                        history.push('/application-form');

                    }).catch(function(error){
                        debug('onSubmit %O', error)
                        _props.showAlert(error.response.data.errors[0], 'error');
                    });   
                }
            
            })
        }
    }

    handleNext = () => {


        const stepActive = this.state.activeStep; 
        const { activeStep } = this.state;
        const _props = this.props;

        if(stepActive == 1){
            // change runonce value to set the error to true
            this.setState({
                business: {
                    ...this.state.business,
                    runonce2: false,
                    businessinfo: {
                        ...this.state.business.businessinfo,
                        //hasbusinessnameerror: true
                    }
                },

            }, () => {

                if(this.state.business.businessinfo.hasbusinessnameerror){
                    _props.showAlert('Business Name is required', 'error');  
                }else if(this.state.business.businessinfo.hasorgnkinderror){
                    _props.showAlert('Organization kind is required', 'error');   
                }else if(this.state.business.businessinfo.hasbusinesstypeerror){
                    _props.showAlert('Business Type is required', 'error');               
                }else if(this.state.business.businessinfo.hasstreeterror){
                    _props.showAlert('Street is required', 'error');  
                }else if(this.state.business.businessinfo.hasbarangayerror){
                    _props.showAlert('Barangay is required', 'error');              
                }else if(this.state.business.businessinfo.hascapitalerror){
                    _props.showAlert('Capital is required', 'error');               
                }else{
                    this.setState({
                     activeStep: activeStep + 1,
                    });    
                }
    
            })


        }else if (stepActive == 2){
            this.setState({
                business: {
                    ...this.state.business,
                    // owner: {
                    //     ...this.state.business.owner,
                    //     runonce3: false,
                    // },
                    runonce3: false,
                },


            }, () => {
                if(this.state.business.owner.lastname == ""){
                    _props.showAlert('Last Name is require', 'error');  
                }else{
                    this.setState({
                     activeStep: activeStep + 1,
                    });    
                }
            
            })

        }else{
            this.setState({
             activeStep: activeStep + 1,
            });    
        }
       
    };

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
        activeStep: activeStep - 1,
        });
    };

    handleStep = (prevStep,step) => () => {
        if(prevStep !== step){
            this.setState({
                activeStep: step,
            });
        }
    };


    handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ snackbarOpen: false });
    };


    render() {
        const { classes } = this.props;
        const steps = this.getSteps();
        const { activeStep } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} >
                    <Card className={classes.card }>
                        {/* <CardHeader
                        classes={{
                                root:classes.cardHeader +" " +classes["blueCardHeader"] ,
                                title: classes.cardTitle,
                                subheader: classes.cardSubtitle
                        }}
                        title= "Simple Table"
                        subheader="Here is a subtitle for this table"
                        /> */}
                        <CardHeader color="primary" >
                            <h4 className={classes.cardTitleWhite}>Add New Business</h4>
                            <p className={classes.cardCategoryWhite}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Alert handleCloseButton = {this.props.closeAlert }/>
                            <CardContent>
                                {/* <form method="post" onSubmit={this.onSubmit}>
                                <div className={classes.root}>
                                    <AppBar position="static" color="default">
                                    <Tabs
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        scrollable
                                        scrollButtons="auto"
                                    >
                                        <Tab label="Basic Information" />
                                        <Tab label="Business Information" />
                                        <Tab label="Owners Information/ Profile" />
                                        <Tab label="Other additional Information" />
                                    </Tabs>
                                    </AppBar>
                                    {this.state.value === 0 && <TabContainer>
                                            <BasicInformation
                                                basicinfo={this.state.business.basicinfo}
                                                onBasicinfoChanged={this.onChanged('basicinfo')}
                                                runonce={this.state.business.runonce1}
                                            />
                                        </TabContainer>
                                    }
                                    {this.state.value === 1 &&
                                        <TabContainer>
                                            <BusinessInformation
                                                businessinfo={this.state.business.businessinfo}
                                                onBusinessInfoChanged={this.onChanged('businessinfo')}
                                                runonce={this.state.business.runonce2}
                                            />
                                        </TabContainer>
                                    }
                                    {this.state.value === 2 &&
                                        <TabContainer>
                                            <OwnersInformation
                                                owner={this.state.business.owner}
                                                onOnwerChanged={this.onChanged('owner')}
                                                runonce={this.state.business.runonce3}
                                            />
                                        </TabContainer>
                                    }
                                    {this.state.value === 3 &&
                                        <TabContainer>
                                            <OtherAddtlInformation
                                                owner={this.state.business.owner}
                                                otherinfo={this.state.business.otherinfo}
                                                otherinfoOrigin={initState.otherinfo}
                                                onOtherInfoChanged={this.onChanged('otherinfo')}
                                                runonce={this.state.business.runonce4}
                                            />
                                        </TabContainer>
                                    }
                                </div>
                                    <button type="submit">Save</button>
                                </form> */}


                                <Dialog
                                  open={this.state.business.modal}
                                  onClose={this.handleClose}
                                  aria-labelledby="form-dialog-title"
                                >
                                  <DialogTitle id="form-dialog-title"> Check your Existing Business</DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                    </DialogContentText>
                                        <TextField
                                          id="owncode"
                                          name="owncode"
                                          label="Own Code"
                                          required = {true}
                                          className={classes.textField}
                                          value={this.state.business.existingBusiness.owncode}
                                          onChange={this.handleChangeModelItem}
                                          // onChange={(e) => this.handleChangeModelItem("owncode",e)}
                                          margin="normal"
                                          style = {{width: "100%"}}
                                          error = {this.state.business.existingBusiness.owncodeError && this.state.business.existingBusiness.runonce}
                                        />

                                        <TextField
                                          id="lastname"
                                          name="lastname"
                                          label="Last Name"
                                          required = {true}
                                          className={classes.textField}
                                          value={this.state.business.existingBusiness.lastname}
                                          onChange={this.handleChangeModelItem}
                                          margin="normal"
                                          style = {{width: "100%"}}
                                          error = {this.state.business.existingBusiness.lastnameError && this.state.business.existingBusiness.runonce}
                                        />

                                        <TextField
                                          id="firstname"
                                          name="firstname"
                                          label="First Name"
                                          required = {true}
                                          className={classes.textField}
                                          value={this.state.business.existingBusiness.firstname}
                                          onChange={this.handleChangeModelItem}
                                          margin="normal"
                                          style = {{width: "100%"}}
                                          error = {this.state.business.existingBusiness.firstnameError && this.state.business.existingBusiness.runonce}
                                        />

                                        <TextField
                                          id="middlename"
                                          name="middlename"
                                          label="Middle Name"
                                          className={classes.textField}
                                          onChange={this.handleChangeModelItem}
                                          value={this.state.business.existingBusiness.middlename}
                                          margin="normal"
                                          style = {{width: "100%"}}
                                        />


                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={this.handleClose} color="secondary">
                                      Cancel
                                    </Button>
                                    <Button onClick={() => this.handleSubmitChecker(this.state.business.modalLocation)} color="primary">
                                      Submit
                                    </Button>
                                  </DialogActions>
                                </Dialog>


                                <Snackbar
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  open={this.state.snackbarOpen}
                                  autoHideDuration={6000}
                                  onClose={this.handleSnackbarClose}
                                >
                                  <MySnackbarContentWrapper
                                    onClose={this.handleSnackbarClose}
                                    variant="error"
                                    message="Unable to find data from the given information"
                                    className={classes.margin}
                                  />
                                </Snackbar>

                                <div className={classes.stepperHeader}>
                                    <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel nonLinear>
                                        {steps.map((label, index) => {
                                            return (
                                            // <Step key={label}>
                                            //     <StepLabel>{label}</StepLabel>
                                            // </Step>
                                            <Step key={label}>
                                                <StepButton
                                                    onClick={this.handleStep(activeStep,index)}
                                                >
                                                    {label}
                                                </StepButton>
                                            </Step>
                                            );
                                        })}
                                    </Stepper>
                                    <div>
                                        {this.state.activeStep === steps.length-1 ? (
                                            <Fragment>
                                                <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                                                <div className={classes.stepperButtons}>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={this.handleBack}
                                                        className={classes.backButton}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={this.onSubmit}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
                                                <div className={classes.stepperButtons}>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={this.handleBack}
                                                        className={classes.backButton}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                                                        {/* {activeStep === steps.length - 1 ? 'Submit' : 'Next'} */}
                                                        Next
                                                    </Button>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </CardContent>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


NewBusinessContainer.defaultProps = {
    headerColor: "purple"
};

NewBusinessContainer.propTypes = {
    plainCard: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
    cardTitle: PropTypes.node,
    cardSubtitle: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        userSignupRequest: (userData) => {
            dispatch(userSignupRequest(userData))
        },
        getOwnInfo: (lastname) => {
            dispatch(getOwnInfo(lastname))
        }, 
    }
};


const mapStateToProps = (state) => {
    return ({
        ownerInfoList: state.application.ownersInfoList
    })
}

NewBusinessContainer.propTypes = {
    classes: PropTypes.object,
};


export default connect( mapStateToProps, mapDispatchToProps)( withStyles(regularCardStyle)(NewBusinessContainer));