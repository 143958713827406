import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getUsers, deleteUser,  createUserByAdmin, getUser , updateUser} from '../actions/users'
import UserSettings from '../components/pages/UserSettings'
import UserSettingsAdd from '../components/pages/UserSettingsAdd'
import UserSettingsEdit from '../components/pages/UserSettingsEdit'
import history from '../history';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

/**
  @class User Container
  @description handling user settings, example add,view.edit and delete
  @params match
*/

//this is a great theme
//https://redgeoff.github.io/mson-react/

class UserContainer extends Component {
  componentDidMount() {
    this.props.getUsers(this.props.user._id)
    const { event } = this.props.match.params
    if (event !== 'view' && event !== 'add' && event !== 'edit'){
      history.push('/404');
    }

  }
  render() {
    const { event } = this.props.match.params
    return (
      <div>
      {
        (event === `view`) ?
          <UserSettings value = {this.props || []} /> :
        (event === `add`) ?
          <UserSettingsAdd value = {this.props} />:
        (event === `edit`) ?
          <UserSettingsEdit value = {this.props } />:
        <div></div>
      }
      </div>
    );
  }
}

UserContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users.user_list,
})

export default connect(mapStateToProps,{getUsers,createUserByAdmin,deleteUser,getUser, updateUser})(withStyles(styles)(UserContainer));

