import React, { Component } from 'react';
import EmailField from './EmailField';
import axios from 'axios';
const config = require('../config');

/**
* @class Email
* description Display component for email address only. The Parameter are all state base value.
* @param {string} emailaddress - Email address store in state
* @param {string} emailaddressError - Error messag store in state
* @param {string} runonce - [runonce = true]

*/


class Email extends Component {
	constructor(props){
		super(props);
		const {
			emailaddress: sEmailAddress,
			emailaddressError : sEmailErrorText,
			runonce
		} = props;

		this.state = {
			sEmailAddress,
			sEmailErrorText,
			runonce
		}
	}

	checkEmail = (email, err) => {
		this.checkIfEmailExists(email)
	    return true // always return true, wait for axios to set errorText
	}

	checkIfEmailExists = (email, err) => {
		axios.post( config.server_url + '/userexists', {
			email
		})
		.then( response => {
			this.setState({sEmailErrorText: '' });
		})
		.catch( error => {
			if(error.response.data.hasOwnProperty('errors')){
				this.setState({sEmailErrorText: error.response.data.errors[0]});
			}else{
				console.log("Connection problem!");
			}
		});
	}

	emailChange = email => {
		const sEmailAddress = email.value;
		const sEmailErrorText = email.errorText;
		const runonce = email.runonce;
		const { sPassword, sConfirmPassword } = this.state;
		this.setState( {
			sEmailAddress,
			sEmailErrorText,
			runonce
		})
		if(this.props.onChange !== null && typeof this.props.onChange === "function"){
			this.props.onChange(sEmailAddress, sPassword, sConfirmPassword, sEmailErrorText, false);
		}
	}


	handleChange = event => {
		const value = event.target.value
		const whatChanges = event.target.name

		if(whatChanges === "email"){
			const sEmailAddress = value;
			const { sPassword, sConfirmPassword, sEmailErrorText} = this.state;
			this.setState({sEmailAddress});

			if(this.props.onChange !== null && typeof this.props.onChange === "function"){
				this.props.onChange(sEmailAddress, sPassword, sConfirmPassword, sEmailErrorText, false);
			}

		}
	}

	render() {
		const {
			emailaddress,
			runonce,
		} = this.props;

		const { sEmailErrorText } = this.state;
		return (
			<div>
				<EmailField
				    name = 'email' // local
				    value = {emailaddress} // from props
				    width='300px' // local
				    validateEmail // local
				    errorText={sEmailErrorText} // from state
					runonce={runonce}
				    onEmailChange={this.emailChange} // local
				    onCheckEmail={this.checkEmail} // local
				/><br />
			</div>
		);
	}
}

export default Email
