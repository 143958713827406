import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormControl, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { closeAlert, showAlert } from '../actions/alert';
import forgotStyle from '../assets/css/forgotStyle';
import Alert from '../components/alerts/alert';
import PasswordField from '../components/PasswordField';
import history from '../history';

const config = require('../config');

class ResetPasswordContainer extends Component{
    constructor(props) {
        super(props);

        if (this.props.isAuthenticated) //put to redirect in route
            history.push('/dashboard');

        const forgotcode = this.props.match.params.code;
        const _props = this.props;
        axios.post(config.server_url + '/checkforgotcode', { forgotcode }).catch(function(error){
            if(error.response.data.hasOwnProperty('errors')){
                _props.showAlert(error.response.data.errors[0], 'error');
                history.push('/');
            }else{
                console.log("Connection problem!");
            }
        });
        this.state = {
            password : '',
            confirmPassword : '',
            forgotcode
        };
    }

    onChange = (e) =>
    {
        const { name, value } = e.target;
        this.setState({ [name] : value });
    }

    onSubmit = (e) =>
    {
        e.preventDefault();
        const _props = this.props;
        const forgotcode = this.state.forgotcode;
        const newpassword = this.state.password;
        const confirmPassword = this.state.confirmPassword;
        if(newpassword !== confirmPassword){
            _props.showAlert('Passwords didn\'t match.', 'error');
        }else{
            axios.post(config.server_url + '/newpassword', { forgotcode, newpassword }).then(function (response) {
                _props.showAlert('Successfully reset your password', 'success');
                history.push('/');
            }).catch(function(error){
                if(error.response.data.hasOwnProperty('errors')){
                    _props.showAlert(error.response.data.errors[0], 'error');
                    history.push('/');
                }else{
                    console.log("Connection problem!");
                }
            });
        }
    }

    render(){
        //const { classes } = this.props;
        return(
            <div className="LoginProperties">
                <div className="container">
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            {/* Title Part */}
                            <AppBar position="static" >
                                <Toolbar className="appBarColor">
                                    <Typography variant="title" color="inherit" >
                                        <Grid item>
                                            BPLS-ONLINE
                                        </Grid>
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Paper >
                                <form onSubmit={this.onSubmit}>
                                    <div className="forgot" >
                                        <Typography variant="title" color="inherit" align="center" gutterBottom >
                                            Reset Password
                                        </Typography>
                                        <Typography color="inherit">
                                            Please enter your new password.
                                        </Typography>
                                        <PasswordField
                                            value={this.state.password}
                                            width='380px'
                                            name = 'password'
                                            validatePassword
                                            onChange={ this.onChange }
                                        />
                                        <PasswordField
                                            width='380px'
                                            value = {this.state.confirmPassword}
                                            validatePassword
                                            shouldMatch={this.state.password}
                                            label="Confirm Password"
                                            name = 'confirmPassword'
                                            onChange={ this.onChange }
                                        />
                                        <FormControl fullWidth  >
                                            <Button variant="contained" color="primary" type="submit">
                                                Submit
                                            </Button>
                                        </FormControl>
                                    </div>
                                </form>
                                <br />
                                <div className="text footer">
                                    Copyright 2018. All Rights Reserved.
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Alert />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
    }
}

const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
    }
}

export default connect(mapStateToProps ,mapDispatchToProps)(withStyles(forgotStyle)(ResetPasswordContainer));
// import React from 'react';
// import axios from 'axios';
// import {connect} from 'react-redux';
// import {showAlert, closeAlert} from '../actions/alert';
// import history from '../history';
// import Alert from '../components/alerts/alert';

// class ResetPasswordContainer extends React.Component{

//     constructor(props)
//     {
//         super(props);
//         this.props.closeAlert();

//         const code = this.props.match.params.code;
//         const _props = this.props;
//         axios.get(config.server_url + '/checkforgotcode', { code }).then(function (response) {
//             _props.showAlert('Successfully activated account.', 'success');
//             history.push('/');
//         }).catch(function(error){
//             _props.showAlert(error.response.data.errors[0], 'error');
//         });
//     }

//     render(){
//         return <Alert handleCloseButton = {this.props.closeAlert }/>;
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         showAlert: (message, color) => {
//             dispatch(showAlert(message, color))
//         },
//         closeAlert: () => {
//             dispatch(closeAlert())
//         },
//     }
// };


// export default connect( null, mapDispatchToProps)(ResetPasswordContainer);
