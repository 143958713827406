import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { logoutRequest, renewTokenRequest } from '../../actions/session';
import logo from "../../assets/img/reactlogo.png";
import history from '../../history';

const debug = require('debug')('clientHeader.js');
debug.enabled = process.env.REACT_APP_DEBUG;
const _config = require('../../config');
const styles = theme => ({
    root: {
        width: '100%',
        zIndex: theme.zIndex.drawer + 2,
		backgroundColor: 'rgb(33, 150, 243)',
		color: 'white',
	},
	toolbar:{
		minHeight: '64px',
	},
    avatar: {
        margin: 5,
        height: 30,
        width: 30,
        fontSize: '1em'
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuList:{
        top:40
    },
    title: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        //   display: 'block',
        // },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'table',
        },
    },
    sectionMobile: {
        // display: 'flex',
        // [theme.breakpoints.up('md')]: {
            display: 'none',
        // },
    },
    flex: {
        flex: 1,
    },
    logoImage: {
        width: "30px",
        display: "inline-block",
        maxHeight: "30px",
        // marginLeft: "10px",
        marginRight: "5px"
    },
    img: {
        width: "35px",
        top: "20px",
        position: "absolute",
        verticalAlign: "middle",
        border: "0"
    },
    logoLink: {
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "18px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent",
        maxWidth: "220px",
        "&,&:hover": {
            color: "#FFFFFF"
        }
    },
    userName:{
        display: 'table-cell',
        verticalAlign: 'middle',
    }
});

let timer = undefined;

class MainAppBar extends React.Component {
    state = {
		auth: true,
        anchorEl: null,
        mobileMoreAnchorEl: null,
		avatar: (this.props.user !== undefined && this.props.user.avatar !== undefined && this.props.user.avatar !== '') ? this.props.user.avatar : 'default-avatar.png',
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    componentDidMount(){
        if (this.props.user === undefined){
            history.push('/');
        }
        else{
            let token = localStorage.getItem('token');
            let config = {
                headers: {"Authorization" : 'bearer ' + token},
                params: {
                    id: this.props.user._id,
                }
            }
            const timeout = 1;
            const _props = this.props;
            const self = this;

            axios.get(_config.server_url + '/getdir',config).then((result) => {
                self.setState ({avatar : result.data.imagePath}, () => { })
            }).catch(function(error){
                _props.logoutRequest();
                history.push('/');
            });

            //disable this if remember me is set
            if (!_props.user.rememberme && !process.env.REACT_APP_DEBUG)
            {
                if (timer === undefined)
                {
                    timer = setTimeout( function tick() {
                        let email = _props.user.email;
                        let refreshToken = localStorage.getItem('refreshToken');
                        _props.renewTokenRequest(email, refreshToken);
                        timer = setTimeout(tick, 1000 * 60 * timeout)
                    }, 1000 * 60 * timeout);
                }
            }
            else
            {
                debug('Remember me is set.');
            }
        }
    }

    componentWillUnmount()
    {
        if (timer !== undefined)
        {
            clearTimeout(timer);
            timer = undefined;
        }
    }

    render() {
        const { anchorEl, mobileMoreAnchorEl, avatar : image} = this.state;
        const { classes } = this.props;
        const {firstname , lastname} = this.props.user;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                className = {classes.menuList}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
            {/* <SimpleList /> */}
            {/* <MenuItem  onClick={this.handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={this.handleMenuClose}>My account</MenuItem> */}
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                className = {classes.menuList}
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={isMobileMenuOpen}
                onClose={this.handleMenuClose}
            >
                {/* <MenuItem onClick={this.handleMobileMenuClose}>
                <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
                </MenuItem>
                <MenuItem onClick={this.handleMobileMenuClose}>
                <IconButton color="inherit">
                    <Badge badgeContent={11} color="secondary">
                    <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
                </MenuItem>
                <MenuItem onClick={this.handleProfileMenuOpen}>
                <IconButton color="inherit">
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
                </MenuItem> */}
                {/* <NestedList /> */}
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar className={classes.root} position="fixed">
                    <Toolbar className={classes.toolbar}>
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={this.props.handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        {/* <Typography variant="title" className={classes.flex} color="inherit" href="/" > */}
						<Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            <a href="/" className={classes.logoLink}>
                                <div className={classes.logoImage}>
                                    <img src={logo} alt="logo" className={classes.img} />
                                </div>
                                BPLS-ONLINE
                            </a>
                        </Typography>
                        {/* SEARCH TOOLBAR */}
                        {/* <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            />
                        </div> */}
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            {/* <IconButton color="inherit">
                                <Badge badgeContent={4} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={17} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}
                            {/* <Button
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                            > */}
                            {/* <Avatar className={classes.avatar}><AccountCircle /></Avatar> */}
                            <Avatar src={image} className={classes.avatar}/>
                            <Typography
                                className = {classes.userName}
                                color = "inherit"
                            >
                                {lastname}, {firstname}
                            </Typography>
                            {/* </Button> */}
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                    {renderMenu}
                    {renderMobileMenu}
                </AppBar>
            </div>
        );
    }
}

MainAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
        user : state.session.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutRequest: () => { dispatch(logoutRequest())},
        renewTokenRequest: (email, refreshToken) => { dispatch(renewTokenRequest(email, refreshToken))}
    }
}

export default withStyles(styles)(connect( mapStateToProps , mapDispatchToProps )(MainAppBar));
