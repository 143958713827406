import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import {  CardContent, withStyles } from "@material-ui/core";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Save from '@material-ui/icons/Save';
import { closeAlert, showAlert } from '../actions/alert';
import { loginSuccess, logoutRequest } from '../actions/session';
import regularCardStyle from "../assets/css/regularCardStyle";
import Alert from '../components/alerts/alert';
import ChangeAvatar from "../components/ChangeAvatar";
import ChangePassword from "../components/ChangePassword";
import GlobalAddress from "../components/GlobalAddress";
import MobileNumberField from '../components/MobileNumberField';
import Person from "../components/Person";
import history from '../history';
const debug = require('debug')('client_AccountsettingsContainer');
debug.enabled = process.env.REACT_APP_DEBUG;

const config = require('../config');

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
	},
});

function TabContainer({ children, dir }) {
	return (
		<Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
		{children}
		</Typography>
	);
}

class AccountSettingsContainer extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			user : {
				_id: props.user._id,
				person : {
					lastname : props.user.lastname,
					firstname : props.user.firstname,
					middlename : props.user.middlename,
					haslastnameerror : false,
					hasfirstnameerror : false,
					hasmiddlenameerror : false,
				},
				dialingcode : '-',
				contactnumber : props.user.contactnumber,
				personaddress : {
					housenumber : props.user.housenumber,
					street : props.user.street,
					province : props.user.province,
					citymunicipality : props.user.citymunicipality,
					barangay : props.user.barangay,
					zipcode : props.user.zipcode,
					country : props.user.country,
					streetlabel : props.user.country === 'PH' ? 'Street' : 'Address',
					isfulladdress: true,
					countries : [ {'PH' : 'PHILIPPINES' }],
					provinces : [ {'':''}],
					citymunicipalities : [{'':''}],
					barangays : [{'':''}],
					loading : false,
					hasstreeterror : false,
					hasprovinceerror : false,
					hascitymunicipalityerror : false,
					hasbarangayerror : false,
					haszipcodeerror : false,
					hascountryerror : false,
					runonce : true
				},
				accounts: {
					currentpassword: '',
					newpassword: '',
					confirmPassword : ''
				},
				avatar : props.user.avatar
			},
		value: 0,
		description: '',
		selectedFile: '',
		file: '', 
		displayImage: (props.user !== undefined && props.user.avatar !== undefined && props.user.avatar !== '') ? props.user.avatar : 'default-avatar.png', 
		}
		this.props.closeAlert(); //clear
	}

    componentDidMount = () => {
		const country = this.state.user.personaddress.country;
		var self = this;
        axios.get(config.server_url + '/dialingcode', {params :  {countrycode : country} } )
		.then(function (response) {
			const dialingcode = response.data.dialing_code;
			self.setState(prevState => ( {
				user : { ...prevState.user, dialingcode}
			}));
		})
		.catch(function(error){
			self.setState(prevState => ( {
				user : { ...prevState.user, dialingcode : '-'}
			}));
		});

		let token = localStorage.getItem('token');
		let _config = {
			headers: {"Authorization" : 'bearer ' + token},
			params: {
				id: this.props.user._id,
			}

		}


		axios.get(config.server_url + '/getdir',_config)
			.then((result) => {
					self.setState ({displayImage : result.data.imagePath}, () => { })
			}).catch(function(error){
				// _props.logoutRequest();
				history.push('/');

		});


    }

	handleChange = (event, value) => {
		this.setState({ value });
	};

	handleChangeIndex = index => {
		this.setState({ value: index });
	};

	onPersonChanged = (person) =>
	{
        this.setState( prevState => ( {
            user:  {...prevState.user, person}
        }));
    }

	onPersonAddressChanged = ( personaddress) =>
    {
        let isCountryChanged = false;

        this.setState( function(prevState) {
            if (prevState.user.personaddress.country !== personaddress.country)
            {
                isCountryChanged = true;
                return {user : { ...prevState.user, personaddress} }
            }
            else
            {
                return {user : { ...prevState.user, personaddress} }
            }

        }, () => {
            if (isCountryChanged)
            {
                const self = this;
                axios.get(config.server_url + '/dialingcode', {params :  {countrycode : personaddress.country} } )
                    .then(function (response) {
                        const dialingcode = response.data.dialing_code;
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode, contactnumber: ''}
                        }));
                    })
                    .catch(function(error){
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode : '', contactnumber: ''}
                        }));
                    });
            }

        });

    }
	onChange = (e) =>
	{
		const {name, value} = e.target;
        if (name === 'contactnumber')
        {
			this.setState( prevState => ( {
				user:  {...prevState.user, contactnumber : value}
			}));
        }
	}


	onChangePass = (propsName, value) => {
		this.setState( prevState => ( {
            user:  {...prevState.user, accounts: {...prevState.user.accounts, [propsName]: value}}
        }));
	}


	handleChangePersons = (e) => {
		e.preventDefault();
		const _props = this.props;
		const _state = this.state;
		let token = localStorage.getItem('token');
		axios.post(config.server_url + '/updateprofile',
			{ user: _state.user  },
			{ headers: {"Authorization" : 'bearer ' + token}
		}).then(function(response){
			const {lastname, firstname, middlename} = _state.user.person;
			const {contactnumber} = _state.user;
			const {housenumber, street, country, zipcode, province, citymunicipality, barangay} = _state.user.personaddress;
			_props.loginSuccess( { ..._props.user, lastname, firstname, middlename, contactnumber, housenumber, street, country, zipcode, province, citymunicipality, barangay} );
			_props.showAlert('Successfully changed Profile','success');
		}).catch(function(error){
			_props.showAlert(error.response.data.errors[0], 'error');
		});
	}

    handleChangePassword = (e) =>{
		e.preventDefault();
		const _props = this.props;
		const {currentpassword: oldpassword, password : newpassword } = this.state.user.accounts
		let token = localStorage.getItem('token');
		// const self = this ;
		axios.post(config.server_url + '/changepassword',
				{ oldpassword, newpassword },
				{ headers: {"Authorization" : 'bearer ' + token}
		}).then(function(response){
			_props.showAlert('Successfully changed Password','success');
			history.push('/');
			_props.logoutRequest();
		}).catch(function(error){
			_props.showAlert(error.response.data.errors[0], 'error');
		});
    }

	onSubmit = (e) => {
		e.preventDefault();
		const { description, selectedFile } = this.state;
		let formData = new FormData();
		formData.append('description', description);
		formData.append('selectedFile', selectedFile);
		const _props = this.props;
		// const _state = this.state;//assigned a value but never used
		let token = localStorage.getItem('token');
		axios.post(config.server_url + '/changeAvatar', formData,{ headers: {"Authorization" : 'bearer ' + token}})
		.then((result) => {
			//assigned a value but never used
			// const {lastname, firstname, middlename} = _state.user.person;
			// const {contactnumber} = _state.user;
			// const {housenumber, street, country, zipcode, province, citymunicipality, barangay} = _state.user.personaddress;
			const avatar = result.data.avatar;
			_props.loginSuccess( { ..._props.user, avatar});
			_props.showAlert('Successfully changed avatar','success');
		}).catch(function(error){
			_props.showAlert(error.response.data.errors[0], 'error');
		});
	}

    handleOnChangeAvatar = (e) => {
		switch (e.target.name) {
		case 'selectedFile':
			debug('Target Files %O', e.target.files)
			this.setState({ 
				selectedFile: e.target.files[0],
				file: URL.createObjectURL(e.target.files[0])
			});
			break;
		default:
			this.setState({ [e.target.name]: e.target.value });
		}
    }

    // removing previed images 
    handleRemoveImage = () => {
		this.setState (prevState => ({
			file: undefined
		}), () => {
			console.log(this.state.file)
		})
    }

    handleDeleteImage = () => {
    	const _props = this.props;
    	let token = localStorage.getItem('token');
    	const userId = this.props.user._id; 
    	
    	axios.post(config.server_url + '/public?', {
    	    query: `
    	        mutation deleteImage($_id: String!){
    	            deleteImage(_id: $_id) {
    	                name,
    	            }
    	        }
    	    `,
    	    variables: {
    	        _id: userId
    	    },
    	})
    	.then(
    	        res => { 
    	        	this.setState (prevState => ({
    	        		displayImage:  config.server_url + '/images/default-avatar.png',
    	        	}), () => {
    	        		// history.push('/account-settings');
    	        	})
    	        }
    	    )
    	.catch((error) => console.log(error))

		// this.setState( prevState => ( {
  //           user:  {...prevState.user, accounts: {...prevState.user.accounts, [propsName]: value}}
  //       }));
    }

	render(){
		const {classes, theme } = this.props;
		return(
			<div>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} >
						<Card className={classes.card }>
							<CardHeader color="primary" >
								<h4 className={classes.cardTitleWhite}>Account Settings</h4>
								<p className={classes.cardCategoryWhite}>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
								</p>
							</CardHeader>
							<CardBody>
								<CardContent>
									<Tabs
										value={this.state.value}
										onChange={this.handleChange}
										indicatorColor="primary"
										textColor="primary"
										variant = "fullWidth"
									>
										<Tab label="Update Information" />
										<Tab label="Change Password" />
										<Tab label="Change Avatar" />
									</Tabs>
									<SwipeableViews
										axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
										index={this.state.value}
										onChangeIndex={this.handleChangeIndex}
									>
										{/* Tab 1 : Update Information  */}
										<TabContainer dir={theme.direction}>
											<div className={classes.container} >
												<Person {...this.state.user.person} onPersonChanged={this.onPersonChanged} />
												<MobileNumberField
													name='contactnumber'
													label='Contact Number'
													dialingCode={this.state.user.dialingcode}
													value={this.state.user.contactnumber}
													onChange={this.onChange}
													error={ (this.state.user.contactnumber.trim() === '' ||
														(this.state.user.dialingcode !== '-' &&  (this.state.user.contactnumber.trim() === ('+' + this.state.user.dialingcode)) )
														) }
												/>
												<GlobalAddress
													{...this.state.user.personaddress} onAddressChanged={this.onPersonAddressChanged}
												/>
												<Button className={classes.button} variant="contained" size="small" color="primary" onClick={this.handleChangePersons}>
													<Save className={classNames(classes.leftIcon, classes.iconSmall)} />
													Save
													</Button>
											</div>
										</TabContainer>
										{/* Tab 2 : Change Password  */}
										<TabContainer dir={theme.direction}>
											<div className={classes.container} >
													<ChangePassword onChange={this.onChangePass} value={this.state.user.accounts} />
													<Button className={classes.button} variant="contained" size="small" color="primary" onClick={this.handleChangePassword}>
													<Save className={classNames(classes.leftIcon, classes.iconSmall)} />
													Save
													</Button>
											</div>
										</TabContainer>
										{/* Tab 3 : Change Avatar  */}
										<TabContainer dir={theme.direction}>
											<div className={classes.container} >
													<ChangeAvatar 
														onChange = {this.handleOnChangeAvatar} 
														displayImage = {this.state.displayImage} 
														removeImage = {this.handleRemoveImage} 
														deleteImage = {this.handleDeleteImage}
														file= {this.state.file} 
														onSubmit = {this.onSubmit} 
													/>
											</div>
										</TabContainer>
									</SwipeableViews>
								</CardContent>
							</CardBody>
						</Card>
					</Grid>
				</Grid>
				<Alert />
			</div>
		);
	}
}

const mapStateToProps = state => {
    return  {
        user : state.session.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
		showAlert: (message, color) => {
			dispatch(showAlert(message, color))
		},
		closeAlert: () => {
			dispatch(closeAlert())
		},
		logoutRequest : () => {
			dispatch(logoutRequest());
		},
		loginSuccess: (user) => {
			dispatch(loginSuccess(user))
		}
    }
}

export default withStyles(regularCardStyle,(styles, { withTheme: true }))(connect(mapStateToProps,mapDispatchToProps)(AccountSettingsContainer));
