import React from 'react';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

class DatePickers extends React.Component {

    onChange = (name) => (event) =>
    {
        let value = event.format()
        this.props.onDateChanged( {...this.props, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    }

    render() {

        const {
            label = 'null',
            name = null,
            // currentDate = false,
            required = false,
            disabled = false,
            openTo = 'day',
            format = 'MM/DD/YYYY',
            disableFuture = false,
            xsSize,
            mdSize,
            lgSize,
        } = this.props;

        let {
            value = '',
        } = this.props;

        if(value === '') value = new Date()

        return (
            <Grid item xs={xsSize} md={mdSize} lg={lgSize}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        id={name}
                        name={name}
                        label={label}
                        value={value}
                        required={required}
                        disabled={disabled}
                        disableFuture={disableFuture}
                        openTo={openTo}
                        format={format}
                        views={["year", "month", "day"]}
                        onChange={this.onChange(name)}
                        style={{width:'100%'}}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        );
    }
}

export default DatePickers;