import React from 'react';
import EmailField from '../EmailField';
import PasswordField from '../PasswordField';

import axios from 'axios';
const config = require('../../config');

class EmailRegistration extends React.Component {

    constructor(props) {
        super(props);

        const {
            emailaddress: sEmailAddress,
            password: sPassword,
            confirmPassword: sConfirmPassword,
            emailaddressError : sEmailErrorText,
            runonce
        } = props.value;

        this.state = {
            sEmailAddress,
            sPassword,
            sConfirmPassword,
            sEmailErrorText,
            runonce
        };
    }

    checkEmail = (email, err) => {
        this.checkIfEmailExists(email)
        return true // always return true, wait for axios to set errorText
    }

    checkIfEmailExists = (email, err) => {
        axios.post(config.server_url + '/userexists', {
            email
        })
        .then( response => {
            
            this.setState({sEmailErrorText: '' });
        })
        .catch( error => {
            if(error.response.data.hasOwnProperty('errors')){
                this.setState({sEmailErrorText: error.response.data.errors[0]});
            }else{
                console.log("Connection problem!");
            }
        });
    }

    emailChange = email => {
        const sEmailAddress = email.value;
        const sEmailErrorText = email.errorText;
        const runonce = email.runonce;
        const { sPassword, sConfirmPassword } = this.state;
        this.setState( {
            sEmailAddress,
            sEmailErrorText,
            runonce
        })
        if(this.props.onChange !== null && typeof this.props.onChange === "function"){
            this.props.onChange(sEmailAddress, sPassword, sConfirmPassword, sEmailErrorText, false);
        }
    }

    handleChange = event => {
        const value = event.target.value
        const whatChanges = event.target.name

        if(whatChanges === "password"){
            const sPassword = value;
            const { sEmailAddress, sConfirmPassword, sEmailErrorText} = this.state;
            this.setState({sPassword});

            if(this.props.onChange !== null && typeof this.props.onChange === "function"){
                this.props.onChange(sEmailAddress, sPassword, sConfirmPassword, sEmailErrorText, false);
            }

        }else if(whatChanges === "email"){
            const sEmailAddress = value;
            const { sPassword, sConfirmPassword, sEmailErrorText} = this.state;
            this.setState({sEmailAddress});

            if(this.props.onChange !== null && typeof this.props.onChange === "function"){
                this.props.onChange(sEmailAddress, sPassword, sConfirmPassword, sEmailErrorText, false);
            }

        }else if(whatChanges === "confirmPassword"){
            const sConfirmPassword = value;
            const { sPassword, sEmailAddress, sEmailErrorText } = this.state;
            this.setState({sConfirmPassword});

            if(this.props.onChange !== null && typeof this.props.onChange === "function"){
                this.props.onChange(sEmailAddress, sPassword, sConfirmPassword, sEmailErrorText, false);
            }
        }
    }

    render(){
        const {
            value = { emailaddress : '', password : '', confirmPassword : '', runonce: true},
        } = this.props;

        const {
            sEmailErrorText,
            sPassword,
        } = this.state;

        return <div>
            <EmailField
                name = 'email' // local

                value = {value.emailaddress} // from props

                width='300px' // local
                validateEmail // local
                errorText={sEmailErrorText} // from state
                runonce={value.runonce}

                onEmailChange={this.emailChange} // local
                onCheckEmail={this.checkEmail} // local
            /><br/>

            <PasswordField
                name = 'password' // local

                value={value.password} // from props

                width='300px' // local
                validatePassword // local

                onChange={this.handleChange} // local
            /><br/>

            <PasswordField
                name = 'confirmPassword' // local

                value = {value.confirmPassword} // from props

                width='300px' // local
                label="Confirm Password" // local
                validatePassword // local

                onChange={this.handleChange} // local
                shouldMatch={sPassword} // from state
            /><br/>
        </div>
    }
}

export default EmailRegistration;