import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import history from '../../history';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {
	withStyles,
	CardContent,
} from "@material-ui/core";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import regularCardStyle from "../../assets/css/regularCardStyle";
import { Delete, Edit } from '@material-ui/icons';

import Table from '../Table/Table';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
	},
	action:{
		minWidth: 80
	},
	fab:{
		margin: 1,
		width: 36,
		height: 36
	}
});

/**
 	@class ActionWrapped
	@description Display action events for user
 */
class ActionWrapped extends React.Component {
	render() {
		const {
			classes,
			handleEdit,
			handleDelete
		} = this.props;
		const { id } = this.props.row;
		return (
			<div className={classes.action}>
				<Fab className={classes.fab} onClick={event => handleEdit(id)} color="primary" aria-label="Edit" title="Edit" size='small'>
					<Edit />
				</Fab>
				<Fab className={classes.fab} onClick={event => handleDelete(id)} color="secondary" aria-label="Delete" title="Delete" size='small'>
					<Delete />
				</Fab>
			</div>
		);
	}
}
const Action = withStyles(styles, { withTheme: true })(ActionWrapped);

/**

	@class UserSettings
	@description Display list of users

*/
class UserSettings extends Component {
	constructor(props){
		super(props);
		this.state = {
			'columns': [
				{title: 'Id', name : 'id'},
				{title: 'No',name: 'no'},
				{title: 'Last Name',name: 'lastname'},
				{title: 'First Name',name: 'firstname'},
				{title: 'Email',name: 'email'},
				{title: 'User Rights',name: 'access'},
				{title: 'Action',name: 'action'},
			],
			'columnDefs' :[
                { visible: false, targets: 0 },
                { responsivePriority: 1, targets: 4 },
                { responsivePriority: 2, targets: -1 },
            ]
		}
	}

	handleDeleteUser = (userId) => {
		const _props = this.props.value;
		_props.deleteUser(userId)
	}

	handleEditUser = (clickedId) => {
		history.push(`/user-settings/edit/${clickedId}`);
	}


	render() {
		const {classes} = this.props;
		const {users} = this.props.value;
		console.log(users);
		const {columns, columnDefs} = this.state;
		let cnt = 0;
		let _users = [];
		if (users !== undefined && users !== null)
		{
			_users = users.map(row => (
				{id: row._id, 
				no : ++cnt,
				firstname : row.firstname,
				lastname : row.lastname,
				email : row.email,
				access : row.access,
				}
			) 
			);
		}
		
		let count = 0;
		return (
			<div>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} >
						<Card className={classes.card }>
							<CardHeader color="primary" >
								<h4 className={classes.cardTitleWhite}>List of users</h4>
								<p className={classes.cardCategoryWhite}>
									This table will display the list of users and admin registered in the website!
								</p>
							</CardHeader>
							<CardBody>
								<CardContent>
									<Link to ="/user-settings/add">
										<Button variant="text" color="inherit" className={classes.button}>
											<AddIcon/>
											Add user
										</Button>
										<br />
									</Link>
									<Paper className={classes.root}>
										{ (_users.length > 0) && 
											<Table
												rows ={_users}
												columns = {columns}
												columnDefs = {columnDefs}
												action={(row) => (
														<Action
															handleEdit={this.handleEditUser}
															handleDelete={this.handleDeleteUser}
															row={row}
														/>
													)}
											
											/>
										}
									</Paper>
								</CardContent>
							</CardBody>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}

}

UserSettings.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(regularCardStyle,(styles, { withTheme: true }))(UserSettings);
