import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './routers/AppRouter'
import {Provider} from 'react-redux';
import {sessionConstants} from './constants/session';

import { PersistGate } from 'redux-persist/integration/react';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import configureStore from './store/configureStore';
const {store, persistor} = configureStore();

const theme = createMuiTheme({
    overrides: {
        MUIDataTableToolbarSelect: {
            root : {
                display: 'none'
            }
        },
        MuiTableCell: {
            root : {
              padding: '0px'
            },
        }
    }
});
  

const token = localStorage.getItem('token');
if (token) {
    store.dispatch( { type: sessionConstants.LOGIN_SUCCESS, user: JSON.parse(localStorage.getItem('user'))} );
}

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
                <AppRouter/>
            </PersistGate>
        </MuiThemeProvider>
    </Provider>
, document.querySelector("#root"));