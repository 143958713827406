import React, {Component} from 'react';
import '../../assets/css/datatables.css';
const $ = require('jquery');
$.DataTable = require('datatables.net-responsive');

class Table extends Component {
    constructor() {
        super();
        this.state = {
            rows: [{}],
            columns: []
        }
    }

    componentWillMount() {
        this.setState({
            rows:[
                ...this.props.rows
            ],
            columns: [
                ...this.props.columns,
            ],
            columnDefs: [
                ...this.props.columnDefs
            ]
        })
    }

    componentDidMount() {
        const {ordering =false,responsive=true} = this.props
        const {columns, columnDefs} = this.state
        $(this.refs.main).DataTable({
            ordering,
            columns,
            responsive,
            columnDefs
        });
    }

    render() {
        const {action = ()=>'NO ACTIONS DECLARED' } = this.props
        const {columns, rows} = this.state
        return (
            <table ref="main">
                <tbody>
                    {rows.map((row,key) => (
                        <tr key={key}>
                            {columns.map((data,key) => {
                                return(
                                <td key={key}>
                                    {(data.name !== 'action')?row[data.name]:action(row)}
                                </td>
                            )})}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}

export default Table;