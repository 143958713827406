import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DatePicker from '../DatePicker';
import GlobalAddress from '../GlobalAddress';
import MobileNumberField from '../MobileNumberField';
import NumberField from '../NumberField';
import Person from '../Person';
import SelectField from '../SelectField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import { withStyles } from '@material-ui/core/styles';

const debug = require('debug')('client_OtherAddtlInformation');
debug.enabled = process.env.REACT_APP_DEBUG;

// const selectList = [
//     {value:"male" ,label : "Male"},
//     {value:"female" ,label : "Female"},
// ] //assigned a value but never used


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },

  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },

  card: {
    minWidth: 275,
  },

  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

});

class OtherAddtlInformation extends Component {

    handleChange = (e) =>{
        const {name, value} = e.target;
        this.props.onOtherInfoChanged( {...this.props.otherinfo, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    }


    onSelectChange = name => value =>
    {
        if(value == null)value = '';
        this.props.onOtherInfoChanged( {...this.props.otherinfo, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});

        if(name === "placeoccupancy"){


             this.props.changeRunonce(); // this fnction / props will change runonce from true to false
            // because we need to remove red mark to the textfield if not necessary. 

            const isOwned = (value === "OWNED")
            const isNull = (value === "")
            var {
                ownercode, lastname, firstname, middlename, contactnumber, housenumber, street, province, citymunicipality, barangay,
                citymunicipalities, barangays, zipcode, country , dialingcode, isfulladdress, streetlabel
            } = (isOwned)?this.props.owner:this.props.otherinfoOrigin;

            var isrentleasesincedisabled = (isOwned || isNull)
            var isrentleasemonthlydisabled = (isOwned || isNull)
            var isnamedisabled = (isOwned || isNull)
            var ishousenumberdisabled = (isOwned || isNull)
            var isstreetdisabled = (isOwned || isNull)
            var isbarangaydisabled = (isOwned || isNull)
            var iscontactnumberdisabled = (isOwned || isNull)
            var isaddressdisabled = (isOwned || isNull)

            if(country !== "PH"){
                isfulladdress= false;
                streetlabel = 'Address'
            }


            this.props.onOtherInfoChanged( {
                ...this.props.otherinfo,
                ownercode,
                lastname,
                firstname,
                middlename,
                contactnumber,
                housenumber,
                street,
                province,
                citymunicipality,
                barangay,
                citymunicipalities,
                barangays,
                zipcode,
                country,
                dialingcode,
                isfulladdress,
                streetlabel,
                placeoccupancy : value,
                isrentleasesincedisabled,
                isrentleasemonthlydisabled,
                isnamedisabled,
                ishousenumberdisabled,
                isstreetdisabled,
                isbarangaydisabled,
                iscontactnumberdisabled,
                isaddressdisabled,
            });
        }
    }


    componentDidMount() {
        if (this.props.otherinfo.placeoccupancy === "OWNED")
            this.onSelectChange('placeoccupancy')(this.props.otherinfo.placeoccupancy)
    }

    render(){
        const  props  = this.props
        const { classes } = props;
        debug('Props %O', props.getSelectedInfo);

        return(
            <Grid container spacing={16}>
                <SelectField
                    value={props.otherinfo.placeoccupancy}
                    id="placeoccupancy"
                    name="placeoccupancy"
                    label="Place Business"
                    menuItem = {props.otherinfo.placeOccupancyList}
                    onChange = {this.onSelectChange}
                    clearable={false}
                    lgSize={3}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.otherinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onOtherInfoChanged}
                    label="Since When"
                    name="rentleasesince"
                    value={props.otherinfo.rentleasesince}
                    disabled={props.otherinfo.isrentleasesincedisabled}
                    lgSize={4}
                    mdSize={6}
                    xsSize={12}
                />
                <NumberField
                    label="Monthly Rental"
                    name="rentleasemonthly"
                    value={props.otherinfo.rentleasemonthly}
                    onChange = {this.handleChange}
                    disabled={props.otherinfo.isrentleasemonthlydisabled}
                    lgSize={5}
                    mdSize={6}
                    xsSize={12}
                    Currencyformat = {true}
                    error = {props.otherinfo.hasrentleasemonthlyerror && !props.runonce}
                />
                {
                    (props.otherinfo.placeoccupancy == "RENTED") ? 
                        <Grid container justify="center" spacing={12}>
                            <Button variant="contained" color="primary" className={classes.button} onClick = {() => this.props.handleClickOpen("otherinfo")}>
                            Existing Business 
                            <SearchIcon className={classes.rightIcon} />
                            </Button>
                        </Grid> : 
                        <label></label>
                }
                
                <Person {...props.otherinfo} runonce={props.runonce} onPersonChanged={props.onOtherInfoChanged} 
                    disabled={props.otherinfo.isnamedisabled}
                    autoComplete = {!props.otherinfo.isnamedisabled} 
                    getOwnInfoList = {this.props.getOwnInfoList} 
                    getSelectedInfo = {this.props.getSelectedInfo}                
                />
                <GlobalAddress {...props.otherinfo} runonce={props.runonce} onAddressChanged={props.onOtherInfoChanged}/>
                <MobileNumberField
                    name='contactnumber'
                    label='Contact Number'
                    dialingCode={props.otherinfo.dialingcode}
                    value={props.otherinfo.contactnumber}
                    onChange = {this.handleChange}
                    // error={ (props.otherinfo.contactnumber.trim() === '' ||
                    //     (props.otherinfo.dialingcode !== '-' &&  (props.otherinfo.contactnumber.trim() === ('+' + props.otherinfo.dialingcode)) )
                    //     ) && !props.runonce}
                    error = {false}
                    disabled={props.otherinfo.iscontactnumberdisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
            </Grid>
        )
    }
}

export default withStyles(styles)(OtherAddtlInformation);;


