import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import ApplicationListItem from './ApplicationListItem';

const debug = require('debug')('client');
debug.enabled = process.env.REACT_APP_DEBUG;

const CustomTableCell = withStyles(theme => ({
	head: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.common.black,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
	row: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default,
		},
	},
});

class ApplicationList extends React.Component{

	render(){
		const { classes, businesses } = this.props;
		let data = [];
		let id = 0;
		debug('My debug... %O', businesses);
		if (Array.isArray(businesses)) 
		{
			businesses.forEach(function(business) {
				id += 1;
				data.push( {id, ...business});
			});
		}
		return(
			<div>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
								<CustomTableCell>Stat</CustomTableCell>
								<CustomTableCell>Full Name</CustomTableCell>
								<CustomTableCell >BIN</CustomTableCell>
								<CustomTableCell >Business Name</CustomTableCell>
								<CustomTableCell >Business Address</CustomTableCell>
								<CustomTableCell >Tax Year</CustomTableCell>
								<CustomTableCell>Status</CustomTableCell>
								<CustomTableCell >Action</CustomTableCell>
							</TableRow>
					</TableHead>
					<TableBody>
						{data.map(n => {
							return (<ApplicationListItem classes={this.props.classes} n={n} key={n.id}/>);
							}
						)}
					</TableBody>
				</Table>
			</div>
		)
	}
}

export default withStyles(styles)(ApplicationList)