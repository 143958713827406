import React,{Fragment} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontSize: "1.125em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "5px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
});

function DashboardPage(props) {
	const { classes } = props;
	return (
		<Fragment>
			<Card>
				<CardHeader color="primary" >
					<h4 className={classes.cardTitleWhite}>Dashboard</h4>
					<p className={classes.cardCategoryWhite}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
					</p>
				</CardHeader>
				<CardBody>
					<Typography variant="body1">Dashboard</Typography>
				</CardBody>
				<CardFooter>
					<Typography variant="body1">Dashboard</Typography>
				</CardFooter>
			</Card>
		</Fragment>
	);
}

export default withStyles(styles)(DashboardPage);
