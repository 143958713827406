import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
  },
});


const TextMaskCustom = prefix => props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            placeholderChar={'_'}
            mask={ [...prefix.split(''), '-', ...Array(4).fill(/\d/), '-', ...Array(7).fill(/\d/) ] }
            keepCharPositions={true}
            showMask
        />
    );

}


class BINField extends React.Component {

    constructor(props){
        super(props)

        const {
            prefix = '',
        } = this.props;

        this.prefix = prefix
        this.maskControl = TextMaskCustom(prefix)
    }

    onChange = event => {
        const {
            onChange = () => true
        } = this.props

        onChange(event)
    }



    render(){
        const {
            classes,
            label =  "BIN",
            name,
            value = null,
            error,
            prefix,
            disabled
        } = this.props

        // not needed for now (prefix doesn't change dynamically)
        if(this.prefix !== prefix){
            this.maskControl = TextMaskCustom(prefix)
            this.prefix = prefix
        }

        return <FormControl className={classes.formControl}>
          <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
          <Input
            id="formatted-text-mask-input"
            name={name}
            value={value || prefix}
            onChange={this.onChange}
            inputComponent={this.maskControl}
            error={error}
            disabled={disabled}
          />
        </FormControl>
    }

}

BINField.propTypes = {
    prefix: PropTypes.string.isRequired,
}

export default withStyles(styles)(BINField);