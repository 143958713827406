import React ,{ Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextFields from '../TextFields';
import NumberField from '../NumberField';
import DatePicker from '../DatePicker';

class BasicInformation extends Component {

    handleChange = (e) =>{
        const {name, value} = e.target;
        this.props.onBasicinfoChanged( {...this.props.basicinfo, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    }

    render(){
        const props = this.props
        return(
            <Grid container spacing={16}>
                <TextFields
                    label="status"
                    name="stat"
                    value={props.basicinfo.stat}
                    onChange = {this.handleChange}
                    inputProps={{
                        'style' : {'textTransform': 'uppercase', 'textAlign': 'center'}
                    }}
                    disabled={props.basicinfo.isstatdisabled}
                    lgSize={2}
                    mdSize={6}
                    xsSize={12}
                />
                <TextFields
                    label="Tax Year"
                    name="taxyear"
                    value={props.basicinfo.taxyear}
                    onChange = {this.handleChange}
                    inputProps={{
                        'style' : {'textTransform': 'uppercase', 'textAlign': 'center'}
                    }}
                    disabled={props.basicinfo.istaxyeardisabled}
                    lgSize={2}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.basicinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onBasicinfoChanged}
                    label="Application Date"
                    name="dateapplication"
                    value={props.basicinfo.dateapplication}
                    disabled={props.basicinfo.isdateapplicationdisabled}
                    lgSize={4}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.basicinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onBasicinfoChanged}
                    label="Operation Start"
                    name="datestarted"
                    value={props.basicinfo.datestarted}
                    disabled={props.basicinfo.isdatestarteddisabled}
                    lgSize={4}
                    mdSize={6}
                    xsSize={12}
                />
                <NumberField
                    label="DTI/SEC/CDA Reg no."
                    name="dtiseccdaregnum"
                    value={props.basicinfo.dtiseccdaregnum}
                    onChange = {this.handleChange}
                    disabled={props.basicinfo.isdtiseccdaregnumdisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.basicinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onBasicinfoChanged}
                    label="DTI/SEC/CDA Date Issued"
                    name="dtiseccdadateissued"
                    value={props.basicinfo.dtiseccdadateissued}
                    disabled={props.basicinfo.isdtiseccdadateissueddisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <NumberField
                    label="TIN No."
                    name="tinnum"
                    value={props.basicinfo.tinnum}
                    onChange = {this.handleChange}
                    disabled={props.basicinfo.istinnumdisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.basicinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onBasicinfoChanged}
                    label="TIN No. Date Issued"
                    name="tindateissued"
                    value={props.basicinfo.tindateissued}
                    disabled={props.basicinfo.istindateissueddisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <NumberField
                    label="SSS No."
                    name="sssnum"
                    value={props.basicinfo.sssnum}
                    onChange = {this.handleChange}
                    disabled={props.basicinfo.issssnumdisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.basicinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onBasicinfoChanged}
                    label="SSS No. Date Issued"
                    name="sssdateissued"
                    value={props.basicinfo.sssdateissued}
                    disabled={props.basicinfo.issssdateissueddisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <NumberField
                    label="CTC No."
                    name="ctcnum"
                    value={props.basicinfo.ctcnum}
                    onChange = {this.handleChange}
                    disabled={props.basicinfo.isctcnumdisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
                <DatePicker
                    {...props.basicinfo}
                    runonce={props.runonce}
                    onDateChanged={props.onBasicinfoChanged}
                    label="CTC No. Date Issued"
                    name="ctcdateissued"
                    value={props.basicinfo.ctcdateissued}
                    disabled={props.basicinfo.isctcdateissueddisabled}
                    lgSize={6}
                    mdSize={6}
                    xsSize={12}
                />
            </Grid>
        )
    }
}
export default BasicInformation;
