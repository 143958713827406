import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { hideLoadingRequest, showLoadingRequest } from '../actions/session';
import { CardContent, Grid, withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import { Autorenew, Delete, Edit, Print, AddCircle, NoteAdd } from '@material-ui/icons';
import ApplicationList from '../components/ApplicationList';
import history from '../history';
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import { closeAlert, showAlert } from '../actions/alert';
import Alert from '../components/alerts/alert';
import Table from '../components/Table/Table';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const config = require('../config');

const buttonproperty = {
	margin: 20
}

const styles = theme => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontSize: "1.125em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "5px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},
	action:{
		minWidth: 120
	},
	fab:{
		margin: 1,
		width: 36,
		height: 36
	}
});

class ActionWrapped extends React.Component {
	render() {
		const {
			classes,
			handleDelete,
			handleRenew,
			handlePrint
		} = this.props;
		const { id, bin, stat } = this.props.row;
		return (
			<div className={classes.action}>
			    
				<Fab className={classes.fab} onClick={event => handleRenew(event, id)} color="primary" aria-label="Renew" title="Renew" size='small'>
					<Autorenew />
				</Fab>
				<Fab className={classes.fab} onClick={event => handleDelete(event, id)} color="secondary" aria-label="Delete" title="Delete" size='small'>
					<Delete />
				</Fab>
				<Fab className={classes.fab} onClick={event => handlePrint(event, bin)} color="default" aria-label="Print" title="Print" size='small'>
					<Print />
				</Fab>
			</div>
		);
	}
}
const Action = withStyles(styles, { withTheme: true })(ActionWrapped);

class ApplicationFormContainer extends React.Component{
	constructor(props)
	{
		super(props);
		this.state = {
			'businesses' : [],
			'columns': [
				{title: 'BIN',name: 'bin'},
				{title: 'Business Name',name: 'businessname'},
				{title: 'Owner',name: 'fullname'},
				{title: 'Tax Year',name: 'taxyear'},
				{title: 'Status',name: 'stat'},
				{title: 'Action',name: 'action'},
			],
			'columnDefs' :[
                { responsivePriority: 1, targets: 0 },
                { responsivePriority: 2, targets: -1 },
            ],
            alertOpen : false, 
            deletingID : '',
		}
	}

	handleRenew = (event, _id) => {
		if (_id === '')
			return;
		history.push('business-form/'+ _id);
	};

    handleDelete = (event,id) => {
    	this.setState({alertOpen: true, deletingID: id})
	};

	handleRemoveItem = () => {
		const delID = this.state.deletingID; 

		if (delID !== null) {
			axios.post(config.server_url + '/public?', {
			    query: `
			        mutation deleteApp($_id: String!){
			             deleteApp(_id: $_id) {
			             	_id
			             }
			         }
			    `,
			    variables: {
			        _id: delID
			    },
			})
			.then(
			        res => {
			            this.handleCloseAlert(); 
			            history.push('/application-form');
			        }
			    )
			.catch((error) => console.log(error))
		}
	};

	handleCloseAlert = () => {
		this.setState({ alertOpen: false });
	};

	

	handlePrint = (event, bin) => {
		if (bin === '')
			return;
		const _props = this.props;
		_props.showLoadingRequest();

		let token = localStorage.getItem('token');
		// const self = this;// assigned but never used
		axios.get(config.server_url + '/applicationform/' + bin,
		{ headers: {"Authorization" : 'bearer ' + token}, responseType : 'arraybuffer'
		}).then(function(response){
			let data = new Buffer(response.data, 'binary').toString('base64');
			const url = 'data:application/pdf;base64,' + data;
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', bin +'.pdf'); //or any other extension
			document.body.appendChild(link);
			link.click();
			_props.hideLoadingRequest();
		}).catch(function(error){
			_props.hideLoadingRequest();
		});
	};

	onNewClick = () => {
		history.push('/new-business-form');
	}

	onRenewClick = () => {
		history.push('/renew-business-form');
	}

	componentDidMount = async () => {
		let token = localStorage.getItem('token');
		const self = this;

		// axios.get(config.server_url + '/business',
		// 	{ headers: {"Authorization" : 'bearer ' + token}
		// }).then(function(response){
		// 	console.log('s')
		// 	console.log(response.data)
		// 	self.setState({
		// 		'businesses': response.data.map((row,key)=>{
		// 			const fullname = row.firstname+" "+row.middlename+" "+row.lastname
		// 			const id = row._id
		// 			return (
		// 				{
		// 					id,
		// 					fullname,
		// 					...row,
		// 				}
		// 			)
		// 		})
		// 	});
		// }).catch(function(error){
		// 	self.setState( {'businesses': {}});
		// });

		const {_id: userId } = this.props.user; 
		
		const query = `{
		    getApplication(id: "${userId}") {
		        bin
	            businessname
	            lastname
	            firstname
	            middlename
	            taxyear
	            stat
	            _id
		    }
		}`

		const url_api = config.server_url + '/public?query=' + query;

		await axios.get(url_api).then(
		    res => {
				const list = res.data.data.getApplication; 
		        self.setState({
		        	'businesses': list.map((row,key)=>{
		        		const fullname = row.firstname+" "+row.middlename+" "+row.lastname
		        		const id = row._id
		        		return (
		        			{
		        				id,
		        				fullname,
		        				...row,
		        			}
		        		)
		        	})
		        });
		    }
		)

	};

	render(){
		const { classes } = this.props;
		const {
			columns,
			businesses,
			columnDefs
		} = this.state;
		
		return(
			<div>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} >
						<Card className={classes.card }>
							<CardHeader color="primary" >
								<h4 className={classes.cardTitleWhite}>Application Form</h4>
								<p className={classes.cardCategoryWhite}>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
								</p>
							</CardHeader>
							<CardBody>
								<div >
									<Alert handleCloseButton = {this.props.closeAlert }/>
									<Tooltip id="tooltip-fab" title="Add New">
										<Button style = {buttonproperty} className={classes.button} variant="text" color="primary" onClick={this.onNewClick} >
												<AddCircle className={classes.leftIcon} />
												New Business
										</Button>
									</Tooltip>
									<Tooltip id="tooltip-fab" title="Add Business Renew">
										<Button style = {buttonproperty} className={classes.button} variant="text" color="primary" onClick={this.onRenewClick}>
												<NoteAdd className={classes.leftIcon} />
												Renew Business
										</Button>
									</Tooltip>
								</div>
								<CardContent>
									{(businesses.length > 0)&&
										<Table
											// businesses={businesses}
											rows={businesses}
											action={(row) => (
														<Action
															handlePrint={(event,bin)=>this.handlePrint(event,bin)}
															handleDelete={this.handleDelete}
															handleRenew={this.handleRenew}
															row={row}
														/>
													)}
											columns={columns}
											columnDefs={columnDefs}
										/>
									}
									{/* <ApplicationList businesses={this.state.businesses}/> */}
								</CardContent>
							</CardBody>
						</Card>
					</Grid>
				</Grid>

				<Dialog
		          open={this.state.alertOpen}
		          onClose={this.handleCloseAlert}
		          aria-labelledby="alert-dialog-title"
		          aria-describedby="alert-dialog-description"
		        >
		          <DialogTitle id="alert-dialog-title">{"BIN DELETE"}</DialogTitle>
		          <DialogContent>
		            <DialogContentText id="alert-dialog-description">
		              Are you sure you would like to delete this item?
		            </DialogContentText>
		          </DialogContent>
		          <DialogActions>
		            <Button onClick={this.handleRemoveItem} color="primary">
		              Yes
		            </Button>
		            <Button onClick={this.handleCloseAlert} color="primary" autoFocus>
		              Close
		            </Button>
		          </DialogActions>
		        </Dialog>
			</div>
		);
	}
}


const mapStateToProps = (state) => {
    return ({
        user : state.session.user,
    })
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
		showLoadingRequest : ()  => dispatch(showLoadingRequest()),
		hideLoadingRequest : ()  => dispatch(hideLoadingRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(ApplicationFormContainer))
