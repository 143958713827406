import { userConstants } from '../constants'; 

const applicationReducerDefaultState = {
	ownersInfoList : []
}; 

export default (state = applicationReducerDefaultState, action) => {
	switch(action.type){
		case userConstants.OWNINFO_GET:{
			return {
				...state,
				ownersInfoList: [
					action.payload.data.getOwnInfo
				]
			}
		}
		default:
		    return state;
		
	}
}