import React from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {showAlert, closeAlert} from '../actions/alert';
import history from '../history';
import Alert from '../components/alerts/alert';

const config = require('../config');

class VerifyAccountContainer extends React.Component{

    constructor(props)
    {
        super(props);
        this.props.closeAlert();

        const code = this.props.match.params.code; 
        const _props = this.props;
        axios.post(config.server_url + '/verifyaccount', { code }).then(function (response) {
            _props.showAlert('Successfully activated account.', 'success');
            history.push('/');
        }).catch(function(error){
            _props.showAlert(error.response.data.errors[0], 'error');
        });
    }
    
    render(){
        return <Alert handleCloseButton = {this.props.closeAlert }/>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
    }
};


export default connect( null, mapDispatchToProps)(VerifyAccountContainer);
