import React from 'react';
import { Router} from 'react-router-dom';

import history from '../history';
import MainPage from '../components/pages/MainPage';


const AppRouter = () => (
<Router history={history}>
    <MainPage/>
</Router>
);

export default AppRouter;