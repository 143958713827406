import { userConstants } from '../constants'; 

const userReducerDefaultState = { 
    users : [ 
        {email : 'x@x.com'},
        {email : 'y@y.com'},
        {email : 'z@z.com'}
    ],
    user_list: [],
    userWillUpdate: {}, 
};


export default (state = userReducerDefaultState, action) => {
    switch (action.type) {
    case userConstants.USER_ADD:{
        return {
            ...state, 
            user_list: [...state.user_list, action.payload.data]
        }
    }
    case userConstants.USER_DELETE:
    {
        let new_user_list = state.user_list.filter(function (user) {
            return user._id !== action.payload;
        });

        return {
            ...state, user_list : new_user_list
            
        }
    }
    case userConstants.USERS_GET:{
        return {
            ...state, user_list: action.payload.data.users
        }
    }
    case userConstants.USER_GET:
        return {
             ...state, 
             userWillUpdate: action.payload.data.getUser[0]
        }
    default:
        return state;
    }
};