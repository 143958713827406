import React from "react"
import {  withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
const _config = require('../config');

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});

const imageProperty = {
  height: '250px',
  width : '250px', 
}

class ChangeAvatar extends React.Component{
	render(){
    const { classes } = this.props;
    const currentImage = this.props.displayImage; 
		return(
      <div>
        <div>
          { 
            (this.props.file) 
            ?
              <div className = "row">
                <div className = "col-md-12">
                  <img src={this.props.file} style = {imageProperty} />
                </div>
                <div className = "col-md-12">
                   <Button variant="contained" color="secondary" className={classes.button} onClick = {this.props.removeImage}>
                    Delete
                  </Button>
                  <br />
                </div>
              </div>
           : 
              <div className = "row">
                <div className = "col-md-12">
                  <img src={currentImage}  style = {imageProperty} />
                </div>
                <div className = "col-md-12">
                   <Button variant="contained" color="secondary" className={classes.button} onClick = {this.props.deleteImage}>
                    Delete
                  </Button>
                  <br />
                </div>
              </div>
            }
        </div>
        <br />
          <form onSubmit={this.props.onSubmit}>
          <input
            className={classes.input}
            id="selectedFile"
            name="selectedFile"
            type="file" 
            onChange={this.props.onChange}
          />
          <label htmlFor="selectedFile">
                <Button variant="contained" component="span" color="secondary" className={classes.button}>
                  Upload
                </Button>
          </label>          
          <Button className={classes.button} color="primary" type="submit">Submit</Button>
        </form>
      </div>
		);
	}
}


export default withStyles(styles, { withTheme: true })(ChangeAvatar)
