import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import classNames from 'classnames';
import {  CardContent, withStyles } from "@material-ui/core";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";

import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Save from '@material-ui/icons/Save';
import { closeAlert, showAlert } from '../../actions/alert';
import { hideLoadingRequest, showLoadingRequest } from '../../actions/session';
import { getUser, userSignupRequest } from '../../actions/users';
import regularCardStyle from "../../assets/css/regularCardStyle";
import Email from "../../components/Email";
import history from '../../history';
import PersonalInformation from '../registration/PersonalInformation';

// radio
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const config = require('../../config');

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
		display: 'flex',
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
});



/**

	@class UserSettingsEdit
	@description Editing/ Updating users (admin)
*/
class UserSettingsEdit extends Component {
	constructor(props){
		super(props);
		this.state = {
			accessValue: '', // radio button
			user : {
				_id: '',
				person : {
					lastname : '',
					firstname : '',
					middlename : '',
					haslastnameerror : false,
					hasfirstnameerror : false,
					hasmiddlenameerror : false,
				},
					dialingcode : '63', //default country
					contactnumber : '',
						personaddress : {
							housenumber : '',
							street : '',
							province : '',
							citymunicipality : '',
							barangay : '',
							zipcode : '',
							country : 'PH',
							streetlabel : '',
							isfulladdress: true,
							countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
							provinces : [ {'':''}],
							citymunicipalities : [{'':''}],
							barangays : [{'':''}],
							loading : false,
							hasstreeterror : false,
							hasprovinceerror : false,
							hascitymunicipalityerror : false,
							hasbarangayerror : false,
							haszipcodeerror : false,
							hascountryerror : false,
							runonce : true
						},
						access: '',
						emailaddress: '',
						emailaddressError: 'Invalid Email.',
						runonce2 : true,
						accounts: {
						currentpassword: '',
						newpassword: '',
						confirmPassword : ''
				},
			},
			value: 0,
		description: '',
		selectedFile: '',
		}

	}

	onPersonChanged = (person) =>
	{
		this.setState( prevState => ( {
			user:  {...prevState.user, person}
		}));
	}

	componentWillMount() {
		const _props = this.props.value.match.params;
		if(_props.id == undefined || _props.id == "")
			history.push('/user-settings/view')

		this.props.getUser(_props.id,() => {
			this.setState({
				...this.state,
				accessValue: this.props.userWillUpdate.access,
				user: {
					...this.state.user,
					_id: this.props.userWillUpdate._id,
					contactnumber : this.props.userWillUpdate.contactnumber,
					personaddress: {
						housenumber : this.props.userWillUpdate.housenumber,
						street : this.props.userWillUpdate.street,
						province : this.props.userWillUpdate.province,
						citymunicipality : this.props.userWillUpdate.citymunicipality,
						barangay : this.props.userWillUpdate.barangay,
						zipcode : this.props.userWillUpdate.zipcode,
						country : this.props.userWillUpdate.country,
					},
					emailaddress: this.props.userWillUpdate.email,
					person: {
						...this.state.user.person,
						lastname : this.props.userWillUpdate.lastname,
						firstname : this.props.userWillUpdate.firstname,
						middlename : this.props.userWillUpdate.middlename,
					}
				}
			})
		})
	}


	handleUpdateUser = (e) => {
		e.preventDefault();
		const _props = this.props;
		const {lastname, firstname, middlename} = this.state.user.person;
		const {housenumber, street, province, citymunicipality, barangay, zipcode, country} = this.state.user.personaddress;
		const {accessValue} = this.state; 
		const { _id } = this.state.user;

		const input = {
			_id : _id,
			name : firstname + '' + lastname,
			email : this.state.user.emailaddress,
			lastname : lastname,
			firstname : firstname,
			middlename : middlename,
			contactnumber : this.state.user.contactnumber,
			housenumber : housenumber,
			street : street,
			province : province,
			citymunicipality : citymunicipality,
			barangay : barangay,
			zipcode : zipcode,
			country : country,
			access: accessValue,
		}
		_props.value.updateUser(input, this.refreshPage);
	}

	refreshPage = () => {
		history.push('/user-settings/view');
	}

	onPersonAddressChanged = ( personaddress) =>
    {
        let isCountryChanged = false;

        this.setState( function(prevState) {
            if (prevState.user.personaddress.country !== personaddress.country)
            {
                isCountryChanged = true;
                return {user : { ...prevState.user, personaddress} }
            }
            else
            {
                return {user : { ...prevState.user, personaddress} }
            }

        }, () => {
            if (isCountryChanged)
            {
                const self = this;
                axios.get(config.server_url + '/dialingcode', {params :  {countrycode : personaddress.country} } )
                    .then(function (response) {
                        const dialingcode = response.data.dialing_code;
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode, contactnumber: ''}
                        }));
                    })
                    .catch(function(error){
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode : '', contactnumber: ''}
                        }));
                    });
            }

        });

    }


    onChange = (e) =>
    {
		const {name, value} = e.target;

		var user = this.state.user;
		if (name === 'contactnumber')
			user.contactnumber = value;
		else if (name === 'emailaddress')
			user.emailaddress = value;
		else if (name === 'password')
			user.password = value;
		else if (name === 'bin')
			user.bin = value;
		else if (name === 'permitnumber')
			user.permitnumber = value;

		this.setState({user});
	}

    checkEmail = fn => e => {
		const email = e.target.value;

		const {
			onEmailChange = null,
			onChange = null,
		} = this.props;

		const isValid  = this.isEmailValid(email);
		if (onEmailChange)
		{
			onEmailChange( { value: email, errorText: isValid ? "" : "Email invalid", runonce : false });
			if (isValid)
			fn(email); //callback
		}
		else if(onChange)
		{
			onChange(e,  isValid ? "" : "Email invalid");
		}
	}


	//radio button
	handleChange = event => {
		this.setState({ accessValue: event.target.value }, () => {
			this.setState({
				...this.state,
				user: {
					...this.state.user,
					access: this.state.accessValue
				}
			})
		});
	};

	render() {

		const {classes } = this.props;
		const {emailaddress, emailaddressError, runonce2, ...user} = this.state.user
		return (
			<div>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} >
						<Card className={classes.card }>
							<CardHeader color="primary" >
								<h4 className={classes.cardTitleWhite}>Edit User Form</h4>
								<p className={classes.cardCategoryWhite}>
									This form will modify information of user/admin!
								</p>
							</CardHeader>
							<CardBody>
								<CardContent>
									<div className={classes.container} >
										{/*
										<Person  onPersonChanged={this.onPersonChanged} />
										<GlobalAddress
											{...this.state.user.personaddress} onAddressChanged={this.onPersonAddressChanged}
										/>

										*/}

										<PersonalInformation person={this.state.user.person} onPersonChanged={this.onPersonChanged}
											dialingcode={this.state.user.dialingcode} contactnumber={this.state.user.contactnumber} onChange={this.onChange} 
											personaddress={this.state.user.personaddress} onAddressChanged={this.onPersonAddressChanged} runonce={this.state.user.runonce1} 
										/>

										<Email
											onChange={
												(emailaddress, emailaddressError, runonce2) => {
													this.setState({
														user: {
															...user,
															emailaddress,
															emailaddressError,
															runonce2
														}
													})
												}
											}
								
									emailaddress = {emailaddress}
									emailaddressError = {emailaddressError}
									runonce = {runonce2}
									// value={{emailaddress, emailaddressError, 'runonce':runonce2}}
								/>


						       <div>
		       				        <FormControl component="fieldset" className={classes.formControl}>
		       		                  <FormLabel component="legend">Access Right</FormLabel>
		       		                  <RadioGroup
		       		                    aria-label="accessRights"
		       		                    name="gender2"
		       		                    className={classes.group}
		       		                    value={this.state.accessValue}
		       		                    onChange={this.handleChange}
		       		                  >
		       		                    <FormControlLabel
		       		                      value="admin"
		       		                      control={<Radio color="primary" />}
		       		                      label="Admin"
		       		                      labelPlacement="start"
		       		                    />
		       		                    <FormControlLabel
		       		                      value="user"
		       		                      control={<Radio color="primary" />}
		       		                      label="User"
		       		                      labelPlacement="start"
		       		                    />
		       		                  </RadioGroup>
		       		                </FormControl>
						       </div>


								<Button className={classes.button} variant="contained" size="small" color="primary" onClick={this.handleUpdateUser}>
									<Save className={classNames(classes.leftIcon, classes.iconSmall)} />
									Update
									</Button>
							</div>
							</CardContent>
							</CardBody>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}

}




const mapStateToProps = state => {
    return  {
       userWillUpdate: state.users.userWillUpdate
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        userSignupRequest: (userData) => {
            dispatch(userSignupRequest(userData))
        },
        showLoadingRequest : ()  => {
            dispatch(showLoadingRequest())
        },
        hideLoadingRequest : ()  => {
            dispatch(hideLoadingRequest())
        },
		getUser : (clickedID) => {
			dispatch(getUser(clickedID))
		}
    }
};

// export default withStyles(regularCardStyle,(styles, { withTheme: true }))(connect( mapStateToProps, mapDispatchToProps)(UserSettingsEdit));
export default connect(mapStateToProps, {getUser})(withStyles(regularCardStyle,(styles, { withTheme: true }))(UserSettingsEdit));