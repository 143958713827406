import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const styles = {
    root: {
        padding: 20
    },
    appBar: {
        backgroundColor: 'rgb(33, 150, 243)',
        color: 'white',
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    button:{
        width: '100%',
        padding: '7px 0px'
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class DialogButton extends React.Component {
    render(){
        const {classes, buttonChild, buttonColor = 'primary'} = this.props;
        if(buttonChild === false){
            return false
        }else{
            return (
                <Button
                    className={classes.button}
                    variant="contained"
                    size="small"
                    color={buttonColor}
                    onClick={this.props.onClick}
                >
                    {buttonChild}
                </Button>
            )
        }
    }
}

const DialogButtonWrapped = withStyles(styles)(DialogButton);

class FullScreenDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.closeAction()
    };

    handleSave = () => {
        this.props.saveAction()
        this.setState({ open: false });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    render() {
        const { classes, title} = this.props;
        const { buttonChild = false, buttonColor = 'primary'} = this.props;
        return (
            <Fragment>
                <DialogButtonWrapped
                    buttonChild={buttonChild}
                    buttonColor={buttonColor}
                    onClick={()=>this.handleClickOpen()}
                />
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography color="inherit" className={classes.flex}>
                                {title}
                            </Typography>
                            <Button color="inherit" onClick={this.handleSave}>
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.root}>
                        {this.props.children}
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

FullScreenDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);
