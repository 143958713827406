import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { AddCircle, Store } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import FullScreenModal from './modals/FullScreenModal';
import NumberField from './NumberField';
import SelectField from './SelectField';
import TextFields from './TextFields';
import TextField from '@material-ui/core/TextField';

// debugging 
const debug = require('debug')('client');
debug.enabled = process.env.REACT_APP_DEBUG;

const styles = theme => ({
    textfield:{
        width: '100%',
    },
    button:{
        width: '100%',
        padding: '7px 0px'
    },
    itemButton:{
        marginTop: '8px',
        height: 40
    }
});

class BusinessField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addtlbusinessInput : [  {businesstype: "", stat: "NEW", capital : ""} ],
            statuses : [ {label:'NEW', value:'NEW'}, {label:'REN', value:'REN'}]
        };
    }

    appendAddtlbusinessInput = (state) => {
        var newAddtlbusinessInput = {businesstype: "", stat: "NEW", capital : ""};
        this.setState( (prevState) => {
            console.log(prevState);
            return { 
                addtlbusinessInput: prevState.addtlbusinessInput.concat(newAddtlbusinessInput) 
            }
        });
    }

    onSaveAddtlbusinessInput = () =>{
        this.props.onSaveAddtlbusiness(this.state.addtlbusinessInput);
    }

    resetAddtlbusinessInput = () => {
        if(this.state.addtlbusinessInput !== this.props.addtlbusiness){
            this.setState({
                addtlbusinessInput : this.props.addtlbusiness
            })
        }
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.props.onBusinessFieldChanged( {...this.props, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    }

    onSelectChange = name => value =>{
        if(value == null)value = '';
        this.props.onBusinessFieldChanged( {...this.props, [name] : value,  ['has' + name + 'error'] : value.trim() === ''});
    }

    onRemoveAddtnlbusiness = id =>{
        this.setState(prevState =>{
            var filtered = prevState.addtlbusinessInput.filter( function(value, index, err){
                return index !== id;
            });
            return { addtlbusinessInput : filtered};
        })
    }

    onChangeAddtnlbusiness = id => e =>{
        const {name, value} = e.target;
        this.setState(prevState => ({
            addtlbusinessInput: prevState.addtlbusinessInput.map((data,key) => {
                if (key === id) {
                    return {
                        ...data,
                        [name]: value
                    }
                }
                return data
            })
        }))
    }

    onSelectChangeAddtnlbusiness = id => name => value =>{
        if(value == null)value = '';
        this.setState(prevState => ({
            addtlbusinessInput: prevState.addtlbusinessInput.map((data,key) => {
                if (key === id) {
                    return {
                        ...data,
                        [name]: value
                    }
                }
                return data
            })
        }))
    }

    componentDidMount = () =>{
        this.resetAddtlbusinessInput()
    }

    render() {
        const { classes, isrenewal } = this.props;
        return (
            <Fragment>
                <TextFields
                    className={classes.textfield}
                    value={this.props.businessname}
                    id="businessName"
                    name="businessname"
                    label="Business Name"
                    type="text"
                    onChange={this.onChange}
                    error={this.props.hasbusinessnameerror && !this.props.runonce}
                    required
                    lgSize={8}
                    mdSize={6}
                    xsSize={12}
                    disabled={isrenewal}
                />
                <SelectField
                    className={classes.textfield}
                    value={this.props.orgnkind}
                    id="orgnKind"
                    name="orgnkind"
                    label="Orgn. Kind"
                    menuItem = {this.props.orgnKind}
                    onChange = {this.onSelectChange}
                    error={this.props.hasorgnkinderror && !this.props.runonce}
                    required
                    lgSize={4}
                    mdSize={6}
                    xsSize={12}
                    disabled={isrenewal}
                />
                <SelectField
                    className={classes.textfield}
                    value={this.props.businesstype}
                    id="businessType"
                    name="businesstype"
                    label="Business Type"
                    menuItem = {this.props.businessType}
                    onChange = {this.onSelectChange}
                    error={this.props.hasbusinesstypeerror && !this.props.runonce}
                    required
                    lgSize={isrenewal ? 5 : 8}
                    mdSize={isrenewal ? 5 : 8}
                    xsSize={12}
                    disabled={isrenewal}
                />
                { isrenewal ? 
                (<Grid item xs={12} md={3} lg={3} className={classes.itemButton} >
                    <FullScreenModal
                        title="Additional Business"
                        closeAction={this.resetAddtlbusinessInput}
                        saveAction = {this.onSaveAddtlbusinessInput}
                        buttonChild= {
                            <Fragment>
                                <Store/>
                                <MediaQuery query="(min-device-width: 1293px)">
                                        Additional Business
                                </MediaQuery>
                                <MediaQuery query="(max-device-width: 959px)">
                                        Additional Business
                                </MediaQuery>
                            </Fragment>
                        }
                    >
                        <Grid container spacing={16}>
                            {this.state.addtlbusinessInput.map((input,key) => (
                                <Fragment key={key}>
                                    <SelectField
                                        className={classes.textfield}
                                        value={input.businesstype}
                                        id="businessType"
                                        name="businesstype"
                                        label="Business Type"
                                        menuItem = {this.props.businessType}
                                        onChange = {this.onSelectChangeAddtnlbusiness(key)}
                                        lgSize={3}
                                        mdSize={3}
                                        xsSize={11}
                                    />
                                    <TextField
                                        id="stat"
                                        name="stat"
                                        label="Status"
                                        placeholder="Status"
                                        disabled={true}
                                        value = {input.stat} onChange={this.onSelectChangeAddtnlbusiness(key)}
                                        lgSize={2}
                                        mdSize={2}
                                        xsSize={11}
                                    />
                                    <NumberField
                                        label="Capital"
                                        name="capital"
                                        id="capital"
                                        value={input.capital}
                                        onChange = {this.onChangeAddtnlbusiness(key)}
                                        lgSize={5}
                                        mdSize={5}
                                        xsSize={11}
                                        Currencyformat = {true}
                                        disabled={input.stat === undefined || input.stat === null || input.stat === "REN"}
                                    />
                                    {input.stat === "REN" && 
                                        <Fragment lgSize={4}
                                        mdSize={4}
                                        xsSize={11}>
                                            <NumberField
                                                label="Previous Gross"
                                                name="gr2"
                                                id="gr2"
                                                value={input.gr2}
                                                onChange = {this.onChangeAddtnlbusiness(key)}
                                                lgSize={2}
                                                mdSize={2}
                                                xsSize={11}
                                                Currencyformat = {true}
                                                disabled={true}
                                            />
                                            <NumberField
                                                label="Current Gross"
                                                name="gr1"
                                                id="gr1"
                                                value={input.gr1}
                                                onChange = {this.onChangeAddtnlbusiness(key)}
                                                lgSize={2}
                                                mdSize={2}
                                                xsSize={11}
                                                Currencyformat = {true}
                                            />
                                        </Fragment>
                                    }
                                    <Grid item xs={1} >
                                        <IconButton color="secondary" onClick={() => this.onRemoveAddtnlbusiness(key)} aria-label="Close">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Fragment>
                            ))}
                            <Tooltip id="tooltip-fab" title="Add New">
                                <Button
                                    className={classes.itemButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>this.appendAddtlbusinessInput(this.state)}
                                >
                                    <AddCircle className={classes.leftIcon} />
                                    New Business
                                </Button>
                            </Tooltip>
                        </Grid>
                    </FullScreenModal>
                </Grid>) : null }

                <NumberField
                    label="Capital"
                    name="capital"
                    id="capital"
                    value={this.props.capital}
                    onChange = {this.onChange}
                    lgSize={4}
                    mdSize={4}
                    xsSize={12}
                    Currencyformat = {true}
                    disabled={isrenewal}
                    error={this.props.hascapitalerror && !this.props.runonce}
                    required={!isrenewal}
                />
                { isrenewal ? 
                (
                    <Fragment>
                        <NumberField
                            label="Previous Gross"
                            name="gr2"
                            id="gr2"
                            value={this.props.gr2}
                            onChange = {this.onChange}
                            lgSize={4}
                            mdSize={4}
                            xsSize={12}
                            disabled={true}
                            Currencyformat = {true}
                        />
                        <NumberField
                            label="Gross"
                            name="gr1"
                            id="gr1"
                            value={this.props.gr1}
                            onChange = {this.onChange}
                            lgSize={4}
                            mdSize={4}
                            xsSize={12}
                            Currencyformat = {true}
                            required
                        />
                    </Fragment>
                ) : null }
            </Fragment>
        );
    }
}

BusinessField.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BusinessField);