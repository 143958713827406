import React, { Component , Fragment } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';

class Address extends Component
{
    onCountryChanged = (v) =>
    {
    }

    onProvinceChanged = (v) =>
    {
    }

    onCityMunicipalityChanged = (v) =>
    {
    }

    onChange = (e) =>
    {
        const {name, value} = e.target;
        if (name ==='country')
            this.onCountryChanged(value);
        else if (name === 'province')
            this.onProvinceChanged(value);
        else if (name === 'citymunicipality')
            this.onCityMunicipalityChanged(value);
        else
            this.props.onAddressChanged(  {...this.props, [name] : value,  ['has' + name + 'error'] : value.trim() === '' } );
    }

    render() {
        const componentMargin = {
            marginRight: 75,
            width : '100%',
        }

        if (this.props.loading)
        {
            return (
                <Fragment>
                        <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    id="housenumber"
                                    name="housenumber"
                                    label="Address"
                                    placeholder="Address"
                                    disabled={this.props.ishousenumberdisabled}
                                    style = {{'width':'100%'}}
                                    value = {this.props.housenumber} onChange={this.onChange}
                                />
                        </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            id="street"
                            name="street"
                            style = {{'width':'100%'}}
                            label={this.props.streetlabel}
                            disabled={this.props.isstreetdisabled}
                            placeholder={this.props.streetlabel}
                            value = {this.props.street} onChange={this.onChange}
                            fullWidth = {!this.props.isfulladdress}
                            error={this.props.hasstreeterror && !this.props.runonce}
                            required
                        />
                    </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                                <FormControl style = {componentMargin}>
                                    <InputLabel htmlFor="province">Province</InputLabel>
                                    <Select
                                        native
                                        onChange={this.onChange}
                                        value={this.props.province}
                                        error={this.props.hasprovinceerror && !this.props.runonce}
                                        disabled={this.props.isaddressdisabled}
                                        required
                                        inputProps={{
                                            id: 'province',
                                            name: 'province'
                                        }}
                                    >
                                        {provinces}
                                    </Select>
                                </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                                <FormControl style = {componentMargin}>
                                <InputLabel htmlFor="citymunicipality">City/Municipality</InputLabel>
                                    <Select
                                        native
                                        onChange={this.onChange}
                                        value={this.props.citymunicipality}
                                        error={this.props.hascitymunicipalityerror && !this.props.runonce}
                                        required
                                        disabled={this.props.isaddressdisabled || !this.props.province}
                                        inputProps={{
                                            id: 'citymunicipality',
                                            name: 'citymunicipality'
                                        }}
                                    >
                                    {citymunicipalities}
                                    </Select>
    
                                </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                                <FormControl style = {componentMargin}>
                                    <InputLabel htmlFor="barangay">Barangay</InputLabel>
                                    <Select
                                        native
                                        onChange={this.onChange}
                                        value={this.props.barangay}
                                        // error={this.props.hasbarangayerror && !this.props.runonce}
                                        error={false}
                                        disabled={this.props.isbarangaydisabled || !this.props.citymunicipality}
                                        required
                                        inputProps={{
                                            id: 'barangay',
                                            name: 'barangay'
                                        }}
                                    >
                                        {_barangays}
                                    </Select>
                                </FormControl>
                        </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl style = {componentMargin}>
                            <InputLabel htmlFor="country">Country</InputLabel>
                            <Select
                                native
                                onChange={this.onChange}
                                value={this.props.country}
                                error={this.props.hascountryerror && !this.props.runonce}
                                required
                                disabled={this.props.isaddressdisabled}
                                inputProps={{
                                    id: 'country',
                                    name: 'country'
                                }}
                            >
                            {countries}
                            </Select>
                        </FormControl>
                    </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    id="zipcode"
                                    name="zipcode"
                                    label="Zip Code"
                                    placeholder="Zip Code"
                                    disabled={this.props.isaddressdisabled}
                                    style = {componentMargin}
                                    error={this.props.haszipcodeerror && !this.props.runonce}
                                    required
                                    value = {this.props.zipcode} onChange={this.onChange}
                                />
                        </Grid>
                </Fragment>
            );
        }   
            //return  <p>Loading...</p>;

        var country_selected = this.props.country;
        var countries = this.props.countries.map(function(_country,key){
            const _selected = (country_selected ===_country.country_code ) ? 'defaultValue' : '';
            return <option key={key} _selected={_selected} value={_country.country_code}>{_country.country_name}</option>;
        });
        var province_selected = this.props.province;
        var provinces = this.props.provinces.map(function(_province,key){
            const _selected = (province_selected === Object.keys(_province)[0] ) ? 'defaultValue' : '';
            return <option key={key} _selected={_selected} value={Object.keys(_province)[0]}>{_province[Object.keys(_province)[0]]}</option>;
        });
        var citymunicipality_selected = this.props.citymunicipality;
        var citymunicipalities = this.props.citymunicipalities.map(function(_citymunicipality,key){
            const _selected = (citymunicipality_selected === Object.keys(_citymunicipality)[0] ) ? 'defaultValue' : '';
            return <option key={key} _selected={_selected} value={Object.keys(_citymunicipality)[0]}>{_citymunicipality[Object.keys(_citymunicipality)[0]]}</option>;
        });
        var barangay_selected = this.props.barangay;
        var _barangays = this.props.barangays.map(function(_barangay,key){
            const _selected = (barangay_selected === Object.keys(_barangay)[0] ) ? 'defaultValue' : '';
            return <option key={key} _selected={_selected} value={Object.keys(_barangay)[0]}>{_barangay[Object.keys(_barangay)[0]]}</option>;
        });
        return (
            <Fragment>
                {this.props.isfulladdress &&
                    <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                id="housenumber"
                                name="housenumber"
                                label="Address"
                                placeholder="Address"
                                disabled={this.props.ishousenumberdisabled}
                                style = {{'width':'100%'}}
                                value = {this.props.housenumber} onChange={this.onChange}
                            />
                    </Grid>
                }
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        id="street"
                        name="street"
                        style = {{'width':'100%'}}
                        label={this.props.streetlabel}
                        disabled={this.props.isstreetdisabled}
                        placeholder={this.props.streetlabel}
                        value = {this.props.street} onChange={this.onChange}
                        fullWidth = {!this.props.isfulladdress}
                        error={this.props.hasstreeterror && !this.props.runonce}
                        required
                    />
                </Grid>
                {this.props.isfulladdress &&
                    <Grid item xs={12} md={6} lg={4}>
                            <FormControl style = {componentMargin}>
                                <InputLabel htmlFor="province">Province</InputLabel>
                                <Select
                                    native
                                    onChange={this.onChange}
                                    value={this.props.province}
                                    error={this.props.hasprovinceerror && !this.props.runonce}
                                    disabled={this.props.isaddressdisabled}
                                    required
                                    inputProps={{
                                        id: 'province',
                                        name: 'province'
                                    }}
                                >
                                    {provinces}
                                </Select>
                            </FormControl>
                    </Grid>
                }
                {this.props.isfulladdress &&
                    <Grid item xs={12} md={6} lg={4}>
                            <FormControl style = {componentMargin}>
                            <InputLabel htmlFor="citymunicipality">City/Municipality</InputLabel>
                                <Select
                                    native
                                    onChange={this.onChange}
                                    value={this.props.citymunicipality}
                                    error={this.props.hascitymunicipalityerror && !this.props.runonce}
                                    required
                                    disabled={this.props.isaddressdisabled || !this.props.province}
                                    inputProps={{
                                        id: 'citymunicipality',
                                        name: 'citymunicipality'
                                    }}
                                >
                                {citymunicipalities}
                                </Select>

                            </FormControl>
                    </Grid>
                }
                {this.props.isfulladdress &&
                    <Grid item xs={12} md={6} lg={4}>
                            <FormControl style = {componentMargin} error={this.props.hasbarangayerror && !this.props.runonce}>
                                <InputLabel htmlFor="barangay">Barangay*</InputLabel>
                                <Select
                                    native
                                    onChange={this.onChange}
                                    value={this.props.barangay}
                                    disabled={this.props.isbarangaydisabled || !this.props.citymunicipality}
                                    required
                                    inputProps={{
                                        id: 'barangay',
                                        name: 'barangay'
                                    }}
                                >
                                    {_barangays}
                                </Select>
                            </FormControl>
                    </Grid>
                }
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl style = {componentMargin}>
                        <InputLabel htmlFor="country">Country</InputLabel>
                        <Select
                            native
                            onChange={this.onChange}
                            value={this.props.country}
                            error={this.props.hascountryerror && !this.props.runonce}
                            required
                            disabled={this.props.isaddressdisabled}
                            inputProps={{
                                id: 'country',
                                name: 'country'
                            }}
                        >
                        {countries}
                        </Select>
                    </FormControl>
                </Grid>
                {this.props.isfulladdress &&
                    <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                id="zipcode"
                                name="zipcode"
                                label="Zip Code"
                                placeholder="Zip Code"
                                disabled={this.props.isaddressdisabled}
                                style = {componentMargin}
                                error={this.props.haszipcodeerror && !this.props.runonce}
                                required
                                value = {this.props.zipcode} onChange={this.onChange}
                            />
                    </Grid>
                }
            </Fragment>
        );
    }
}

export default Address;