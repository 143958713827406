import {alertConstants} from '../constants/alert'

export const showAlert = ( message, color , open = true) => (
    {
        type: alertConstants.SHOW_ALERT,
        message: message,
        open: open,
        color: color
    }
);

export const closeAlert = (open = false) => (
    {
        type: alertConstants.CLOSE_ALERT,
        open: open
    }
);