import axios from 'axios';
import { userConstants } from '../constants';
const config = require('../config');

export const addUser = ( {email = ''} = {}) => (
    {
        type : userConstants.USER_ADD,
        user : {
            email
        }
    }
);

export const userSignupSuccess = (userData) => (
    {
        type: userConstants.USER_SIGNUP_REQUEST_SUCCESS,
        userData
    }
);

export const userSignupFailed = (err) => (
    {
        type: userConstants.USER_SIGNUP_REQUEST_FAILED,
        err
    }
);

export const userSignupRequest = (userData) =>  dispatch => {
    axios.post(config.server_url + '/api/users', { user : userData})
        .then( res => dispatch(userSignupSuccess(res.userData)))
        .catch (err => dispatch(userSignupFailed(err)))
}

export const getUsers = (userId) => async dispatch => {
    const query = `{
        users(id: "${userId}") {
            _id,
            lastname,
            firstname,
            middlename,
            email,
            access
        }
    }`

    const url_api = config.server_url + '/public?query=' + query;

    // const res =
    await axios.get(url_api).then(
        res => {
            dispatch({
                type: userConstants.USERS_GET,
                payload: res.data
            })
        }
    )
}

// user information only given id
export const getUser = (userId,cb) => async dispatch => {
    const query = `{
        getUser(id: "${userId}") {
            _id,
            name
            email
            lastname
            firstname
            middlename
            contactnumber
            housenumber
            street
            province
            citymunicipality
            barangay
            zipcode
            country
            access
        }
    }`

    const url_api = config.server_url + '/public?query=' + query;

    // const res =
    await axios.get(url_api)
        .then(
            res => {
                dispatch({
                    type: userConstants.USER_GET,
                    payload: res.data
                })
                cb();
            }
        )
}


export const createUserByAdmin = (data, cb) =>  async dispatch => {
    axios
    .post( config.server_url + '/public?', {
        query: `
            mutation add($input: UserInput){
                createUser(input: $input) {
                    name,
                    email,
                    lastname,
                    firstname,
                    middlename,
                    contactnumber,
                    housenumber,
                    street,
                    province,
                    citymunicipality,
                    barangay,
                    zipcode,
                    country,
                    access,
                }
            }
        `,
        variables: {
            input: data
        },
    })
    .then((res) => {
        if(!res.data.errors){
           cb(); 
           dispatch({
               type: userConstants.USER_ADD,
               payload: res.data
           })
        }
    })
    .catch((error) => {
        console.log('asd_error')
    })
}

export const deleteUser = (userId) => async dispatch => {
    axios.post(config.server_url + '/public?', {
        query: `
            mutation deleteUser($_id: String!){
                 deleteUser(_id: $_id) 
             }
        `,
        variables: {
            _id: userId
        },
    })
    .then(
            res => {
                dispatch({
                    type: userConstants.USER_DELETE,
                    payload: res.data.data.deleteUser
                })
            }
        )
    .catch((error) => console.log(error))
}

export const updateUser = (data, callbackAction) => async dispatch => {
    axios.post(config.server_url + '/public?', {
        query: `
            mutation updateUser($input: UserInput){
                 updateUser(input: $input) 
             }
        `,
        variables: {
            input: data
        },
    })
    .then(
            res => {
                callbackAction();
                // dispatch({
                //     type: userConstants.USER_UPDATE,
                //     payload: res.data
                // })
            }
        )
    .catch((error) => console.log(error))
}