import React, {Component } from 'react';
import {connect} from 'react-redux';
import Alert from '../components/alerts/alert';
import {showAlert, closeAlert} from '../actions/alert';
import {userSignupRequest} from '../actions/users';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {
    withStyles,
    CardContent,
} from "@material-ui/core";
import {
	Save,
	Clear
} from '@material-ui/icons';
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";


import regularCardStyle from "../assets/css/regularCardStyle";
import {BinDetails} from "../components/registration";

import history from '../history';
import axios from 'axios';

const config = require('../config');
/**
    @class RenewBusinessContainer
    @description allows to renew business here, same as signup renewal
*/

class RenewBusinessContainer extends Component {

    constructor(props)
    {
        super(props);
        this.props.closeAlert();

        this.state = {
            user : {
                owner : {
                    lastname : '',
                    firstname : '',
                    middlename : '',
                    haslastnameerror : true,
                    hasfirstnameerror : true,
                    hasmiddlenameerror : true,
                    runonce : true
                },
                bin : '',
                error : '',
                permitnumber: '',
                owneraddress : {
                    housenumber : '',
                    street : '',
                    province : '031400000',
                    citymunicipality : '031410000',
                    barangay : '',
                    zipcode : '3000',
                    country : 'PH',
                    streetlabel : 'Street',
                    isfulladdress: true,
                    countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
                    provinces : [ {'031400000': 'BULACAN'}],
                    citymunicipalities : [{'031410000':'CITY OF MALOLOS'}],
                    barangays : [{'':''}],
                    loading : false,
                    hashousenumber : true,
                    iszipcodedisabled : true,
                    hasstreeterror : true,
                    hasprovinceerror : false,
                    hascitymunicipalityerror : false,
                    hasbarangayerror : true,
                    haszipcodeerror : false,
                    hascountryerror : false,
                    runonce : true
                },
                runonce3 : true,
            }
        }
    }

    onChange = (e) =>
    {
        const {name, value} = e.target;

        var user = this.state.user;
        if (name === 'bin')
            user.bin = value;
        else if (name === 'permitnumber')
            user.permitnumber = value;

        this.setState({user});
    }

    onOwnerChanged = (owner) =>
    {
        this.setState( prevState => ( {
            user:  {...prevState.user, owner}
        }));
    }

    onOwnerAddressChanged = ( owneraddress) =>
    {
        this.setState( prevState => ( {
            user : { ...prevState.user, owneraddress}
        }));
    }

    onApply = () => {
        let success = true;

        const {owner, bin : _bin, permitnumber:_permitnumber, owneraddress, runonce3} = this.state.user;
        //format bin and permit number
        let bin = _bin.replace(/_/g, '');
        let permitnumber = _permitnumber.replace(/_/g, '');
        if (permitnumber === '-')
            permitnumber = '';

        if (runonce3)
        {
            const _user = {...this.state.user, runonce3: false};
            this.setState({user: _user});
        }

        if (owner.haslastnameerror || owner.hasfirstnameerror || owner.hasmiddlenameerror  ||
            bin === '' ||
            owneraddress.hasstreeterror || owneraddress.hascountryerror || owneraddress.hasprovinceerror ||  owneraddress.hascitymunicipalityerror || owneraddress.hasbarangayerror || owneraddress.haszipcodeerror
            )
            success = false;

        if (success)
        {
            //axios
            //add userid here
            const bindetails = {  userid: this.props.user._id,
                lastname:owner.lastname, firstname:owner.firstname, middlename:owner.middlename, bin, permitnumber,
                housenumber: owneraddress.housenumber, street: owneraddress.street, barangay: owneraddress.barangay
            };
            const self = this;
            axios.post(config.server_url + '/matchbindetails', { bindetails }).then(function (response) {
                // const _user = {...self.state.user, error: ''}; //value never used
                //call graphql here
                axios
                .post(config.server_url + '/public?', {
                    query: `
                        mutation applyRenewal($input: RenAppInput!){
                            applyRenewal(input: $input)
                        }
                    `,
                    variables: {
                        input: bindetails
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.errors === undefined || res.data.errors.length === 0)
                    {
                        history.push('/application-form');
                    }
                    else
                    {
                        self.props.showAlert(res.data.errors[0].message, 'error');
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            }).catch(function(error){
                self.props.showAlert(error.response.data.errors[0], 'error');
                const _user = {...self.state.user, error: error.response.data.errors[0]};
                self.setState({ user: _user });
            });
        }
    }

    onCancel = () => {
        history.push('/application-form');
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12} >
                    <Card className={classes.card }>
                        {/* <CardHeader
                        classes={{
                                root:classes.cardHeader +" " +classes["blueCardHeader"] ,
                                title: classes.cardTitle,
                                subheader: classes.cardSubtitle
                        }}
                        title= "Renew Business Application"
                        subheader="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
                        /> */}
                        <CardHeader color="primary" >
                            <h4 className={classes.cardTitleWhite}>Renew Business Application</h4>
                            <p className={classes.cardCategoryWhite}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Alert handleCloseButton = {this.props.closeAlert }/>
                            <CardContent>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={12}>
                                        <BinDetails owner={this.state.user.owner} onPersonChanged={this.onOwnerChanged}
                                        bin={this.state.user.bin}
                                        error={this.state.user.error}
                                        permitnumber={this.state.user.permitnumber}
                                        onChange={this.onChange}
                                        owneraddress={this.state.user.owneraddress} onAddressChanged={this.onOwnerAddressChanged} runonce={this.state.user.runonce3}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.stepperButtons}>
                                            <Button variant="text" color="primary" className={classes.button} onClick={this.onApply} size="small">
                                                <Save className={classes.leftIcon}/> Apply
                                            </Button>
                                            <Button variant="text" className={classes.button} onClick={this.onCancel} size="small">
                                                <Clear className={classes.leftIcon}/> Cancel
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                        </CardContent>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return  {
        user : state.session.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        userSignupRequest: (userData) => {
            dispatch(userSignupRequest(userData))
        }
    }
};

export default connect( mapStateToProps, mapDispatchToProps)( withStyles(regularCardStyle)(RenewBusinessContainer));