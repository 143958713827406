import axios from 'axios'
import {userConstants} from '../constants'

const debug = require('debug')('client');
debug.enabled = process.env.REACT_APP_DEBUG;
const config = require('../config');

export const getOwnInfo = (lastname, cb) => async dispatch => {
    const query = `{
        getOwnInfo(lastname: "${lastname}"){
            ID,
            FULLNAME,
            LASTNAME,
            FIRSTNAME,
            MIDDLE,
            GENDER,
            BDATE,
            NATIONALITY,
            CONTACTNUMBER,
            EMAILADDRESS,
            ADDRESS,
            HOUSERNUMBER,
            STREET,
            PROVINCE,
            CITYMUNICIPALITIES,
            BARANGAY,
            ZIPCODE,
            COUNTRY,
        }
    }`

    const url_api = config.server_url +'/public?query=' + query;
    // const res =
    await axios.get(url_api)
    .then(
        res => {
            dispatch({
                type:userConstants.OWNINFO_GET,
                payload: res.data
            });
            cb();
        }
    ).catch (
        err => {
            console.log(err)
        }

    )
}