import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { CardContent, withStyles } from "@material-ui/core";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Save from '@material-ui/icons/Save';
import classNames from 'classnames';
import { closeAlert, showAlert } from '../../actions/alert';
import { hideLoadingRequest, showLoadingRequest } from '../../actions/session';
import { userSignupRequest } from '../../actions/users';
import regularCardStyle from "../../assets/css/regularCardStyle";
import Alert from '../../components/alerts/alert';
import Email from "../../components/Email";
import history from '../../history';
import PersonalInformation from '../registration/PersonalInformation';

// radio 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const config = require('../../config');

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
		display: 'flex',
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
});

// defined but never used
// function TabContainer({ children, dir }) {
// 	return (
// 		<Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
// 		{children}
// 		</Typography>
// 	);
// }


/**

	@class UserSettingsAdd
	@description Add users (admin)
*/
class UserSettingsAdd extends Component {
	constructor(props){
		super(props);

		this.state = {
			accessValue: 'admin', // radio button
			user : {
				_id: '',
					person : {
						lastname : '',
						firstname : '',
						middlename : '',
						haslastnameerror : true,
						hasfirstnameerror : true,
						hasmiddlenameerror : true,
                    	runonce : true // contact number
					},
					dialingcode : '63', //default country
					contactnumber : '',
						personaddress : {
							housenumber : '',
							street : '',
							province : '',
							citymunicipality : '',
							barangay : '',
							zipcode : '',
							country : 'PH',
							streetlabel : '',
							isfulladdress: true,
							countries : [ {"country_code": "PH", "country_name": "PHILIPPINES", "dialing_code" : "63"}],
							provinces : [ {'':''}],
							citymunicipalities : [{'':''}],
							barangays : [{'':''}],
							loading : false,
							hasstreeterror : true,
							hasprovinceerror : true,
							hascitymunicipalityerror : true,
							hasbarangayerror : true,
							haszipcodeerror : true,
							hascountryerror : false,
							runonce : true
						},
						access: '', 
						emailaddress: '',
						emailaddressError: 'Invalid Email.',
						runonce1 : true, // trigger to persons and contact
						runonce2 : true, // trigger to email address
						accounts: {
								currentpassword: '',
								newpassword: '',
								confirmPassword : ''
						},
			},
			value: 0,
		description: '',
		selectedFile: '',
		}

	}
	onPersonChanged = (person) =>
	{
		this.setState( prevState => ( {
			user:  {...prevState.user, person}
		}));
	}

	handleAddUser = (e) => {
		e.preventDefault();

		let success = true; 
		const _props = this.props;
		const {lastname, firstname, middlename} = this.state.user.person;
		const {housenumber, street, province, citymunicipality, barangay, zipcode, country} = this.state.user.personaddress; 
		const {access} = this.state.user;


		var {person, personaddress, contactnumber, dialingcode, runonce1} = this.state.user;
		const { emailaddress, emailaddressError, runonce2 } = this.state.user;

		if (person.haslastnameerror || person.hasfirstnameerror || person.hasmiddlenameerror ||
			contactnumber === '' || (dialingcode !== '-' && contactnumber === ('+' + dialingcode)) ||
			personaddress.hasstreeterror || personaddress.hascountryerror || personaddress.hasprovinceerror ||  personaddress.hascitymunicipalityerror || personaddress.hasbarangayerror || personaddress.haszipcodeerror || emailaddress === '' || emailaddressError !== '')
		{
		    success = false;
		}


		if(success){
			const input = {
				name : firstname + '' + lastname,
				email : this.state.user.emailaddress,
				lastname : lastname,
				firstname : firstname,
				middlename : middlename,
				contactnumber : this.state.user.contactnumber,
				housenumber : housenumber,
				street : street,
				province : province,
				citymunicipality : citymunicipality,
				barangay : barangay,
				zipcode : zipcode,
				country : country,
				access: access,
			}
			_props.value.createUserByAdmin(input, this.refreshPage);
		}else{
			this.setState(ps => ({
				user: {...ps.user, runonce1: false, runonce2: false}
			}));
		}
	}

	refreshPage = () => {
		history.push('/user-settings/view');
	}

	onPersonAddressChanged = ( personaddress) =>
    {
        let isCountryChanged = false;

        this.setState( function(prevState) {
            if (prevState.user.personaddress.country !== personaddress.country)
            {
                isCountryChanged = true;
                return {user : { ...prevState.user, personaddress} }
            }
            else
            {
                return {user : { ...prevState.user, personaddress} }
            }

        }, () => {
            if (isCountryChanged)
            {
                const self = this;
                axios.get(config.server_url + '/dialingcode', {params :  {countrycode : personaddress.country} } )
                    .then(function (response) {
                        const dialingcode = response.data.dialing_code;
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode, contactnumber: ''}
                        }));
                    })
                    .catch(function(error){
                        self.setState(ps => ( {
                            user : { ...ps.user, dialingcode : '', contactnumber: ''}
                        }));
                    });
            }

        });

    }


    onChange = (e) =>
    {
		const {name, value} = e.target;

		var user = this.state.user;
		if (name === 'contactnumber')
			user.contactnumber = value;
		else if (name === 'emailaddress')
			user.emailaddress = value;
		else if (name === 'password')
			user.password = value;
		else if (name === 'bin')
			user.bin = value;
		else if (name === 'permitnumber')
			user.permitnumber = value;

		this.setState({user});
	}

    checkEmail = fn => e => {
		const email = e.target.value;
		const {
			onEmailChange = null,
			onChange = null,
		} = this.props;

		const isValid  = this.isEmailValid(email);
		if (onEmailChange)
		{
			onEmailChange( { value: email, errorText: isValid ? "" : "Email invalid", runonce : false });
			if (isValid)
			fn(email); //callback
		}
		else if(onChange)
		{
			onChange(e,  isValid ? "" : "Email invalid");
		}
	}
	//radio button
	handleChange = event => {
		this.setState({ accessValue: event.target.value }, () => {
			this.setState({
				...this.state,
				user: {
					...this.state.user,
					access: this.state.accessValue
				}
			})
		});
	};

	render() {
		const {classes} = this.props;
		const {emailaddress, emailaddressError, runonce2, ...user} = this.state.user
		return (
			<div>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} >
						<Card className={classes.card }>
							<CardHeader color="primary" >
								<h4 className={classes.cardTitleWhite}>Add User Form</h4>
								<p className={classes.cardCategoryWhite}>
									This form will register new user and admin.
								</p>
							</CardHeader>
							<CardBody>
								<CardContent>
									<div className={classes.container} >
										{/*
										<Person  onPersonChanged={this.onPersonChanged} />
										<GlobalAddress
											{...this.state.user.personaddress} onAddressChanged={this.onPersonAddressChanged}
										/>

										*/}

										<PersonalInformation person={this.state.user.person} onPersonChanged={this.onPersonChanged}
											dialingcode={this.state.user.dialingcode} contactnumber={this.state.user.contactnumber} onChange={this.onChange} 
											personaddress={this.state.user.personaddress} onAddressChanged={this.onPersonAddressChanged} runonce={this.state.user.runonce1} 
										/>

										<Email
											onChange={
												(emailaddress, emailaddressError, runonce2) => {
													this.setState({
														user: {
															...user,
															emailaddress,
															emailaddressError,
															runonce2
														}
													})
												}
											}
											emailaddress = {emailaddress}
											emailaddressError = {emailaddressError}
											runonce = {runonce2}
											// value={{emailaddress, emailaddressError, 'runonce':runonce2}}
										/>

										<div>
												<FormControl component="fieldset" className={classes.formControl}>
												<FormLabel component="legend">Access Right</FormLabel>
												<RadioGroup
													aria-label="accessRights"
													name="gender2"
													className={classes.group}
													value={this.state.accessValue}
													onChange={this.handleChange}
												>
													<FormControlLabel
													value="admin"
													control={<Radio color="primary" />}
													label="Admin"
													labelPlacement="start"
													/>
													<FormControlLabel
													value="user"
													control={<Radio color="primary" />}
													label="User"
													labelPlacement="start"
													/>
												</RadioGroup>
												</FormControl>
										</div>
										<Button className={classes.button} variant="constained" size="small" color="primary" onClick={this.handleAddUser}>
											<Save className={classNames(classes.leftIcon, classes.iconSmall)} />
											Save
											</Button>
									</div>
								</CardContent>
							</CardBody>
						</Card>
					</Grid>
				</Grid>
				<Alert/>
			</div>
		);
	}

}const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (message, color) => {
            dispatch(showAlert(message, color))
        },
        closeAlert: () => {
            dispatch(closeAlert())
        },
        userSignupRequest: (userData) => {
            dispatch(userSignupRequest(userData))
        },
        showLoadingRequest : ()  => {
            dispatch(showLoadingRequest())
        },
        hideLoadingRequest : ()  => {
            dispatch(hideLoadingRequest())
        },
    }
};

const mapStateToProps = state => {
    return  {
        isAuthenticated : state.session.isAuthenticated,
    }
}

export default withStyles(regularCardStyle,(styles, { withTheme: true }))(connect( mapStateToProps, mapDispatchToProps)(UserSettingsAdd));